/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import EntityActionSharedResources from 'App/Pages/Home/EntityActionSharedResources';
import { NameLinkOptionalRenderer } from 'components/EntityTable/CellRenderer';
import { organizationStore, userStore } from 'stores';
import { getMilestoneUpdatedDate, getNameRendererInput } from 'utils/shared-resources-helpers';
import { EmptyState } from 'primitives';
import { Connectors } from 'icons';
import { EntityTable } from 'components';

import { organizationSharedResourcesStore } from './stores';
import * as Styled from './SharedResources.styled';

const sharedResourcesDocLink =
  'https://docs.camunda.io/docs/next/components/connectors/manage-connector-templates/#manage-published-connector-templates';
const superUserDocLink = 'https://docs.camunda.io/docs/components/modeler/web-modeler/collaboration/#super-user-mode';

const SharedResources = () => {
  const [sharedResources, setSharedResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isSuperUserModeActive } = userStore;
  const { currentOrganizationId, hasElevatedOrganizationPermissions } = organizationStore;

  useEffect(() => {
    const currentOrganizationId = organizationStore.currentOrganizationId;
    const loading = organizationSharedResourcesStore.getLoadingStateForOrganization(currentOrganizationId);

    if (!loading) {
      organizationSharedResourcesStore.updateSharedResourcesForOrganization(currentOrganizationId);
    }
  }, [organizationStore.currentOrganizationId, userStore.isSuperUserModeActive]);

  useEffect(() => {
    const currentOrganizationId = organizationStore.currentOrganizationId;

    const loading = organizationSharedResourcesStore.getLoadingStateForOrganization(currentOrganizationId);
    setLoading(loading);

    const sharedResourcesForCurrentOrg =
      organizationSharedResourcesStore.getSharedResourcesForOrganization(currentOrganizationId) || [];
    setSharedResources(sharedResourcesForCurrentOrg);
  }, [
    organizationStore.currentOrganizationId,
    organizationSharedResourcesStore.getSharedResourcesForOrganization(organizationStore.currentOrganizationId),
    organizationSharedResourcesStore.getLoadingStateForOrganization(organizationStore.currentOrganizationId)
  ]);

  let rows;
  let columns;

  if (hasElevatedOrganizationPermissions) {
    columns = [
      {
        key: 'name',
        header: 'Name',
        renderer: NameLinkOptionalRenderer,
        sortable: true
      },
      { key: 'location', header: 'Location', width: '200px', sortable: true },
      { key: 'creator', header: 'Creator', width: '200px', sortable: true },
      {
        key: 'publishDate',
        header: 'Publish date',
        width: '200px',
        sortable: true
      },
      {
        key: 'entityAction',
        header: '',
        width: '50px',
        noPadding: true,
        overflowVisible: true,
        renderer: (resource) => (
          <EntityActionSharedResources resource={resource} organizationId={currentOrganizationId} />
        )
      }
    ];
    rows = sharedResources.map((resource) => ({
      id: resource.id,
      name: getNameRendererInput(resource),
      location: resource.project.name,
      creator: resource.author,
      publishDate: getMilestoneUpdatedDate(resource),
      entityAction: resource
    }));
  } else {
    columns = [
      {
        key: 'name',
        header: 'Name',
        renderer: NameLinkOptionalRenderer,
        sortable: true
      },
      {
        key: 'publishDate',
        header: 'Publish date',
        width: '200px',
        sortable: true
      }
    ];

    rows = sharedResources.map((resource) => ({
      id: resource.id,
      name: getNameRendererInput(resource),
      publishDate: getMilestoneUpdatedDate(resource)
    }));
  }

  return (
    <Styled.Container>
      {rows?.length > 0 && (
        <Styled.DescriptionContainer>
          <Styled.DescriptionTextWrapper>
            These resources have been approved and shared by your organization.&nbsp;
            <Styled.Link href={sharedResourcesDocLink} target="_blank" rel="noreferrer">
              Learn more
              {/* @ts-expect-error TS2769 */}
              <Styled.WithExternalLinkIcon />
            </Styled.Link>
            .
          </Styled.DescriptionTextWrapper>
          {hasElevatedOrganizationPermissions && !isSuperUserModeActive && (
            <Styled.DescriptionTextWrapper>
              <br />
              Enter super-user mode to view source code in all projects.&nbsp;
              <Styled.Link href={superUserDocLink} target="_blank" rel="noreferrer">
                Learn more
                {/* @ts-expect-error TS2769 */}
                <Styled.WithExternalLinkIcon />
              </Styled.Link>
              .
              <br />
            </Styled.DescriptionTextWrapper>
          )}
        </Styled.DescriptionContainer>
      )}
      <EntityTable
        columns={columns}
        rows={rows}
        title={''}
        // @ts-expect-error TS2741
        emptyState={
          // @ts-expect-error TS2739
          <EmptyState
            icon={<Connectors />}
            title="Your organization doesn't have shared resources."
            link={
              <Styled.Link href={sharedResourcesDocLink} target="_blank" rel="noreferrer">
                Learn more about shared resources
                {/* @ts-expect-error TS2769 */}
                <Styled.WithExternalLinkIcon />
              </Styled.Link>
            }
          />
        }
        isLoading={loading}
        noTablePadding
      />
    </Styled.Container>
  );
};

export default observer(SharedResources);
