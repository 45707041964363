/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';
import { C3Navigation } from '@camunda/camunda-composite-components';

// Note: this value is hard-coded in the camunda-composite-components library
const cdsHeaderZIndex = 8000;

export const Wrapper = styled.header`
  position: sticky;
  border-bottom: 1px solid var(--silver-darken-87);
  background-color: var(--cm-color-ui-light1);
  top: 0;
  // This is to ensure that the wrapper stays on top of other UI elements (e.g. the breadcrumb subheader)
  z-index: ${cdsHeaderZIndex + 1};

  // Eventually, it wil be remove with #3147
  .cds--side-nav__link {
    cursor: pointer;
  }

  ${({ $hideApps }) =>
    $hideApps &&
    `[aria-label='Camunda components'] {
      display: none;
    }`}
`;

export const Navigation = styled(C3Navigation)`
  height: 47px;
`;

export const Breadcrumbs = styled.div`
  position: fixed;
  z-index: ${cdsHeaderZIndex + 1};
  left: ${({ $appBarHidden }) => ($appBarHidden ? '150px' : '198px')};
  top: 6px;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 80px;
  flex-grow: 1;
  max-width: 55vw;

  height: 36px;
  margin-left: 20px;
  padding-left: 20px;

  > * {
    white-space: nowrap;
  }

  > *:last-child {
    color: var(--grey-darken-33);
  }

  > *:not(:first-child) {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 30vw;
  }

  > *:not(:last-child) {
    padding-right: 18px;
    margin-right: 15px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="%23949494" /><path d="M0 0h24v24H0z" fill="none" /></svg>');
    background-position: calc(100% + 5px) 6px;
    background-repeat: no-repeat;
  }
`;

export const SubHeader = styled.div`
  margin-top: 47px;
`;
