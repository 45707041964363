/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Loader } from 'primitives';

import * as Styled from './SubmitInProgress.styled';
import { isExecuteMode } from './modeUtils';

export default function SubmitInProgress({ mode }) {
  return (
    <Styled.SubmitInProgressSection>
      {/* @ts-expect-error TS2769 */}
      <Loader size="small" /> {isExecuteMode(mode) ? 'Running' : 'Deploying'}
    </Styled.SubmitInProgressSection>
  );
}
