/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import { KEY_RETURN, KEY_DOWN, KEY_UP, KEY_TAB } from 'keycode-js';

import { commentsStore } from 'stores';

import * as Styled from './SuggestionInput.styled';

class SuggestionsInput extends Component {
  nodes = new Map();
  input = createRef();
  username = '';
  cursorPosition = 0;
  isSuggestionsVisible = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      username: observable,
      cursorPosition: observable,
      isSuggestionsVisible: observable,
      handleBlur: action,
      handleKeyDown: action,
      handleKeyUp: action,
      handleSuggestionsNavigation: action,
      suggestions: computed
    });
  }

  componentDidMount() {
    if (this.props.autofocus && this.input?.current) {
      this.input.current.focus();
    }
  }

  getCurrentWord(str, pos) {
    str = String(str);
    pos = Number(pos - 1) >>> 0;

    const left = str.slice(0, pos + 1).search(/\S+$/);
    const right = str.slice(pos).search(/\s/);

    if (right < 0) {
      return str.slice(left);
    }

    return str.slice(left, right + pos);
  }

  handleBlur = () => {
    this.isSuggestionsVisible = false;
    this.username = '';
  };

  handleFocus = (evt) => {
    // This puts the cursor at the end of the documentation text, so that
    // users can directly continue typing without having to go to the end first.
    evt.target.setSelectionRange(this.props.value.length, this.props.value.length);
  };

  handleKeyDown = (evt) => {
    if (this.suggestions.length > 0 && [KEY_RETURN, KEY_TAB].includes(evt.keyCode) && this.isSuggestionsVisible) {
      const currentIndex = this.suggestions.findIndex((suggestion) => suggestion.isSelected);

      if (currentIndex !== -1) {
        this.suggestions[currentIndex].isSelected = false;

        this.handleSuggestionsSelect(this.suggestions[currentIndex].username)(evt);

        evt.preventDefault();

        return;
      }
    }

    if (evt.keyCode === KEY_RETURN && !evt.shiftKey && !evt.altKey && this.props.value !== '') {
      this.props.onEnter(evt);

      return;
    }

    if ([KEY_DOWN, KEY_UP].includes(evt.keyCode)) {
      this.handleSuggestionsNavigation(evt);

      return;
    }
  };

  handleKeyUp = (evt) => {
    this.cursorPosition = evt.target.selectionEnd;

    const word = this.getCurrentWord(this.props.value, evt.target.selectionEnd);

    if (word[0] === '@') {
      this.isSuggestionsVisible = true;
      this.username = word;
    } else {
      this.isSuggestionsVisible = false;
      this.username = '';
    }
  };

  handleSuggestionsNavigation = (evt) => {
    if (this.suggestions.length === 0) {
      return;
    }

    evt.preventDefault();

    const currentIndex = this.suggestions.findIndex((suggestion) => suggestion.isSelected);
    let nextIndex = currentIndex;

    if (currentIndex === -1) {
      this.suggestions[0].isSelected = true;
    } else {
      const nextSuggestion = this.suggestions[evt.keyCode === KEY_DOWN ? ++nextIndex : --nextIndex];

      if (nextSuggestion) {
        this.suggestions[currentIndex].isSelected = false;

        nextSuggestion.isSelected = true;

        this.nodes.get(nextSuggestion.username).scrollIntoView({
          block: 'nearest'
        });
      }
    }
  };

  handleSuggestionsSelect = (value) => (evt) => {
    const index = this.props.value.lastIndexOf('@', this.cursorPosition - 1);

    const updatedComment =
      this.props.value.substring(0, index) + `@${value} ` + this.props.value.substring(index + value.length);

    this.props.onChange({ target: { value: updatedComment } });

    evt.preventDefault();

    runInAction(() => {
      this.isSuggestionsVisible = false;
    });
  };

  setSuggestionRef(node, key) {
    this.nodes.set(key, node);
  }

  get suggestions() {
    const { suggestions } = commentsStore.state;

    return suggestions.filter((suggestion) =>
      suggestion.username.toLowerCase().includes(this.username.toLowerCase().replace(/@/, ''))
    );
  }

  render() {
    const hasCollaborators = commentsStore.state.suggestions.length > 0;

    return (
      <Styled.FormSuggestionsWrapper data-test={this.props['data-test']}>
        {this.isSuggestionsVisible && (
          <Styled.UserSuggestions data-test="user-suggestions">
            {this.suggestions.length === 0 ? (
              <Styled.EmptyState>
                {hasCollaborators ? 'No collaborator found.' : "This project doesn't have any collaborators, yet."}
              </Styled.EmptyState>
            ) : (
              this.suggestions.map((suggestion) => (
                <div ref={(node) => this.setSuggestionRef(node, suggestion.username)} key={suggestion.id}>
                  <Styled.CollaboratorItem
                    // @ts-expect-error TS2769
                    username={suggestion.name}
                    displayName={suggestion.name}
                    subtitle={suggestion.username}
                    isSelected={suggestion.isSelected}
                    id={suggestion.id}
                    onMouseDown={this.handleSuggestionsSelect(suggestion.username)}
                  />
                </div>
              ))
            )}
          </Styled.UserSuggestions>
        )}

        <Styled.MultiLineTextarea
          // @ts-expect-error TS2769
          multiline
          grow
          placeholder="Reply..."
          value={this.props.value}
          ref={this.input}
          rows="1"
          onChange={this.props.onChange}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </Styled.FormSuggestionsWrapper>
    );
  }
}

export default observer(SuggestionsInput);
