/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Canvg } from 'canvg';

import { notificationStore } from 'stores';
import { tracingService, trackingService } from 'services';

import saveFile from './save-file';

const ENCODINGS = ['image/png', 'image/jpeg'];

const INITIAL_SCALE = 3;
const FINAL_SCALE = 1;
const SCALE_STEP = 1;

const DATA_URL_REGEX = /^data:((?:\w+\/(?:(?!;).)+)?)((?:;[\w\W]*?[^;])*),(.+)$/;

/**
 * Converts a given BPMN diagram into a PNG image and downloads
 * it to the user's computer.
 *
 * @param {Object} modeler An instance of the bpmn-js Modeler with access to the `saveSVG` method.
 * @param {Object} diagram
 */
export default async function exportDiagramPNG(modeler, diagram) {
  try {
    const fileName = `${diagram.name}.png`;

    const { svg } = await modeler.saveSVG();
    const image = await generateImage('png', svg);
    const blob = dataURLToBlob(image);

    // @ts-expect-error TS2345
    saveFile(blob, fileName);

    notificationStore.showSuccess(`"${fileName}" is being downloaded to your computer.`);
    trackingService.trackFileExport({
      fileId: diagram.id,
      fileTypeKey: diagram.type,
      exportType: 'png',
      from: 'diagram',
      fileCount: 1,
      fileSize: blob.size
    });
  } catch (ex) {
    notificationStore.showError('Yikes! Something went wrong while preparing your download. Please try again later.');
    tracingService.traceError(ex, 'Failed to export diagram as PNG');
  }
}

/**
 * Generates an image from an SVG.
 *
 * @param {String} type
 * @param {object} svg
 */
async function generateImage(type, svg) {
  const encoding = `image/${type}`;

  if (ENCODINGS.indexOf(encoding) === -1) {
    throw new Error(`<${type}> is an unknown type for converting svg to image`);
  }

  const initialSVG = svg;

  let dataURL = '';

  for (let scale = INITIAL_SCALE; scale >= FINAL_SCALE; scale -= SCALE_STEP) {
    let canvas = document.createElement('canvas');

    svg = initialSVG.replace(
      /width="([^"]+)" height="([^"]+)"/,
      (_, widthStr, heightStr) =>
        `width="${parseInt(widthStr, 10) * scale}" height="${parseInt(heightStr, 10) * scale}"`
    );

    const context = canvas.getContext('2d');

    const canvg = Canvg.fromString(context, svg);
    await canvg.render();

    // make the background white for every format
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = 'white';

    context.fillRect(0, 0, canvas.width, canvas.height);

    dataURL = canvas.toDataURL(encoding);

    if (!DATA_URL_REGEX.test(dataURL)) {
      throw new Error('Invalid data URL');
    }

    return dataURL;
  }
}

/**
 * Converts a data URL to a Blob.
 *
 * @param {String} dataURL
 */
function dataURLToBlob(dataURL) {
  const matches = dataURL.match(DATA_URL_REGEX);

  if (!matches) {
    throw new Error('Invalid data URL');
  }

  const [, type, , data] = matches;

  const byteString = atob(data);
  const buffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([buffer], { type });
}
