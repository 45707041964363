/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed, makeObservable } from 'mobx';
import PropTypes from 'prop-types';

import { Button } from 'primitives';

import * as Styled from './Form.styled';

// @ts-expect-error TS2554
export const FormContext = React.createContext();

class Form extends Component {
  constructor(props) {
    super(props);

    makeObservable(this, {
      fields: observable,
      register: action,
      update: action,
      hasErrors: computed
    });
  }

  static defaultProps = {
    needsChange: true
  };

  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    layout: PropTypes.string
  };

  fields = [];

  /**
   * Registers an input element to the form, so the form
   * can keep track of its value and validation status.
   *
   * @param {String} field The name of the field.
   * @param {String} value The input value of the field.
   * @param {Boolean} isValid If the field already passed validation or not.
   */
  register = (field, value, isValid = false) => {
    this.fields.push({ field, isValid, hasChanged: false, value });
  };

  /**
   * Updates the value and validation status of a registered
   * input.
   *
   * @param {String} field The name of the field.
   * @param {String} value The input value of the field.
   * @param {Boolean} isValid If the field passed validation or not.
   * @param {Boolean} hasChanged If the field's value has changed or not.
   */
  update = (field, value, isValid, hasChanged) => {
    this.fields.forEach((item) => {
      if (item.field === field) {
        item.isValid = isValid;
        item.hasChanged = hasChanged;
        item.value = value;
      }
    });
  };

  /**
   * Converts the passed validation rules into an object, which
   * then will be passed onto the input field. The object contains
   * valid, native HTML5 validation attributes, like `minlength`, `required` and more.
   *
   * @param {String} params A list of rules to be mapped to the input, seperated by a |.
   */
  getValidationRules(params = '') {
    const rules = params.split(/\|/g);
    let attributes = {};

    if (!params) {
      return attributes;
    }

    for (let i = 0; i < rules.length; i++) {
      const [method, modifier] = rules[i].split(/:/);

      switch (method) {
        case 'min':
          attributes['minLength'] = modifier;
          break;
        case 'max':
          attributes['maxLength'] = modifier;
          break;
        case 'required':
          attributes['required'] = true;
          attributes['pattern'] = '.*\\S+.*';
          break;
        case 'email':
          attributes['pattern'] = '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$';
          break;
        case 'username':
          attributes['pattern'] = '^[0-9a-zA-Z._-]+$';
          break;
        case 'nonurl':
          attributes['pattern'] = '^([^:/.@§$]+)$';
          break;
      }
    }

    return attributes;
  }

  /**
   * Determines if the current form (e.g. all of its inputs) is valid.
   *
   * In order to be valid, an input needs to pass the HTML5 validation check
   * and its value must have been changed.
   *
   * @return {Boolean}
   */
  get hasErrors() {
    return (
      !this.fields.every((field) => field.isValid) ||
      (!this.fields.some((field) => field.hasChanged) && this.props.needsChange)
    );
  }

  /**
   * @typedef {import('react').SyntheticEvent} SyntheticEvent
   */

  /**
   * This method is called when the submit button is clicked.
   *
   * If the form is valid (e.g. all form fields are valid) it creates
   * a new object with the form data and calls the `onSubmit` prop, which
   * in return handles what should happen afterward.
   *
   * @param {SyntheticEvent} evt The submit event which is fired by the form itself.
   */
  validate = (evt) => {
    evt.preventDefault();

    if (!this.hasErrors) {
      let data = {};

      this.fields.forEach((field) => (data[field.field] = field.value.trim()));

      this.props.onSubmit(data);
    }
  };

  render() {
    return (
      <FormContext.Provider
        value={{
          register: this.register,
          update: this.update,
          getValidationRules: this.getValidationRules,
          isInlined: this.props.layout === 'inline'
        }}
      >
        <Styled.FormWrapper
          onSubmit={this.validate}
          noValidate
          data-test={this.props['data-test']}
          // @ts-expect-error TS2769
          layout={this.props.layout}
        >
          {this.props.children}

          {/* @ts-expect-error TS2769 */}
          <Styled.FormFooter layout={this.props.layout}>
            {this.props.onCancel && (
              // @ts-expect-error TS2322
              <Button onClick={this.props.onCancel} type="button" variant="secondary" data-test="form-cancel">
                Cancel
              </Button>
            )}

            {/* @ts-expect-error TS2322 */}
            <Button variant="primary" type="submit" disabled={this.hasErrors} data-test="form-submit">
              {this.props.buttonText}
            </Button>
          </Styled.FormFooter>
        </Styled.FormWrapper>
      </FormContext.Provider>
    );
  }
}

export default observer(Form);
export { TextField } from './TextField';
export { default as InlineEditable } from './InlineEditable';
