/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link as _Link, Section, Tag as _Tag } from '@carbon/react';
import styled from 'styled-components';

export const DialogBody = styled.div`
  padding: 80px 40px;
  color: var(--cds-text-primary);
`;

export const Description = styled(Section)`
  margin-top: 10px;
`;

export const Link = styled(_Link)`
  margin-top: 40px;
`;

export const Tag = styled(_Tag)`
  margin-bottom: var(--cds-spacing-03);
  margin-left: 0;
`;
