/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect } from 'react';
import { Toggle, Stack, Loading } from '@carbon/react';
import styled from 'styled-components';
import { spacing06 } from '@carbon/layout';

import { EmptyState } from 'primitives';
import { organizationStore, userStore } from 'stores';
import { notificationStore } from 'components/NotificationSystem';

import emailNotificationService from './EmailNotificationService';

export function EmailNotificationSettings() {
  const [isLoading, setIsLoading] = useState(true);
  const [emailNotificationSettings, setEmailNotificationSettings] = useState([]);

  useEffect(() => {
    emailNotificationService
      .fetchByOrganizationId(organizationStore.currentOrganizationId)
      .then((emailSettings) => {
        setEmailNotificationSettings(emailSettings);
        setIsLoading(false);
      })
      .catch(() => notificationStore.showError('Could not load your notification preferences.'));
  }, []);

  function isAllProjectsSliderOn() {
    return emailNotificationSettings.every((setting) => setting.subscribed);
  }

  function subscribe(projectToggle) {
    return emailNotificationService
      .update({ projectId: projectToggle.id })
      .catch(() =>
        notificationStore.showError('There was a problem while trying to update your settings. Please try again.')
      );
  }

  function unsubscribe(projectToggle) {
    return emailNotificationService
      .destroy({ projectId: projectToggle.id })
      .catch(() =>
        notificationStore.showError('There was a problem while trying to update your settings. Please try again.')
      );
  }

  function toggleSubscription(toggle) {
    const projectToggle = emailNotificationSettings.find((notification) => notification.id === toggle.id);
    if (projectToggle.subscribed) {
      unsubscribe(projectToggle).then(() =>
        setEmailNotificationSettings(
          emailNotificationSettings.map((project) => ({
            ...project,
            subscribed: project.id === projectToggle.id ? false : project.subscribed
          }))
        )
      );
    } else {
      subscribe(projectToggle).then(() =>
        setEmailNotificationSettings(
          emailNotificationSettings.map((project) => ({
            ...project,
            subscribed: project.id === projectToggle.id ? true : project.subscribed
          }))
        )
      );
    }
  }

  function toggleAll() {
    if (isAllProjectsSliderOn()) {
      // unsubscribe all
      Promise.all(emailNotificationSettings.filter((project) => project.subscribed).map(unsubscribe)).then(() =>
        setEmailNotificationSettings(
          emailNotificationSettings.map((project) => ({
            ...project,
            subscribed: false
          }))
        )
      );
    } else {
      // subscribe all
      Promise.all(emailNotificationSettings.filter((project) => !project.subscribed).map(subscribe)).then(() =>
        setEmailNotificationSettings(
          emailNotificationSettings.map((project) => ({
            ...project,
            subscribed: true
          }))
        )
      );
    }
  }
  if (isLoading) {
    return <Loading />;
  }

  if (!userStore.userEmail) {
    return (
      // @ts-expect-error TS2739
      <EmptyState
        title="Your email address has not been set."
        description="To get email notifications, you or your admin must add an email address to your profile."
      />
    );
  }

  if (emailNotificationSettings.length === 0) {
    return (
      // @ts-expect-error TS2739
      <EmptyState
        title="You don't have any projects yet."
        description="Once you have created a project, you can set your preferences for email notifications."
      />
    );
  }

  return (
    <Stack gap={2}>
      <Description>
        Receive email notifications when you are mentioned in a comment. <br />
        Select the projects you want to receive notifications for.
      </Description>
      {emailNotificationSettings.length !== 0 && (
        <>
          <StyledToggle
            hideLabel
            size="sm"
            labelText="All projects"
            toggled={isAllProjectsSliderOn()}
            onClick={toggleAll}
            id="all-projects"
          />
          <Divider />
        </>
      )}

      {emailNotificationSettings.map((projectToggle) => (
        <StyledToggle
          hideLabel
          size="sm"
          labelText={projectToggle.name}
          toggled={projectToggle.subscribed}
          onClick={() => toggleSubscription(projectToggle)}
          id={projectToggle.id}
          key={projectToggle.id}
        />
      ))}
    </Stack>
  );
}

const Description = styled.p`
  margin-bottom: ${spacing06};
`;

const Divider = styled.hr`
  height: 1px;
  background-color: var(--cds-border-subtle-01, #e0e0e0);
  width: 100%;
`;

const StyledToggle = styled(Toggle)`
  .cds--toggle__appearance {
    grid-template-columns: max-content auto;
  }
  .cds--toggle__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
