/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, computed, makeObservable, observable } from 'mobx';

const DEFAULT_STATE = {
  isVisible: false,
  message: '',
  variant: 'info',
  isProgress: false
};

/**
 * This store includes the state for snackbar notifications. The <Snackbar />
 * component is only once used in App.js and controled via this store in
 * all other components and pages.
 *
 * If you want to show a snackbar, inject this store and call the `notify` method
 * with its parameters.
 */
class NotificationStore {
  state = Object.assign({}, DEFAULT_STATE);
  queue = [];

  constructor() {
    makeObservable(this, {
      state: observable.shallow,
      queue: observable.shallow,
      isNotificationVisible: computed,
      variant: computed,
      message: computed,
      isProgress: computed,
      reset: action,
      hideNotification: action('hide notification'),
      resetNotification: action('reset notification'),
      showNotification: action('show notification'),
      processQueue: action('process queue')
    });
  }

  reset = () => {
    this.state = Object.assign({}, DEFAULT_STATE);
    this.queue = [];
  };

  /**
   * Get whether the snackbar is visible or hidden on the page.
   *
   * @return {boolean}
   */
  get isNotificationVisible() {
    return this.state.isVisible;
  }

  /**
   * Get the type of snackbar. Only two valid variants
   * are `error` and `warning`. Influences the background color
   * and styling.
   *
   * @return {string} The variant of the snackbar.
   */
  get variant() {
    return this.state.variant;
  }

  /**
   * Get the text to display in the snackbar.
   *
   * @return {string} The text.
   */
  get message() {
    return this.state.message;
  }

  /**
   * Get whether the snackbar should include a progress spinner.
   *
   * @return {boolean} whether to display the progress spinner.
   */
  get isProgress() {
    return this.state.isProgress;
  }

  get duration() {
    return this.state.duration;
  }

  /**
   * Hides the snackbar and resets the state to default.
   */
  hideNotification = () => {
    this.state.isVisible = DEFAULT_STATE.isVisible;
  };

  /**
   * Resets the snackbar to its default values.
   */
  resetNotification = () => {
    this.processQueue();
  };

  /**
   * Prepares to display the next snackbar by pushing it into the queue.
   * @param {Object} notification
   * @param {string} [notification.message] The text to display in the snackbar.
   * @param {string} [notification.variant] Controls what color the snackbar has.
   *      Only `error` and `warning` are valid values.
   * @param {boolean} [notification.progress] If set to `true`, the progress spinner is shown.
   * @param {boolean|number} [notification.autohide] Sets after how much time the snackbar disappears.
   *      Setting `null` as a value disables autohide.
   * @param {number} [notification.duration] The time in milliseconds after which the snackbar disappears.
   */
  showNotification = ({
    message,
    variant = 'info',
    progress = false,
    autohide = true,
    duration = DEFAULT_STATE.duration
  }) => {
    this.queue.push({
      message,
      progress,
      variant: ['warning', 'error', 'info'].includes(variant) ? variant : DEFAULT_STATE.variant,
      duration: autohide ? duration : null,
      key: new Date().getTime()
    });

    if (!this.state.isVisible) {
      this.state.isVisible = true;
      this.processQueue();
    }
  };

  showSuccess(message, duration) {
    this.showNotification({ message, duration });
  }

  showError(message) {
    this.showNotification({ message, variant: 'error' });
  }

  /**
   * Takes the next element from the message queue and applies it
   * to the state, so the following snackbar has the appropriate message,
   * color and other properties.
   */
  processQueue = () => {
    if (this.queue.length > 0) {
      const next = this.queue.shift();

      this.state.message = next.message;
      this.state.isProgress = next.progress;
      this.state.duration = next.duration;
      this.state.variant = next.variant;
      this.state.isVisible = true;
    }
  };
}

export default new NotificationStore();
