/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-expect-error TS2307
import BPMNModdle from 'bpmn-moddle';
import zeebe from 'zeebe-bpmn-moddle/resources/zeebe.json';
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil';

/**
 * @typedef {import('bpmn-js/lib/model/Types').Element} Element
 * @typedef {import('bpmn-js/lib/model/Types').ModdleElement} ModdleElement
 * @typedef {import('bpmn-js/lib/Modeler').default} Modeler
 */

const moddle = new BPMNModdle({ zeebe });

/**
 * Create or update an extension element.
 *
 * @param {Element|ModdleElement} element
 * @param {string} type
 * @param {Object} properties
 * @param {Modeler} modeler
 */
export function createOrUpdateExtensionElement(element, type, properties, modeler) {
  const modeling = modeler.get('modeling');

  const businessObject = getBusinessObject(element);

  let extensionElements = businessObject.get('extensionElements'),
    extensionElement;

  if (!extensionElements) {
    extensionElements = moddle.create('bpmn:ExtensionElements');

    extensionElement = moddle.create(type, properties);

    extensionElement.$parent = extensionElements;

    extensionElements.set('values', [extensionElement]);

    modeling.updateProperties(element, { extensionElements });
  } else {
    extensionElement = findExtensionElement(element, type);

    if (!extensionElement) {
      extensionElement = moddle.create(type, properties);

      extensionElement.$parent = extensionElements;

      modeling.updateModdleProperties(element, extensionElements, {
        values: [...extensionElements.get('values'), extensionElement]
      });
    } else {
      modeling.updateModdleProperties(element, extensionElement, properties);
    }
  }
}

/**
 * Find the first extension element of the given type.
 *
 * @param {Element|ModdleElement} element
 * @param {string} type
 *
 * @returns {ModdleElement|null}
 */
export function findExtensionElement(element, type) {
  const businessObject = getBusinessObject(element);

  const extensionElements = businessObject.get('extensionElements');

  if (!extensionElements) {
    return null;
  }

  return (
    extensionElements.get('values')?.find((extensionElement) => {
      return is(extensionElement, type);
    }) || null
  );
}

/**
 * Find all extension elements of the given type.
 *
 * @param {Element|ModdleElement} element
 * @param {string} type
 *
 * @returns {ModdleElement[]}
 */
export function findExtensionElements(element, type) {
  const businessObject = getBusinessObject(element);

  const extensionElements = businessObject.get('extensionElements');

  if (!extensionElements) {
    return [];
  }

  return extensionElements.get('values').filter((extensionElement) => {
    return is(extensionElement, type);
  });
}

/**
 * Remove an extension element.
 *
 * @param {Element} element
 * @param {string} type
 // @ts-expect-error TS2304
 * @param {Modeler} modeler
 */
export function removeExtensionElement(element, type, modeler) {
  const modeling = modeler.get('modeling');

  const extensionElement = findExtensionElement(element, type);

  if (!extensionElement) {
    return;
  }

  const businessObject = getBusinessObject(element);

  const extensionElements = businessObject.get('extensionElements');

  modeling.updateModdleProperties(element, extensionElements, {
    values: extensionElements.get('values').filter((value) => {
      return value !== extensionElement;
    })
  });
}

/**
 * Get an extension element of the given type from the business object.
 * @param businessObject
 * @param type
 * @returns {ModdleElement|null}  The extension element or null if not found
 */
export const getExtensionElement = (businessObject, type) => {
  if (!businessObject?.extensionElements?.values) {
    return null;
  }
  return (
    businessObject.extensionElements.values.filter((extensionElement) => {
      return extensionElement.$instanceOf(type);
    })[0] || null
  );
};
