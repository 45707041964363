/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { clustersStore, currentDiagramStore, organizationStore, processApplicationStore } from 'stores';
import { Deploy } from 'icons';
import { getUrlParam } from 'utils/url-params';
import { ClusterUpdateNotification } from 'components';
import { deploymentPermissionsStore, deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import {
  deploymentErrorsStore,
  isDeployedModalVisibleStore,
  executeModalStore,
  isLinkingImprovementsModalVisibleStore
} from 'App/Pages/Diagram/stores';
import { checkIfNoClusterPresent, showClusterRequiredNotification } from 'features/cluster-required-notification';

import StartInstanceButton from './StartInstanceButton';
import RbaResourceDeployedModal from './rbaResourceDeployedModal';
import * as Styled from './DeploymentActions.styled';
import LinkingImprovementsModal from './linkingImprovementsModal';
import ClusterCreationModal, { clusterCreationModalStore } from './clusterCreationModal';
import DeploymentErrorsModal from './deploymentErrorsModal';
import MainProcessRedirectModal, {
  mainProcessRedirectModalStore,
  useMainProcessRedirect
} from './mainProcessRedirectModal';
import DefineStagesModal, { defineStagesModalStore } from './defineStagesModal';

const DeploymentActions = () => {
  const { isDeployOrStartInstanceButtonPresent, isAllowedToStartInstance } = deploymentPermissionsStore;
  const { deploymentErrors, isErrorsModalVisible, errorsModalTitle, errorsModalNotification } = deploymentErrorsStore;

  const { isVisible: isExecuteModalVisible, setMode: setExecuteModalMode } = executeModalStore;
  const { open: openClusterCreationModal } = clusterCreationModalStore;

  const {
    isVisible: isDeployedModalVisible,
    reset: resetDeployedModal,
    hide: hideDeployedModal,
    processId,
    decisionIds
  } = isDeployedModalVisibleStore;

  const { availableClusters, watchClustersAndUpdate } = clustersStore;
  const hasAvailableClusters = availableClusters?.length > 0;

  const {
    isVisible: isImprovementsModalVisible,
    reset: resetImprovementsModal,
    hide: hideImprovementsModal,
    selectedClusterVersion
  } = isLinkingImprovementsModalVisibleStore;

  const { open: openMainProcessRedirectModal, close: closeMainProcessRedirectModal } = mainProcessRedirectModalStore;

  const { diagram } = currentDiagramStore.state;

  const { fromAProcessApplication, processApplication, loading: processApplicationIsLoading } = processApplicationStore;

  const areDeployAndRunButtonsDisabled = !fromAProcessApplication ? false : processApplicationIsLoading;

  const isMainProcessInAProcessApplication = diagram?.id === processApplication.mainProcessFileId;

  const { shouldShowRedirectModal, performRedirect } = useMainProcessRedirect({
    isMainProcess: isMainProcessInAProcessApplication,
    inProcessApplicationContext: fromAProcessApplication
  });

  useEffect(() => {
    setExecuteModalMode(getUrlParam('modal') || undefined);

    return () => {
      clustersStore.clearWatchClustersTimer();
    };
  }, []);

  useEffect(() => {
    if (diagram) {
      deploymentStore.init?.(diagram.id, diagram.processId);
      deploymentPermissionsStore.init();
    }

    return () => {
      deploymentStore.reset?.();
      deploymentPermissionsStore.reset();
      hideImprovementsModal();
    };
  }, [diagram]);

  const shouldRenderClusterCreationJourneyDialog =
    !hasAvailableClusters && organizationStore.shouldSeeClusterCreationJourney();
  let nextCreationStep = useRef(null);

  const onDeploy = () => {
    if (checkIfNoClusterPresent()) {
      showClusterRequiredNotification();
      return;
    }

    if (shouldShowRedirectModal) {
      openMainProcessRedirectModal('deploy');
    } else if (shouldRenderClusterCreationJourneyDialog) {
      nextCreationStep.current = 'deploy';
      openClusterCreationModal();
    } else {
      setExecuteModalMode('deploy');
    }
  };
  const onExecute = () => {
    if (checkIfNoClusterPresent()) {
      showClusterRequiredNotification();
      return;
    }

    if (shouldShowRedirectModal) {
      openMainProcessRedirectModal('execute');
    } else if (shouldRenderClusterCreationJourneyDialog) {
      nextCreationStep.current = 'execute';
      openClusterCreationModal();
    } else {
      setExecuteModalMode('execute');
    }
  };

  const onMainProcessRedirect = () => {
    const deployOnly = mainProcessRedirectModalStore.actionType === 'deploy';
    closeMainProcessRedirectModal();
    performRedirect(processApplication.mainProcessFileId, deployOnly);
  };

  const deployButton = (
    // @ts-expect-error TS2769
    <Styled.PrimaryButton
      data-test="deploy-button"
      title={fromAProcessApplication ? 'Deploys the whole process application' : 'Deploys the current diagram'}
      size="small"
      onClick={onDeploy}
      disabled={areDeployAndRunButtonsDisabled}
    >
      <Deploy />
      Deploy
    </Styled.PrimaryButton>
  );

  const clusterCreationModal = (
    <ClusterCreationModal
      onClose={() => {
        clustersStore.clearWatchClustersTimer();
        nextCreationStep.current = null;
      }}
      onSubmit={async () => {
        const updated = await watchClustersAndUpdate();
        if (updated) {
          setExecuteModalMode(nextCreationStep.current);
          nextCreationStep.current = null;
        }
      }}
    />
  );

  return (
    <Styled.DeploymentActions>
      {isDeployOrStartInstanceButtonPresent && (
        <>
          {isAllowedToStartInstance ? (
            <>
              {deployButton}{' '}
              <ClusterUpdateNotification
                isModalOpen={isExecuteModalVisible}
                latestDeployedClusterId={deploymentStore.latestDeployedCluster?.uuid}
              >
                <StartInstanceButton
                  onExecute={onExecute}
                  runButtonTitle={
                    fromAProcessApplication
                      ? 'Deploys the whole process application (if not already deployed) and starts a new instance of the main process'
                      : 'Deploys the current process (if not already deployed) and starts a new instance'
                  }
                  isRunDisabled={areDeployAndRunButtonsDisabled}
                />
              </ClusterUpdateNotification>
            </>
          ) : (
            <ClusterUpdateNotification
              isModalOpen={isExecuteModalVisible}
              latestDeployedClusterId={deploymentStore.latestDeployedCluster?.uuid}
            >
              {deployButton}
            </ClusterUpdateNotification>
          )}

          {shouldRenderClusterCreationJourneyDialog && clusterCreationModal}

          {(isMainProcessInAProcessApplication || defineStagesModalStore.executeModalMode === 'play') && (
            <DefineStagesModal
              onClose={() => {
                // Introducing a delay between transitioning from DefineStagesModal back to ExecuteModal
                setTimeout(() => {
                  setExecuteModalMode(defineStagesModalStore.executeModalMode);
                }, 500);
              }}
            />
          )}

          <LinkingImprovementsModal
            open={isImprovementsModalVisible}
            onClose={() => {
              hideImprovementsModal();
              resetImprovementsModal();
            }}
            selectedClusterVersion={selectedClusterVersion}
          />
          <RbaResourceDeployedModal
            open={isDeployedModalVisible}
            onClose={() => {
              hideDeployedModal();
              resetDeployedModal();
            }}
            processId={processId}
            decisionIds={decisionIds}
          />
          <DeploymentErrorsModal
            open={isErrorsModalVisible}
            onClose={() => {
              deploymentErrorsStore.hideErrorsModal();
            }}
            errors={deploymentErrors?.errors}
            title={errorsModalTitle}
            notification={errorsModalNotification}
          />
          <MainProcessRedirectModal onRedirect={onMainProcessRedirect} />
        </>
      )}
    </Styled.DeploymentActions>
  );
};

export default observer(DeploymentActions);
