/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { InlineNotification, ActionableNotification } from '@carbon/react';

import { isDeployMode, isExecuteMode, isPlayMode } from 'App/Pages/Diagram/Deployment/executeModal/modeUtils';
import { getSanitizedZeebeVersionString } from 'utils/cluster/cluster-version-util';
import { processApplicationStore, clustersStore, organizationStore, userStore } from 'stores';
import { clusterService, tracingService, trackingService } from 'services';
import { isLinkingImprovementsModalVisibleStore } from 'App/Pages/Diagram/stores';
import { deploymentPermissionsStore, deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import { Button, Dialog, Link, Spinner } from 'primitives';
import config from 'utils/config';
import capitalize from 'utils/capitalize';
import pluralize from 'utils/pluralize';
import { useExecutionPayload } from 'App/Pages/Diagram/Deployment/executeModal/AddVariable';
import SubmitInProgress from 'App/Pages/Diagram/Deployment/executeModal/SubmitInProgress';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import getClusterStatus from 'utils/cluster/get-cluster-status';
import { CLUSTER_STATUS } from 'utils/cluster/constants';

import ClusterSelection from './ClusterSelection';
import NoPermissionMessage from './NoPermissionMessage';
import * as Styled from './ExecuteModal.styled';
import useStagesClusters from './Stages/useStagesClusters';

export const ExecuteModal = ({ open = false, onClose = () => {}, mode, deployTarget = 'diagram' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorLoadingClusters, setIsErrorLoadingClusters] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { selectedClusterId, setSelectedClusterId } = deploymentStore;
  const { isAllowedToDeploy, isAllowedToStartInstance, isAllowedToDeployToSelectedCluster } =
    deploymentPermissionsStore;

  const {
    availableClusters: allAvailableClusters,
    availableDevClusters,
    unavailableClusters
  } = processApplicationStore.fromAProcessApplication
    ? useStagesClusters({
        availableClusters: clustersStore.availableClusters,
        availableDevClusters: clustersStore.availableDevClusters,
        unavailableClusters: clustersStore.unavailableClusters,
        // @ts-expect-error TS2339
        processApplication: processApplicationStore.processApplication
      })
    : clustersStore;

  const isAllowedToPlay = organizationStore.hasClustersReadPermission && organizationStore.hasWorkflowsCreatePermission;
  const isAllowed = isExecuteMode(mode)
    ? isAllowedToStartInstance
    : isPlayMode(mode)
      ? isAllowedToPlay
      : isAllowedToDeploy;

  const { isTrialExpired, isUsingTrial, hasBillingPermission, currentOrganization } = organizationStore;
  const { isLoading: isFormLinkingLoading } = formLinkStore;

  const availableClusters = isPlayMode(mode) ? availableDevClusters : allAvailableClusters;
  // load available clusters
  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        if (open && isAllowed) {
          setIsLoading(true);
          const clusters = await clusterService.getClusters(organizationStore.currentOrganizationId);
          if (mounted) {
            clustersStore.setClusters(clusters);
          }
          if (processApplicationStore.fromAProcessApplication) {
            await processApplicationStore.getExternalResources();
          }
        } else {
          setPayload('');
          setIsErrorLoadingClusters(false);
        }
      } catch (ex) {
        // @ts-expect-error TS2554
        tracingService.traceError(ex);
        setIsErrorLoadingClusters(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [open]);

  // check if user has revoked roles
  useEffect(() => {
    if (open && isAllowed) {
      userStore.checkRevokedRoles();
    }
  }, [open]);

  useEffect(() => {
    const isSelectedClusterPresentInClusters =
      deploymentStore.selectedClusterId &&
      availableClusters.some(({ uuid }) => uuid === deploymentStore.selectedClusterId);

    if (!isSelectedClusterPresentInClusters) {
      setSelectedClusterId(availableClusters[0]?.uuid);
    }
  }, [availableClusters]);

  const [payload, setPayload, isValidPayload] = useExecutionPayload();

  const selectedCluster = availableClusters?.find(({ uuid }) => uuid === selectedClusterId);

  const componentsForHealthCheck = isPlayMode(mode) ? ['zeebe', 'operate', 'tasklist'] : ['zeebe'];
  const isSelectedClusterUnhealthy =
    getClusterStatus(selectedCluster?.status, componentsForHealthCheck) !== CLUSTER_STATUS.HEALTHY;

  function getDisabledButtonText() {
    if (formLinkStore.diagramHasConflictingLinkedForms) {
      return `${capitalize(deployTarget)} has conflicting linked forms`;
    }

    if (!isExecuteMode(mode)) {
      return `${capitalize(deployTarget)} can only be deployed in healthy clusters`;
    }

    return 'Instances can only be started in healthy clusters';
  }

  const hasUnavailableClusters = unavailableClusters?.length > 0;

  let content;

  function getTextForUsersWithBillingPermissions() {
    return (
      <Link
        href={`${organizationStore.consoleDashboardPageUrl}/checkout`}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          const displayedSalesPlan = organizationStore.currentOrganizationInfo?.salesPlan?.type;
          trackingService.trackCheckoutPage(displayedSalesPlan);
        }}
      >
        Upgrade your plan
      </Link>
    );
  }

  if (isLoading) {
    content = (
      <Styled.Loader>
        <Spinner />
        <Styled.LoaderDescription>Checking cluster availability</Styled.LoaderDescription>
      </Styled.Loader>
    );
  } else if (isErrorLoadingClusters) {
    content = (
      <InlineNotification
        hideCloseButton
        kind="error"
        title="Unable to check cluster availability"
        subtitle="Please wait a few minutes and try again"
        lowContrast
      />
    );
  } else if (isUsingTrial && isTrialExpired) {
    content = (
      <>
        <Styled.ExpiredTrialHeader>
          Upgrade plan to {isExecuteMode(mode) ? 'start instance' : `deploy ${deployTarget}`}
        </Styled.ExpiredTrialHeader>
        <Styled.ExpiredTrialText>
          Clusters are not available on the Modeling plan.{' '}
          {hasBillingPermission
            ? getTextForUsersWithBillingPermissions()
            : 'Contact the admin or owner to upgrade your plan'}{' '}
          in order to create a cluster and{' '}
          {isExecuteMode(mode) ? 'start instances' : `deploy ${pluralize(deployTarget, 2)}`}.
        </Styled.ExpiredTrialText>
      </>
    );
  } else if (!isAllowed) {
    content = <NoPermissionMessage mode={mode} />;
  } else {
    content = (
      <>
        {/* @ts-expect-error TS2741 */}
        <ClusterSelection
          clusters={isPlayMode(mode) ? availableDevClusters : availableClusters}
          mode={mode}
          onClose={onClose}
          payload={payload}
          setPayload={setPayload}
          isValidPayload={isValidPayload}
          componentsForHealthCheck={componentsForHealthCheck}
        />

        {isPlayMode(mode) && !processApplicationStore.fromAProcessApplication && availableDevClusters.length === 0 && (
          <ActionableNotification
            hideCloseButton
            inline
            kind="warning"
            title="No development cluster available"
            subtitle="Play requires a development cluster to run. Create one in console."
            actionButtonLabel="Open console"
            onActionButtonClick={() => {
              return window.open(
                `https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/clusters?create=true`
              );
            }}
            lowContrast
          />
        )}

        {hasUnavailableClusters && !isPlayMode(mode) && (
          // @ts-expect-error TS2739
          <ClusterSelection clusters={unavailableClusters} mode={mode} disabled />
        )}
      </>
    );
  }

  const hasAvailableAction = !isUsingTrial || !isTrialExpired || hasBillingPermission;

  const onCloseHandler = (selectedCluster) => {
    const zeebeVersion = selectedCluster?.generation?.versions?.zeebe;
    isLinkingImprovementsModalVisibleStore.setSelectedClusterVersion(getSanitizedZeebeVersionString(zeebeVersion));
    // @ts-expect-error TS2554
    onClose({
      hasBeenExecutedOnClusterLT8_4: isLinkingImprovementsModalVisibleStore.isClusterVersionLT8_4(zeebeVersion)
    });
  };

  const handleDeployClick = async () => {
    setIsSubmitting(true);

    isDeployMode(mode)
      ? await deploymentStore.deploy(onCloseHandler)
      : await deploymentStore.execute(onCloseHandler, payload.trim() ? JSON.parse(payload) : {});

    setIsSubmitting(false);
  };

  const handlePlayClick = async () => {
    setIsSubmitting(true);

    deploymentStore.setSelectedClusterIdForPlay(selectedClusterId);
    // @ts-expect-error TS2554
    onClose({ shouldProceedToPlay: true });

    setIsSubmitting(false);
  };

  const noDeployPermissions = !isAllowedToDeploy || !isAllowedToDeployToSelectedCluster;
  const noAvailableClusters = !availableClusters || availableClusters.length === 0 || isSelectedClusterUnhealthy;

  return (
    // @ts-expect-error TS2739
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>
        <Dialog.Title>{isExecuteMode(mode) ? 'Start instance' : `Deploy ${deployTarget}`}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Content>{content}</Dialog.Content>
      <Dialog.Footer>
        {isSubmitting ? (
          <SubmitInProgress mode={mode} />
        ) : (
          <>
            {/* @ts-expect-error TS2322 */}
            <Button variant={hasAvailableAction ? 'secondary' : 'primary'} onClick={onClose}>
              {hasAvailableAction ? 'Cancel' : 'Close'}
            </Button>
            {isUsingTrial && isTrialExpired && hasBillingPermission && (
              <Styled.SubscribeLink
                href={`https://console.${config.camundaCloudBaseDomain}/org/${currentOrganization.id}/checkout`}
              >
                {/* @ts-expect-error TS2559 */}
                <Button>Upgrade now</Button>
              </Styled.SubscribeLink>
            )}
            {(!isUsingTrial || !isTrialExpired) && (
              <div
                title={
                  isSelectedClusterUnhealthy || formLinkStore.diagramHasConflictingLinkedForms
                    ? getDisabledButtonText()
                    : ''
                }
              >
                {!isPlayMode(mode) ? (
                  // @ts-expect-error TS2322
                  <Button
                    onClick={handleDeployClick}
                    disabled={
                      noDeployPermissions ||
                      noAvailableClusters ||
                      !isValidPayload ||
                      isFormLinkingLoading ||
                      formLinkStore.diagramHasConflictingLinkedForms
                    }
                    data-test="deploy-action"
                  >
                    {isDeployMode(mode) ? 'Deploy' : 'Run'}
                  </Button>
                ) : (
                  // @ts-expect-error TS2322
                  <Button
                    onClick={handlePlayClick}
                    disabled={
                      !isAllowedToPlay ||
                      !isAllowedToDeployToSelectedCluster ||
                      noAvailableClusters ||
                      !availableDevClusters.find((cluster) => cluster.uuid === selectedClusterId)
                    }
                    data-test="continue-to-play-action"
                  >
                    Continue
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </Dialog.Footer>
    </Dialog>
  );
};

ExecuteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  mode: PropTypes.oneOf(['deploy', 'execute', 'play'])
};

export default observer(ExecuteModal);
