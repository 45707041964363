/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import { Link as _Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const LinkStyleProps = `
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const Link = styled(_Link)([LinkStyleProps]);
