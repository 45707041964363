/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Link, Modal, TextInput } from '@carbon/react';
import { Launch } from '@carbon/icons-react';

import useShowBackgroundSyncNotification from 'features/git-sync/useShowBackgroundSyncNotification';
import gitSettingsStore from 'features/git-sync/settings/GitSettingsStore';

import gitSyncStore, { GitSyncStatus } from './GitSyncStore';
import * as Styled from './GitSyncDialog.styled';
import InlineNotification from './InlineNotification';
import ConflictsHandler from './ConflictsHandler';

const GitSyncDialog = () => {
  const {
    shouldShowDialog,
    versionTag,
    status,
    isPulling,
    isPushing,
    pullingStatus,
    pushingStatus,
    pullMessage,
    pushMessage,
    hasValidVersion,
    versionTagMessage,
    hasConflicts,
    hasFinished,
    hasErrors
  } = gitSyncStore;

  const { settings, fullRepositoryPath, shortRepositoryPath, providerLabel } = gitSettingsStore;
  const { branchName } = settings;

  const handleClose = () => {
    gitSyncStore.close();
  };

  const getLoadingDescription = () => {
    if (hasFinished) {
      return 'Synchronized';
    }

    if (hasConflicts) {
      return 'Conflict';
    }

    if (hasErrors) {
      return 'Failed';
    }

    return 'Synchronizing...';
  };

  useShowBackgroundSyncNotification();

  return (
    <Modal
      open={shouldShowDialog}
      size="sm"
      modalHeading={`Sync with ${providerLabel}`}
      primaryButtonText="Synchronize"
      primaryButtonDisabled={!hasValidVersion}
      secondaryButtonText="Cancel"
      onRequestSubmit={() => gitSyncStore.start()}
      onSecondarySubmit={() => handleClose()}
      onRequestClose={() => handleClose()}
      // @ts-expect-error TS2322
      loadingStatus={status}
      loadingDescription={getLoadingDescription()}
    >
      <>
        <Styled.Message>
          Synchronize your process application with your defined repository.
          <Styled.Property>
            <Styled.PropertyName>Branch</Styled.PropertyName>
            <Styled.PropertyValue>{branchName}</Styled.PropertyValue>
          </Styled.Property>
          <Styled.Property>
            <Styled.PropertyName>Repository</Styled.PropertyName>
            <Styled.PropertyValue>
              <Link href={fullRepositoryPath} target="_blank">
                {shortRepositoryPath}&nbsp;
                <Launch />
              </Link>
            </Styled.PropertyValue>
          </Styled.Property>
        </Styled.Message>

        <Styled.VersionTagDescription>
          <span>
            Name your version with a version tag to track it through the development lifecycle and ensure the right
            version is called.
          </span>
          <Link
            href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#versioning"
            target="_blank"
          >
            Learn more
          </Link>
        </Styled.VersionTagDescription>

        <TextInput
          id="git-version-tag"
          type="text"
          labelText="Version tag"
          value={versionTag}
          helperText={versionTagMessage}
          onFocus={(e) => e.target.select()}
          onChange={(e) => gitSyncStore.setVersionTag(e.target.value)}
          disabled={status !== GitSyncStatus.INACTIVE}
          data-modal-primary-focus
        />

        {isPulling && (
          <Styled.ProgressBar
            label={`Pulling latest changes from ${providerLabel}`}
            helperText={pullMessage}
            size="small"
            // @ts-expect-error TS2769
            status={pullingStatus}
          />
        )}

        {isPushing && (
          <Styled.ProgressBar
            label={`Pushing changes to ${providerLabel}`}
            helperText={pushMessage}
            size="small"
            // @ts-expect-error TS2769
            status={pushingStatus}
          />
        )}

        {hasConflicts ? <ConflictsHandler /> : <InlineNotification />}
      </>
    </Modal>
  );
};

export default observer(GitSyncDialog);
