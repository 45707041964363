/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    ${({ $isDragging, $position }) => {
      if ($isDragging) {
        if ($position === 'left' || $position === 'right') {
          return 'cursor: ew-resize;';
        } else {
          return 'cursor: ns-resize;';
        }
      }
    }}
  }
`;

const CommonHandle = css`
  position: absolute;
  z-index: var(--z-resizable-panel-handle);
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: var(--cm-color-blue-base);
  }

  ${({ $position }) => {
    if ($position === 'left' || $position === 'right') {
      return 'cursor: ew-resize;';
    } else {
      return 'cursor: ns-resize;';
    }
  }};

  ${({ $isDragging }) => {
    if ($isDragging) {
      return 'border-color: var(--cm-color-blue-base);';
    }
  }}
`;

const handleSize = 10;
const handleOffset = handleSize / 2;

export const Panel = styled.div`
  position: relative;

  ${({ open, $position, $sizeClosed }) => {
    const measure = $position === 'left' || $position === 'right' ? 'width' : 'height';

    if (!open) {
      return `${measure}: ${$sizeClosed}px !important;`;
    }
  }}

  ${({ $background }) => `background: ${$background};`}

  &.animate {
    transition: ${({ $position }) => {
      return $position === 'left' || $position === 'right' ? 'width 0.2s ease-in-out' : 'height 0.2s ease-in-out';
    }};
  }

  & .content {
    width: 100%;
    height: 100%;

    ${({ $isDragging }) => {
      if ($isDragging) {
        return 'pointer-events: none;';
      }
    }};
  }
`;

export const LeftHandle = styled.div`
  width: ${handleSize}px;
  height: 100%;

  left: 0;
  border-left: ${handleOffset}px solid transparent;

  ${CommonHandle}
`;

export const RightHandle = styled.div`
  width: ${handleSize}px;
  height: 100%;

  right: 0;
  border-right: ${handleOffset}px solid transparent;

  ${CommonHandle}
`;

export const TopHandle = styled.div`
  height: ${handleSize}px;
  width: 100%;

  top: 0;
  border-top: ${handleOffset}px solid transparent;

  ${CommonHandle}
`;

export const BottomHandle = styled.div`
  height: ${handleSize}px;
  width: 100%;

  bottom: 0;
  border-bottom: ${handleOffset}px solid transparent;

  ${CommonHandle}
`;
