/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef, useState } from 'react';
import { Heading, Section } from '@carbon/react';

import { trackingService } from 'services';
import { NonModalDialog } from 'primitives';
import { organizationStore } from 'stores';

import * as Styled from './CamundaDocsAiFeatureDiscovery.styled';
import CamundaDocsAiButton from './CamundaDocsAiButton';

export default function CamundaDocsAiFeatureDiscovery({ location }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const buttonRef = useRef(null);

  return (
    <>
      <CamundaDocsAiButton
        onClick={() => {
          toggle();
          trackingService.trackDocsAIOpen({ enabled: false, from: location });
        }}
        buttonRef={buttonRef}
      />
      {/* @ts-expect-error TS2741 */}
      <NonModalDialog
        maxWidth="350px"
        onClose={() => setIsOpen(false)}
        onSubmit={() => {
          window.open(`${organizationStore.organizationManagementPageUrl}/settings`);
        }}
        open={isOpen}
        primaryButtonText="Enable"
        anchorEl={buttonRef?.current}
        selectorPrimaryFocus=".ai-features-docs-link"
        passiveModal={!organizationStore.hasElevatedOrganizationPermissions}
      >
        <Styled.DialogBody>
          <Section level={4}>
            <Styled.Tag className="some-class" type="cool-gray">
              Alpha feature
            </Styled.Tag>
            <Heading>Camunda Docs AI</Heading>
            <Styled.Description>
              {organizationStore.hasElevatedOrganizationPermissions ? (
                <>
                  Click <strong>Enable</strong> to enable smart AI-powered chatbot that answers your Camunda related
                  questions.
                </>
              ) : (
                <>
                  A smart chatbot that answers your Camunda related questions.
                  <br />
                  <br />
                  Contact your admin to enable this feature.
                </>
              )}
            </Styled.Description>
            <Styled.Link
              href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/advanced-modeling/camunda-docs-ai/"
              aria-label="Read docs"
              target="_blank"
              className="ai-features-docs-link"
            >
              Read docs
            </Styled.Link>
          </Section>
        </Styled.DialogBody>
      </NonModalDialog>
    </>
  );
}
