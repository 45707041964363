/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Pusher from 'pusher-js';

import config from 'utils/config';

export class BaseWebSocket {
  socket = undefined;

  constructor(options) {
    // @ts-expect-error TS2554
    this.createSocket(options);
  }

  subscribe(channelName) {
    return this.socket.subscribe(channelName);
  }

  unsubscribe(channelName) {
    return this.socket.unsubscribe(channelName);
  }

  updateOptions(options) {
    if (this.socket) {
      // @ts-expect-error TS2554
      this.createSocket(options);
    }
  }

  instanciateSocket(options) {
    this.socket = new Pusher(config.pusher.key, options);
  }

  createSocket() {
    throw new Error('Not implemented');
  }
}
