/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { documentExtractionProcess } from './bpmn-snippets';
import runInstanceWithResult from './run-instance-with-result';

export default async function extractDocument({
  idpProjectId,
  clusterId,
  idpDocuments,
  taxonomy,
  modelId,
  baseRequest = {
    authentication: {
      type: 'credentials',
      accessKey: '{{secrets.IDP_AWS_ACCESSKEY}}',
      secretKey: '{{secrets.IDP_AWS_SECRETKEY}}'
    },
    configuration: {
      region: 'us-east-1'
    }
  },
  extractionEngineType = 'AWS_TEXTRACT'
}) {
  const xml = documentExtractionProcess
    .replace('{{EXTRACTION_ENGINE_TYPE}}', extractionEngineType)
    .replace('{{TAXONOMY}}', escapeQuotes(JSON.stringify(taxonomy)))
    .replace('{{CONVERSE_DATA}}', escapeQuotes(JSON.stringify(getConverseData(modelId))))
    .replace('{{BASE_REQUEST}}', escapeQuotes(JSON.stringify(baseRequest)));

  const instancePayloads = idpDocuments.map((document) => ({
    document: {
      'camunda.document.type': document.clusterDocumentType,
      storeId: document.clusterDocumentStoreId,
      documentId: document.clusterDocumentId
    }
  }));
  const results = await runInstanceWithResult(idpProjectId, clusterId, xml, 40, instancePayloads);

  return results
    .filter((result) => result.status === 'fulfilled')
    .map((result) => ({
      processInstanceKey: result.value.processInstanceKey,
      clusterDocumentId: result.value.variables.document.documentId,
      variables: JSON.parse(result.value.variables.result.response)
    }));
}

function escapeQuotes(string) {
  return string.replaceAll('"', '&#34;');
}

function getConverseData(modelId) {
  // TODO: Replace with model specific converse data when available.
  return {
    modelId: modelId,
    maxTokens: 512,
    temperature: 0.5,
    topP: '0.9'
  };
}
