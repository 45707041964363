/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { Dots } from 'icons';
import { Button } from 'primitives';
import textEnhancer from 'utils/text-enhancer';
import { commentsStore, userStore } from 'stores';
import { Dropdown } from 'components';

import SuggestionsInput from './SuggestionsInput';
import highlightUsers from './utils/highlight-users';
import * as Styled from './Comment.styled';

const EDIT_COMMENT = 'editing';
const VIEW_COMMENT = 'viewing';
const DELETE_COMMENT = 'deleting';

export default function Comment({ comment }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [view, setView] = useState(VIEW_COMMENT);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [value, setValue] = useState(comment.content);
  const commentRef = useRef();

  const isCommentOwner = userStore.isCurrentUser(comment.author);
  const preview = highlightUsers(textEnhancer(comment.content), commentsStore.state.suggestions);
  const isCommentValid = value.trim().length > 0;

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (isCommentValid) {
      setButtonDisabled(true);

      await commentsStore.updateComment({
        content: value,
        commentId: comment.id
      });

      setView('viewing');
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    const comment = commentRef.current;
    // @ts-expect-error TS18048
    const commentBottom = comment.getBoundingClientRect().bottom;
    // @ts-expect-error TS18048
    const parentBottom = comment.parentNode.getBoundingClientRect().bottom;

    if (view != VIEW_COMMENT && [EDIT_COMMENT, DELETE_COMMENT].includes(view) && commentBottom > parentBottom) {
      // @ts-expect-error TS18048
      comment.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, [view]);

  return (
    // @ts-expect-error TS2769
    <Styled.Comment ref={commentRef} hasHover={isCommentOwner} data-test="comment-wrapper">
      {/* @ts-expect-error TS2769 */}
      <Styled.UserAvatar size="small" fullname={comment.author.name} disableTooltip />

      <Styled.CommentContent>
        {view != EDIT_COMMENT && (
          <Styled.CommentHeader>
            <Styled.CommentUser data-test="comment-author">
              {isCommentOwner ? 'You' : comment.author.name}
            </Styled.CommentUser>
            <Styled.CommentDate dateTime={comment.created} data-test="comment-date">
              {moment(comment.created).format('h:mm')}
            </Styled.CommentDate>

            {isCommentOwner && (
              <Styled.EditCommentButton
                onClick={(evt) => setAnchorEl(evt.currentTarget)}
                data-test="comment-button"
                title="Show comment actions"
              >
                <Dots />
              </Styled.EditCommentButton>
            )}
          </Styled.CommentHeader>
        )}

        {view != EDIT_COMMENT && (
          <Styled.CommentText data-test="comment-content" dangerouslySetInnerHTML={{ __html: preview }} />
        )}

        {view != 'viewing' && (
          <form onSubmit={handleSubmit}>
            {view == EDIT_COMMENT && (
              <SuggestionsInput
                autofocus
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
                onEnter={handleSubmit}
              />
            )}

            <Styled.EditCommentFormFooter>
              {/* @ts-expect-error TS2322 */}
              <Button
                size="small"
                variant="secondary"
                type="button"
                data-test="comment-cancel-action"
                onClick={() => setView('viewing')}
              >
                Cancel
              </Button>

              {view == EDIT_COMMENT && (
                // @ts-expect-error TS2322
                <Button
                  disabled={!isCommentValid || buttonDisabled}
                  size="small"
                  variant="primary"
                  type="submit"
                  data-test="comment-submit-update"
                >
                  Update Comment
                </Button>
              )}
              {view == DELETE_COMMENT && (
                // @ts-expect-error TS2322
                <Button
                  type="button"
                  size="small"
                  variant="primary"
                  data-test="comment-submit-delete"
                  onClick={() => commentsStore.removeComment(comment)}
                >
                  Delete Comment
                </Button>
              )}
            </Styled.EditCommentFormFooter>
          </form>
        )}
      </Styled.CommentContent>

      <Dropdown size="small" open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {/* @ts-expect-error TS2739 */}
        <Dropdown.ListItem
          onClick={() => {
            setAnchorEl(null);
            setView(EDIT_COMMENT);
          }}
          data-test="edit-comment"
        >
          Edit Comment
        </Dropdown.ListItem>
        {/* @ts-expect-error TS2739 */}
        <Dropdown.ListItem
          onClick={() => {
            setAnchorEl(null);
            setView(DELETE_COMMENT);
          }}
          data-test="delete-comment"
        >
          Delete Comment
        </Dropdown.ListItem>
      </Dropdown>
    </Styled.Comment>
  );
}
