/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { InlineNotification } from '@carbon/react';

import { organizationStore } from 'stores';

/**
 * It wraps the `creationElement` with all the permission, and trial checks, so that we have proper handling with notifications
 *
 * @param {Object} props
 * @param {JSX.Element} [props.creationElement] it can be a link, a button or whatever can be used to trigger the cluster creation
 * @param {String} [props.clusterType] a specific cluster type (also known as 'tag')
 * @return {JSX.Element}
 * @constructor
 */
export default function CreateNewClusterSection({ creationElement, clusterType }) {
  const specificCluster = clusterType ? `${clusterType} cluster` : 'cluster';

  if (!organizationStore.hasClustersCreatePermission) {
    return (
      <InlineNotification
        kind="warning"
        lowContrast
        hideCloseButton
        title="Missing permissions"
        subtitle={`contact your organization admin to create a ${specificCluster}.`}
      />
    );
  } else if (organizationStore.isUsingTrial && organizationStore.isTrialExpired) {
    return (
      <InlineNotification
        kind="warning"
        lowContrast
        hideCloseButton
        title="Cluster creation is not available on this modeling plan"
        subtitle="contact the admin or owner to upgrade your plan."
      />
    );
  }

  return creationElement;
}
