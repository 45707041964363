/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// File contains the hard-coded version of the client and webapp, will be
// overwritten by CI build, do not move or modify manually!
export const version = 'c1bf838f80ab069a7387f1b63d51dcf879a08fc2';

export const versionShort = (() => {
  return version.substring(0, 7);
})();
