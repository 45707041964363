/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled, { css } from 'styled-components';
import { StructuredListCell } from '@carbon/react';

import { Sidebar } from 'primitives';

export const SidebarTitle = styled(Sidebar.Title)`
  border-bottom: unset;
`;

export const ChangeListContainer = styled.div`
  overflow-y: auto;
`;

export const EmptyState = styled.div`
  padding: 5px 10px;
`;

export const TagCell = styled(StructuredListCell)`
  min-width: 93px;
`;

export const highlightSelectedFlowElement = css`
  .selected path {
    stroke: var(--cds-blue-60) !important;
  }
`;
