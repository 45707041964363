/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Service from 'services/Service';
import config from 'utils/config';
import {
  isDisallowedDomain,
  isProtocolAllowedOrMissing,
  isValidUrl,
  prependHttpsIfMissing
} from 'utils/validate-imported-resource';
import { DEFAULT, IMPORT_DIALOG_SOURCE } from 'utils/constants';
import { milestoneStore, projectStore, userStore } from 'stores';
import { fileService, tracingService, trackingService } from 'services';
import { getExportType } from 'utils/helpers';
import FileMapper from 'utils/file-mapper';

class BaseImportService extends Service {
  /**
   * Returns the output of the import process. It should be overwritten by the child class to return the correct output.
   * @returns
   */
  async prepareOutput() {
    return {
      valid: false,
      error: null,
      reasons: [],
      templates: []
    };
  }

  /**
   * Fetches the resources and returns the output of the import process, composed of the valid and invalid resources.
   * @param {*} listOfResources The list of resources to import
   * @ts-expect-error TS1064 returns {Object} The output of the import process
   */
  async fetchResources(listOfResources) {
    const resources = this.#getUniqueListOfResources(listOfResources);
    const errors = [];
    const metadata = [];

    const results = await Promise.allSettled(resources.map((resource) => this.#fetchResource(resource)));
    for (const result of results) {
      const index = results.indexOf(result);
      if (result.status === 'fulfilled' && result.value) {
        // @ts-expect-error TS2554
        const { valid, error, reasons, templates, type } = await this.prepareOutput(result.value, resources[index]);

        if (valid) {
          metadata.push({
            source: resources[index],
            templates,
            type
          });
        } else {
          errors.push({
            source: resources[index],
            error: error || 'Invalid schema',
            reasons,
            templates
          });
        }
      } else {
        errors.push({
          source: resources[index],
          // @ts-expect-error TS2339
          error: result.reason || 'Unknown error'
        });
      }
    }

    return {
      errors,
      metadata
    };
  }

  extractFileName(template, _resource) {
    return template.name;
  }

  extractIcon(template, _resource) {
    return template?.icon?.contents;
  }

  executePostImportAction(history, selectedProject, _importedFiles) {
    this.#goToProject(history, selectedProject.id);
  }

  duplicatesNotAllowed() {
    return false;
  }

  getAllDuplicatedResources(_projectId, _templateIdsToPrepare) {
    return [];
  }

  #filterResourcesByValidUrl(resources) {
    return resources.reduce((acc, resource) => {
      try {
        if (!isProtocolAllowedOrMissing(resource)) {
          return acc;
        }

        resource = prependHttpsIfMissing(resource);

        if (!isValidUrl(resource) || isDisallowedDomain(resource)) {
          return acc;
        }

        return [...acc, new URL(resource).toString()];
      } catch (ex) {
        tracingService.traceError(ex, 'Failed to filter resources by valid URL');
        return acc;
      }
    }, []);
  }

  /**
   * Returns a list of valid and unique resources to import; it also sets the protocol to https if it's missing.
   * @param {*} resources The list of resources to import
   * @returns {Array} The list of unique resources to import
   */
  #getUniqueListOfResources(resources) {
    const validResources = this.#filterResourcesByValidUrl(resources);
    const uniqueList = new Set(validResources);
    return this.#limitTheNumberOfTemplates(Array.from(uniqueList));
  }

  async #fetchResource(url) {
    return this.get(`/fetch-resources?url=${url}`);
  }

  #limitTheNumberOfTemplates(templates) {
    if (templates.length > config.importResources.maxCountOfFiles) {
      return templates.slice(0, config.importResources.maxCountOfFiles);
    }

    return templates;
  }

  #goToProject(history, projectId) {
    history.push(`/projects/${projectId}`);
  }

  async importNoDuplicateCheck({ selectedProject, resourcesMetadata, folderId, source = IMPORT_DIALOG_SOURCE }) {
    const response = [];
    const errors = {};

    for (let resource of resourcesMetadata) {
      for (let template of resource.templates) {
        try {
          const importedFile = await this.#importSingleTemplateFromResource({
            selectedProject,
            resource,
            template,
            source,
            folderId
          });
          response.push(importedFile);
        } catch (ex) {
          tracingService.traceError(ex, 'Failed to import resource');
          errors[resource.source] = 'File import failed';
        }
      }
    }

    const fileUrlsFailedToImport = Object.keys(errors);

    if (fileUrlsFailedToImport.length > 0) {
      console.warn('URLs of files that failed to import:', errors);
      throw new Error(`Could not import ${fileUrlsFailedToImport.length} resources.`);
    }

    return response;
  }

  async #importSingleTemplateFromResource({ selectedProject, resource, template, source, folderId }) {
    const projectId = selectedProject.id;
    const fileName = this.extractFileName(template, resource);
    const type = resource.type;

    const content = getExportType(type) === 'json' ? JSON.stringify(template, undefined, 2) : template;

    const baseFile = {
      type,
      name: fileName,
      projectId,
      importUrl: resource.source,
      originAppInstanceId: userStore.originAppInstanceId,
      content
    };

    const file = await projectStore.addAdditionalAttributesToFile(baseFile);
    const generatedFileContents = new FileMapper(type).generate(file);

    try {
      const createdFile = await fileService.create({
        type,
        projectId,
        folderId,
        source,
        isFromTemplate: false,
        ...generatedFileContents
      });

      if (!createdFile) {
        const error = new Error('Could not create file');
        // @ts-expect-error TS2554
        tracingService.traceError(error);
        return Promise.reject(error);
      } else {
        trackingService.trackCreateFile(createdFile.id, source, type, DEFAULT, file?.content, true, null, false);
      }

      await milestoneStore.create({
        file: createdFile,
        append: false,
        name: 'Marketplace resource',
        organizationPublic: false,
        origin: 'publish'
      });

      trackingService.trackImportedResourcePublish({ urls: [resource.source], projectId });

      return { ...createdFile, type };
    } catch (e) {
      // @ts-expect-error TS2554
      tracingService.traceError(e);
      return Promise.reject(e);
    }
  }
}

export default BaseImportService;
