/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TargetSelector } from 'components';
import { projectStore } from 'stores';
import { BULK, ZIP } from 'utils/constants';
import hasAccess, { actions } from 'utils/user-access';
import { getExportType, isFile, isIdpApplication, isMainProcess, isProcessApplication } from 'utils/helpers';
import { trackingService } from 'services';

export default function getBatchActions() {
  const history = useHistory();
  const { folder, isFolder, fromProcessApplicationId, fromIdpApplicationId, project } = projectStore;

  const [targetSelectorParams, setTargetSelectorParams] = useState({
    moveAnchorEl: null,
    selection: [],
    invalidTargetIds: []
  });

  const handleMove = async (target) => {
    setTargetSelectorParams((params) => ({ ...params, moveAnchorEl: null }));

    const selection = targetSelectorParams.selection.map((project) => project.entityAction);

    if (target.projectId) {
      const redirectTarget = isProcessApplication(target) ? 'process-applications' : 'folders';
      if (await projectStore.moveEntities(selection, target.projectId, target.id)) {
        history.push(`/${redirectTarget}/${target.id}`);
      }
    } else {
      if (await projectStore.moveEntities(selection, target.id, undefined)) {
        history.push(`/projects/${target.id}`);
      }
    }
  };

  const allSelectedItemsAreFiles = (selection) => selection.every((project) => isFile(project.entityAction));

  const selectedItemsContainsIdpApplication = (selection) =>
    selection.some((entity) => isIdpApplication(entity.entityAction));

  const noMainProcessInvolved = (selection) => selection.every((entity) => !isMainProcess(entity));

  const handleDownload = async (items) => {
    const selection = items.map((project) => project.entityAction);
    const [files, folders] = projectStore.extractFilesAndFolders(selection);
    const fileSize = await projectStore.downloadEntities(selection);
    if (fileSize) {
      trackingService.trackFileExport({
        fileId: selection.length === 1 ? selection[0].id : BULK,
        fileTypeKey: selection.length === 1 ? selection[0].type : BULK,
        // @ts-expect-error TS2332
        exportType: selection.length === 1 ? getExportType(selection[0].type) : ZIP,
        from: isFolder ? 'folder' : 'project',
        fileCount: files.length,
        folderCount: folders.length,
        fileSize
      });
    }
  };

  return {
    batchActions: [
      {
        action: (selection) => handleDownload(selection),
        isAllowed: (selection) =>
          hasAccess(project, actions.VIEW_PROJECT) && !selectedItemsContainsIdpApplication(selection),
        title: 'Download'
      },
      {
        action: (selection, clearSelection) => {
          projectStore.duplicateFiles(selection);
          clearSelection();
        },
        isAllowed: (selection) => hasAccess(project, actions.MODIFY_DIAGRAM) && allSelectedItemsAreFiles(selection),
        title: 'Duplicate'
      },
      {
        action: (selection, _, evt) => {
          const selectionMap = selection.map(({ id }) => id);
          let invalidTargetIds = [];
          if (fromProcessApplicationId) {
            invalidTargetIds = [...selectionMap, fromProcessApplicationId];
          } else if (fromIdpApplicationId) {
            invalidTargetIds = [...selectionMap, fromIdpApplicationId];
          } else if (isFolder) {
            invalidTargetIds = [...selectionMap, folder.id];
          } else {
            invalidTargetIds = [...selectionMap, project.id];
          }

          setTargetSelectorParams({
            selection,
            invalidTargetIds,
            moveAnchorEl: evt.target
          });
        },
        isAllowed: (selection) => hasAccess(project, actions.MODIFY_DIAGRAM) && noMainProcessInvolved(selection),
        title: 'Move'
      },
      {
        action: (selection) => projectStore.deleteEntities(selection.map((project) => project.entityAction)),
        isAllowed: (selection) => hasAccess(project, actions.DELETE_DIAGRAM) && noMainProcessInvolved(selection),
        title: 'Delete'
      }
    ],
    targetSelector: targetSelectorParams.moveAnchorEl && (
      <TargetSelector
        align="bottom"
        anchorEl={targetSelectorParams.moveAnchorEl}
        open={Boolean(targetSelectorParams.moveAnchorEl)}
        startingPoint={isFolder ? folder : project}
        invalidTargetIds={targetSelectorParams.invalidTargetIds}
        onSubmit={handleMove}
        selectedEntities={targetSelectorParams.selection.map((selection) => selection.entityAction)}
        handleClickOutside={() =>
          setTargetSelectorParams((params) => ({
            ...params,
            moveAnchorEl: null
          }))
        }
      />
    )
  };
}
