/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { InvitationModal, ShareView } from 'components';
import { Dialog } from 'primitives';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { diagramExtensionStore, currentDiagramStore, invitationModalStore } from 'stores';

import KeyboardShortcuts from './KeyboardShortcuts';

const Extensions = () => {
  const { hideShortcutModal, hideShareModal } = diagramExtensionStore;
  const { isShortcutModalVisible, isShareModalVisible } = diagramExtensionStore.state;
  const { project, diagram } = currentDiagramStore.state;
  const { isDesignMode } = dedicatedModesStore;

  return (
    <div>
      {/* @ts-expect-error TS2739 */}
      <Dialog open={isShortcutModalVisible} onClose={hideShortcutModal}>
        <Dialog.Header>
          <Dialog.Title>Keyboard shortcuts</Dialog.Title>
        </Dialog.Header>

        <Dialog.Content>
          <KeyboardShortcuts diagram={diagram} isDesignMode={isDesignMode} />
        </Dialog.Content>
      </Dialog>
      {invitationModalStore.state.isInvitationModalVisible && (
        <InvitationModal project={project} onClose={invitationModalStore.hide} />
      )}
      {isShareModalVisible && <ShareView diagram={diagram} onClose={hideShareModal} />}
    </div>
  );
};

export default observer(Extensions);
