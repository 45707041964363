/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  top: 1px;
  flex: 1;
  display: flex;
  flex-direction: column;

  // The following overrides are to invalidate the reset rules from Carbon
  // See #4639 for more details
  ol,
  ul {
    margin: 1em 0;
    padding: 0 0 0 40px;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }
`;

export const FormContainer = styled.main`
  flex: 1;
  background-color: white;

  /*
   * Read-only styles
   */
  // Hide palette
  .cfp-palette {
    ${({ $readOnly }) => $readOnly && 'display: none;'}
  }
  // Hide Form Definition and Form Input
  .cfp-left {
    ${({ $readOnly }) => $readOnly && 'display: none;'}
  }
  // Make visible panels non-collapsible
  .cfp-collapsible-panel-actions {
    ${({ $readOnly }) => $readOnly && 'display: none;'}
  }
  .cfp-collapsible-panel-title {
    ${({ $readOnly }) => $readOnly && 'pointer-events: none;'}
  }
  // Read-only styles for the properties panel
  .bio-properties-panel,
  .cds--toggle,
  .cds--toggle__appearance {
    ${({ $readOnly }) => $readOnly && 'cursor: not-allowed !important;'}
  }
  .bio-properties-panel-input,
  .bio-properties-panel-feel-editor-container {
    ${({ $readOnly }) =>
      $readOnly && 'cursor: not-allowed; opacity: 0.75; background-color: var(--color-grey-225-10-97);'}
  }
  .bio-properties-panel-feel-templating,
  .bio-properties-panel-entry,
  .bio-properties-panel-input,
  .bio-properties-panel-feel-popup,
  .bio-properties-panel-toggle-switch,
  .cds--toggle,
  .cds--toggle__appearance {
    ${({ $readOnly }) => $readOnly && 'pointer-events: none !important;'}
  }
  .bio-properties-panel-group-header-button,
  .bio-properties-panel-remove-entry,
  .bio-properties-panel-open-feel-popup,
  .bio-properties-panel-tooltip,
  .cm-tooltip-autocomplete {
    ${({ $readOnly }) => $readOnly && 'display: none !important;'}
  }
  /*
   * End of read-only styles
   */
`;
