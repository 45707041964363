/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef, useState } from 'react';
import { ChevronDown } from '@carbon/react/icons';
import { Button } from '@carbon/react';

import { AISparkle } from 'icons';

import CamundaAiChatbox from './CamundaAiChatbox';
import * as Styled from './CamundaAiButtonWithCopilot.styled';

export default function CamundaAiButtonWithCopilot({ location, modeler, saveDiagramContent, addNewMilestone }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatboxOpen = () => setIsOpen(!isOpen);
  const closeChatbox = () => setIsOpen(false);

  const buttonRef = useRef();

  return (
    <Styled.ChatboxGroup>
      <CamundaAiChatbox
        onClose={closeChatbox}
        buttonRef={buttonRef}
        isOpen={isOpen}
        location={location}
        modeler={modeler}
        saveDiagramContent={saveDiagramContent}
        addNewMilestone={addNewMilestone}
      />
      <Button
        hasIconOnly
        iconDescription="Camunda AI"
        tooltipAlignment="end"
        kind="secondary"
        size="sm"
        ref={buttonRef}
        renderIcon={() => {
          return isOpen ? <ChevronDown /> : <AISparkle />;
        }}
        onClick={toggleChatboxOpen}
      />
    </Styled.ChatboxGroup>
  );
}
