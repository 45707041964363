/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { IconButton } from '@carbon/react';
import { AddLarge, ChevronLeft, ChevronRight, SubtractLarge } from '@carbon/icons-react';
import { useState } from 'react';

import pluralize from 'utils/pluralize';

import * as Styled from './PdfViewerHeader.styled';

const MIN_PDF_DOCUMENT_ZOOM_SCALE = 1;
const MAX_PDF_DOCUMENT_ZOOM_SCALE = 3;
const DOCUMENT_ZOOM_STEP = 0.1;

const PdfViewerHeader = ({
  documents,
  selectedDocument,
  selectedDocumentPageCount,
  onDocumentChange,
  onDocumentPageChange,
  onDocumentScaleChange
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [documentsScale, setDocumentsScale] = useState(1);

  const getSelectedDocumentIndex = () => documents.indexOf(selectedDocument);

  const resetDocument = () => {
    setCurrentPage(1);
    setDocumentsScale(1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      onDocumentPageChange(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < selectedDocumentPageCount) {
      onDocumentPageChange(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousDocument = () => {
    if (getSelectedDocumentIndex() > 0) {
      onDocumentChange(documents[getSelectedDocumentIndex() - 1]);
      resetDocument();
    }
  };

  const goToNextDocument = () => {
    if (getSelectedDocumentIndex() < documents.length - 1) {
      onDocumentChange(documents[getSelectedDocumentIndex() + 1]);
      resetDocument();
    }
  };

  const incrementDocumentScale = () => {
    if (documentsScale < MAX_PDF_DOCUMENT_ZOOM_SCALE) {
      const newScale = parseFloat((documentsScale + DOCUMENT_ZOOM_STEP).toFixed(2));
      onDocumentScaleChange(newScale);
      setDocumentsScale(newScale);
    }
  };

  const decrementDocumentScale = () => {
    if (documentsScale > MIN_PDF_DOCUMENT_ZOOM_SCALE) {
      const newScale = parseFloat((documentsScale - DOCUMENT_ZOOM_STEP).toFixed(2));
      onDocumentScaleChange(newScale);
      setDocumentsScale(newScale);
    }
  };

  return (
    documents?.length > 0 && (
      <Styled.PdfViewerHeaderContainer data-test="pdf-viewer-header-section">
        <Styled.DocumentNavigationContainer>
          <div>
            <IconButton
              size="md"
              kind="ghost"
              label="Go to previous document"
              align="right"
              disabled={getSelectedDocumentIndex() <= 0}
              onClick={goToPreviousDocument}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              size="md"
              kind="ghost"
              label="Go to next document"
              align="bottom"
              disabled={getSelectedDocumentIndex() === documents.length - 1}
              onClick={goToNextDocument}
            >
              <ChevronRight />
            </IconButton>
          </div>
          <Styled.DocumentDropdown
            id="pdf-document"
            data-test="pdf-document"
            titleText=""
            initialSelectedItem={documents[0]}
            label=""
            items={documents}
            selectedItem={selectedDocument}
            onChange={({ selectedItem }) => onDocumentChange(selectedItem)}
            // @ts-expect-error TS2339
            itemToString={(idpDocument) => idpDocument.name}
          />
        </Styled.DocumentNavigationContainer>
        <Styled.DocumentNavigationContainer>
          <span>
            {currentPage} of {selectedDocumentPageCount} {pluralize('page', selectedDocumentPageCount)}
          </span>
          <Styled.PageNavigationContainer>
            <IconButton
              size="md"
              kind="ghost"
              label="Go to previous page"
              align="bottom"
              disabled={currentPage === 1}
              onClick={goToPreviousPage}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              size="md"
              kind="ghost"
              label="Go to next page"
              align="left"
              disabled={currentPage === selectedDocumentPageCount}
              onClick={goToNextPage}
            >
              <ChevronRight />
            </IconButton>
            <IconButton
              size="md"
              kind="ghost"
              label="Zoom in"
              align="bottom"
              disabled={documentsScale === MAX_PDF_DOCUMENT_ZOOM_SCALE}
              onClick={incrementDocumentScale}
            >
              <AddLarge />
            </IconButton>
            <IconButton
              size="md"
              kind="ghost"
              label="Zoom out"
              align="left"
              disabled={documentsScale === MIN_PDF_DOCUMENT_ZOOM_SCALE}
              onClick={decrementDocumentScale}
            >
              <SubtractLarge />
            </IconButton>
          </Styled.PageNavigationContainer>
        </Styled.DocumentNavigationContainer>
      </Styled.PdfViewerHeaderContainer>
    )
  );
};

export default PdfViewerHeader;
