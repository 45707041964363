/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { MenuItemDivider } from '@carbon/react';

export const Dropdown = styled.ul`
  list-style: none;
  margin: ${({ $noMargin }) => ($noMargin ? '0' : '4px 0 0 0')};
  position: fixed;
  z-index: var(--z-dropdown);
  border-radius: var(--spacing-tiny);
  padding: ${({ $noPadding }) => ($noPadding ? '0' : 'var(--spacing-tiny) 0')};
  min-width: 70px;
  max-width: 350px;

  border-radius: var(--spacing-tiny);
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px 0px;
  background: var(--silver-base-98);

  ${({ size }) => {
    if (size == 'small') {
      return `
        font-size: 13px;

        li {
          padding: var(--spacing-tiny) 10px;
        }

        a {
          padding: 0;
        }
      `;
    }
  }}

  ${({ width }) => {
    if (width) {
      return `
        min-width: ${width}px;
      `;
    }
  }}

  hr {
    margin: var(--spacing-tiny) 0;
    height: 1px;
    border: none;
    background-color: var(--silver-darken-87);
  }
`;

export const SubDropdown = styled(Dropdown)`
  position: absolute;
  ${({ $verticalAlignment }) => `${$verticalAlignment}: 100%;`}
  ${({ $horizontalAlignment }) => `${$horizontalAlignment}: -6px;`}
  opacity: 0;
  pointer-events: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${({ $noPadding }) => ($noPadding ? '0' : '7px var(--spacing-medium)')};
  position: relative;
  color: var(--grey-darken-23);
  white-space: nowrap;
  outline: none;
  font-size: 14px;
  transition: all 150ms linear 0s;
  border-radius: 3px;

  ${({ $hasSubDropdown }) => {
    if ($hasSubDropdown) {
      return `
        justify-content: space-between;
        padding-right: 7px;

        svg {
          color: var(--grey-lighten-50);
        }
      `;
    }
  }}

  ${({ isDangerous }) => isDangerous && 'color: var(--red-darken-42);'}

  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return `
        color: var(--grey-lighten-56);
        pointer-events: none;
        `;
    } else {
      return `
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: var(--silver-darken-94);

          ${SubDropdown} {
            opacity: 1;
            pointer-events: all;
          }
        }

        &:active {
          background-color: var(--silver-darken-80);
        }
      `;
    }
  }}

  &::-moz-focus-inner {
    border: 0;
  }

  ${({ isActive, $isHover }) => {
    if (isActive)
      return `
        background-color: var(--silver-darken-94);

        &:hover, &:focus {
          background-color: var(--silver-darken-94);
        }
      `;

    if ($isHover) return 'background-color: var(--silver-darken-94);';
  }}

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const Title = styled.li`
  font-weight: 500 !important;
  padding: ${({ $noPadding }) =>
    $noPadding
      ? '8px var(--spacing-medium) 8px var(--spacing-medium)'
      : '4px var(--spacing-medium) 8px var(--spacing-medium)'};
  color: var(--grey-darken-23);
  font-size: 14px;
  border-bottom: 1px solid var(--silver-darken-87);
`;

const sharedLinkStyles = css`
  display: flex;
  align-items: center;
  padding: 7px var(--spacing-medium);
  color: inherit;
  white-space: nowrap;
  outline: none;
  text-decoration: none;
`;

export const AnchorLink = styled.a`
  ${sharedLinkStyles}
`;

export const ReactRouterLink = styled(Link)`
  ${sharedLinkStyles}
`;

export const ListItemTitle = styled.div`
  color: var(--grey-lighten-56);
  font-size: 11px;
`;

export const ListGroup = styled.div`
  padding: var(--spacing-tiny) 0 var(--spacing-tiny) 0;
  margin: 0 var(--spacing-tiny) 0 var(--spacing-tiny);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:first-child) {
    border-top: solid 1px var(--cm-color-ui-light5);
  }
`;

export const ListGroupTitle = styled.div`
  padding: var(--spacing-tiny) var(--spacing-medium) 0px;
  color: var(--cm-color-ui-light6);
  font-family: var(--cm-font-text);
  font-size: 11px;
`;

export const ListItemDivider = styled(MenuItemDivider)`
  &.cds--menu-item-divider {
    padding: 0;
  }
`;
