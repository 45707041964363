/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import { v4 } from 'uuid';

import { organizationStore } from 'stores';
import { authService, tracingService, trackingService } from 'services';

class BaseUserStore {
  originAppInstanceId = v4();
  isAuthenticated = false;

  userId = undefined;
  userAuthProviderId = undefined;
  userEmail = '';
  userName = '';
  _isSuperUserModeEnabled = false;

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      userId: observable,
      userAuthProviderId: observable,
      userName: observable,
      userEmail: observable,
      _isSuperUserModeEnabled: observable,
      initIsSuperUserModeEnabled: action,
      toggleSuperUserMode: action
    });

    this.initIsSuperUserModeEnabled();
    this.#observeServices();
  }

  #observeServices() {
    autorun(() => {
      if (authService.isReady) {
        this.#onUserReady();
      } else if (authService.hasReset) {
        this.reset();
      }
    });
  }

  initIsSuperUserModeEnabled() {
    this._isSuperUserModeEnabled = JSON.parse(window.localStorage.getItem('modeler.super-user-mode.enabled'));
  }

  toggleSuperUserMode() {
    window.localStorage.setItem('modeler.super-user-mode.enabled', JSON.stringify(!this._isSuperUserModeEnabled));
    this._isSuperUserModeEnabled = !this._isSuperUserModeEnabled;
  }

  reset() {
    runInAction(() => {
      this.isAuthenticated = false;
    });
  }

  get isSuperUserModeActive() {
    return organizationStore.hasElevatedOrganizationPermissions && this._isSuperUserModeEnabled;
  }

  get isLoading() {
    return document.location.href.includes('/login') || document.location.href.includes('/logout');
  }

  /**
   * Compares two user accounts and returns `true` if they
   * are equal or `false` if they are different.
   *
   * @param {Object} user The user to compare with.
   * @returns {Boolean}
   */
  isCurrentUser = (user) => {
    if (!user) {
      return false;
    }

    return this.isAuthenticated && authService.modelerUser?.id === user.id;
  };

  /**
   *
   * @param {*} originAppInstanceId
   */
  isCurrentOriginAppInstanceId(originAppInstanceId) {
    return this.originAppInstanceId === originAppInstanceId;
  }

  /**
   * @returns {Promise<Boolean>}
   */
  async checkRevokedRoles() {
    throw new Error('Not implemented');
  }

  #onUserReady() {
    runInAction(() => {
      this.userId = authService.modelerUser?.id;
      this.userEmail = authService.modelerUser?.email;
      this.userName = authService.modelerUser?.name;
      this.userAuthProviderId = authService.jwtUser?.sub;
    });

    trackingService.registerUser({
      user: authService.jwtUser
    });
    tracingService.registerUser({
      modelerUser: authService.modelerUser
    });

    runInAction(() => {
      this.isAuthenticated = true;
    });
    // @ts-expect-error TS2339
    window.__invitation = undefined;
  }
}

export default BaseUserStore;
