/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';

import { DropTarget, DiagramControls } from 'components';
import { Tooltip } from 'primitives';
import { diagramControlStore, notificationStore } from 'stores';

import { MONACO_LOADER_CONFIG } from './config';
import * as Styled from './CodeEditor.styled';

export const CodeEditor = ({
  onChange,
  onKeyUp,
  onDrop,
  value,
  modified,
  readOnly = true,
  readOnlyRanges = [],
  titles = [],
  schema,
  folding = true,
  wordWrap = 'off',
  minimap = false,
  hideControls,
  suggestionProviders = [],
  hoverProviders = [],
  language = 'json',
  dropAreaText = 'Drop to replace current template',
  dropAreaDescription = 'Previous template will be saved as a version'
}) => {
  // For non-readonly editors, we only pass the initial value to monaco (similar to an uncontrolled component).
  // Otherwise, if the user types faster than react+mobx can deal with the async state updates in the onChange handler,
  // the user might lose data they typed or experience cursor jumps.
  const initialValue = useRef(value);
  const [editor, setEditor] = useState();

  const monaco = useMonaco();

  const { fontSize } = diagramControlStore.state;
  const [leftTitle, rightTitle] = titles;
  const options = {
    minimap: {
      enabled: minimap
    },
    folding,
    wordWrap,
    fontSize,
    lineHeight: 20,
    fontFamily: '"IBM Plex Mono", "Droid Sans Mono", "monospace", monospace, "Droid Sans Fallback"',
    readOnly,
    formatOnPaste: true
  };

  loader.config(MONACO_LOADER_CONFIG);

  // setup json schema validation
  useEffect(() => {
    if (language !== 'json') {
      document.fonts.ready.then(() => {
        monaco?.editor.remeasureFonts();
      });

      return;
    }

    if (schema && value) {
      try {
        monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
          // validate JSON syntax
          validate: true,
          schemaValidation: 'error',
          schemas: [
            {
              uri: JSON.parse(value).$schema,
              fileMatch: ['*'],
              schema
            }
          ]
        });
      } catch {
        // json parsing of value could fail
      }
    } else {
      monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
        schemaValidation: 'ignore',
        schemaRequest: 'ignore'
      });
    }

    document.fonts.ready.then(() => {
      monaco?.editor.remeasureFonts();
    });
  }, [monaco, schema, value]);

  useEffect(() => {
    if (!monaco || language !== 'json') {
      return;
    }

    const completionDisposables = suggestionProviders.map((provider) =>
      monaco.languages.registerCompletionItemProvider('json', provider)
    );
    const hoverDisposables = hoverProviders.map((provider) => monaco.languages.registerHoverProvider('json', provider));

    return () => {
      completionDisposables.forEach((disposable) => disposable.dispose());
      hoverDisposables.forEach((disposable) => disposable.dispose());
    };
  }, [monaco, suggestionProviders, hoverProviders]);

  // styling for read-only lines
  const decorations = useRef([]);
  function applyReadOnlyLinesHighlighting() {
    // @ts-expect-error TS18048
    decorations.current = editor?.deltaDecorations(
      decorations.current || [],
      readOnlyRanges.map((range) => ({
        range: {
          ...range,
          endLineNumber: range.endLineNumber - 1
        },
        options: {
          isWholeLine: true,
          className: 'readOnlyLine',
          marginClassName: 'readOnlyLine'
        }
      }))
    );
  }
  useEffect(() => {
    applyReadOnlyLinesHighlighting();
  }, [editor, readOnlyRanges]);

  // flag indicating whether we are currently rolling back a change
  // this is set to true when the user tries to edit a read-only area
  const processingRollback = useRef(false);

  // last valid and propagated value, used when rolling back a change
  const lastValidValue = useRef(value);

  useEffect(() => {
    if (editor) {
      // @ts-expect-error TS2339
      const handler = editor.onDidChangeModelContent(async (evt) => {
        // If the change occured during a rollback, do not process it.
        // This prevents infinite loops.
        if (processingRollback.current) return;

        // if the user tried to edit a read-only area of the file
        if (evt.changes.some((change) => readOnlyRanges.some((range) => change.range.intersectRanges(range)))) {
          // roll back the change
          processingRollback.current = true;
          await undoCurrentOperation(editor);
          applyReadOnlyLinesHighlighting();
          processingRollback.current = false;

          // show a hint that the user cannot edit the section
          if (!notificationStore.isNotificationVisible) {
            notificationStore.showNotification({
              message: 'You cannot edit this section.'
            });
          }
        } else {
          // Change in non-restricted area, propagate change via onChange handler
          // and store the current state as valid (for potential future rollbacks)
          // @ts-expect-error TS2339
          const newValue = editor.getValue();
          lastValidValue.current = newValue;
          onChange?.(newValue);
        }
      });

      return handler.dispose;
    }
  }, [editor, onChange, readOnlyRanges]);

  return (
    // @ts-expect-error TS2322
    <DropTarget isDisabled={readOnly || !onDrop} onDrop={onDrop} text={dropAreaText} description={dropAreaDescription}>
      <Styled.EditorWrapper onKeyUp={onKeyUp} data-test={`${language}-editor`}>
        {titles.length > 0 && (
          <Styled.Header>
            {leftTitle && (
              <Tooltip title={leftTitle} showOnlyOnOverflow>
                <Styled.HeaderTitle data-test="left-editor-title" className="overflow-ellipsis">
                  {leftTitle}
                </Styled.HeaderTitle>
              </Tooltip>
            )}
            <Tooltip title={rightTitle} showOnlyOnOverflow>
              <Styled.HeaderTitle data-test="right-editor-title" className="overflow-ellipsis">
                {rightTitle}
              </Styled.HeaderTitle>
            </Tooltip>
          </Styled.Header>
        )}
        <Styled.EditorContainer>
          {modified ? (
            // @ts-expect-error TS2322
            <DiffEditor original={value} modified={modified} language={language} options={options} />
          ) : (
            <Editor
              // @ts-expect-error TS2322
              options={options}
              language={language}
              value={readOnly ? value : initialValue.current}
              // @ts-expect-error TS2345
              onMount={(editor) => setEditor(editor)}
            />
          )}
        </Styled.EditorContainer>
        {!hideControls && (
          <Styled.BottomRight>
            <DiagramControls />
          </Styled.BottomRight>
        )}
      </Styled.EditorWrapper>
    </DropTarget>
  );
};

CodeEditor.propTypes = {
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onDrop: PropTypes.func,
  value: PropTypes.string.isRequired,
  modified: PropTypes.string,
  readOnly: PropTypes.bool,
  titles: PropTypes.array,
  schema: PropTypes.object
};

export default observer(CodeEditor);

// helpers /////////////////////////////

async function undoCurrentOperation(editor) {
  // We want the current operation to finish before we roll it back
  // ==> immediately resolve a promise and schedule the undo operation after
  return Promise.resolve().then(() => {
    editor.trigger('readonly', 'undo');
  });
}
