/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

/**
 * Custom hook to filter and order available and unavailable clusters based on the process application stages cluster IDs.
 *
 * @param {Object} params - The input parameters.
 * @param {Object[]} [params.availableClusters] - The list of available clusters.
 * @param {Object[]} [params.availableDevClusters] - The list of available dev clusters.
 * @param {Object[]} [params.unavailableClusters] - The list of unavailable clusters.
 * @param {{
 *    defaultDevClusterId: string,
 *    defaultTestClusterId: string,
 *    defaultStageClusterId: string,
 *    defaultProdClusterId: string
 * }}  [params.processApplication] - The process application object containing default stage cluster IDs.
 *
 * @returns {{availableClusters: Object[], availableDevClusters: Object[], unavailableClusters: Object[]}} - An object containing the filtered and ordered available and unavailable clusters.
 */
const useStagesClusters = ({ availableClusters, availableDevClusters, unavailableClusters, processApplication }) => {
  const { defaultDevClusterId, defaultTestClusterId, defaultStageClusterId, defaultProdClusterId } = processApplication;

  // Define the stages in the order we want them
  const stagesOrder = [
    { key: 'dev', id: defaultDevClusterId },
    { key: 'test', id: defaultTestClusterId },
    { key: 'stage', id: defaultStageClusterId },
    { key: 'prod', id: defaultProdClusterId }
  ];

  const filterAndOrderClusters = (clusters) => {
    return stagesOrder.map((stage) => clusters.find((cluster) => cluster.uuid === stage.id)).filter(Boolean);
  };

  const orderedAvailableClusters = filterAndOrderClusters(availableClusters);
  const orderedUnavailableClusters = filterAndOrderClusters(unavailableClusters);
  const orderedAvailableDevClusters = filterAndOrderClusters(availableDevClusters);

  return {
    availableClusters: orderedAvailableClusters,
    availableDevClusters: orderedAvailableDevClusters,
    unavailableClusters: orderedUnavailableClusters
  };
};

export default useStagesClusters;
