/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

export const NonModalDialog = styled.div`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};

  ${({ $position }) =>
    $position
      ? // Position next to anchor element
        `
        position: absolute;
        top: ${$position.top}px;
        left: ${$position.left}px;
      `
      : // Position at bottom - center
        `
        position: fixed;
        right: 50%;
        bottom: 1rem;
        transform: translateX(50%);
      `}

  max-width: ${({ $maxWidth }) => $maxWidth};
  z-index: var(--z-dialog);
  background-color: var(--cm-color-white-base);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`;

export const Header = styled.div``;

export const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Body = styled.div`
  padding: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 48px;
`;

export const FooterLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 36px;
  margin-right: 20px;
`;
