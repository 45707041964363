/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import minimapModule from 'diagram-js-minimap';
import ExecutionPlatformModule from '@camunda/execution-platform';
import { DmnPropertiesPanelModule, DmnPropertiesProviderModule } from 'dmn-js-properties-panel';
import { DmnImprovedCanvasModule } from '@camunda/improved-canvas';

import { attentionGrabberStore, currentDiagramStore, diagramControlStore, realtimeCollaborationStore } from 'stores';
import { deploymentErrorsStore } from 'App/Pages/Diagram/stores';
import { Modeler } from 'components';
import { callingProcessesExtension } from 'App/Pages/Diagram/CallingProcesses';
import { businessRuleTaskLinkStore } from 'App/Pages/Diagram/BusinessRuleTaskLinking';
import { XMLEditor, XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';
import { ResizablePanel } from 'primitives';
import hasAccess, { actions } from 'utils/user-access';

import clipboardExtension from './BpmnJSExtensions/clipboardExtension';
import attentionGrabberExtension from './BpmnJSExtensions/attentionGrabberExtension';
import undoRedoButtonsExtension from './features/undoRedo';
import DetailsPanel from './DetailsPanel/DetailsPanel';
import DiagramErrorPanel from './ErrorPanel';
import CanvasBottomRight from './CanvasBottomRight';
import * as Styled from './Diagram.styled';

export const DmnViewer = ({ permission }) => {
  const [isSidebarAvailable, setIsSidebarAvailable] = useState(true);

  const { diagram, project } = currentDiagramStore.state;

  const diagramId = diagram?.id;

  // initialize the attentionGrabberStore with the current diagram
  useEffect(() => {
    if (diagramId) {
      attentionGrabberStore.currentDiagramId = diagramId;
      attentionGrabberStore.isInProjectContext = true;

      return () => {
        attentionGrabberStore.reset();
      };
    }
  }, [diagramId]);

  const getModelerPlugins = () => {
    const additionalPlugins = {
      drd: [minimapModule, callingProcessesExtension, undoRedoButtonsExtension],
      decisionTable: [clipboardExtension],
      literalExpression: []
    };

    if (hasAccess(project, actions.MODIFY_DIAGRAM)) {
      additionalPlugins.drd.push(DmnImprovedCanvasModule);
    }

    if (permission.is(['WRITE', 'ADMIN', 'COMMENT'])) {
      additionalPlugins.drd.push(attentionGrabberExtension);
    }

    if (permission.is(['WRITE', 'ADMIN'])) {
      additionalPlugins.drd.push(ExecutionPlatformModule);
      additionalPlugins.decisionTable.push(ExecutionPlatformModule);
      additionalPlugins.literalExpression.push(ExecutionPlatformModule);
    }

    if (permission.is(['COMMENT'])) {
      additionalPlugins.drd.push(DmnPropertiesPanelModule);
      additionalPlugins.drd.push(DmnPropertiesProviderModule);
    }

    return additionalPlugins;
  };

  const handleChange = () => {
    if (!XMLEditorStore.isEditorOpen) {
      // prevents double save, when the XML editor is open
      currentDiagramStore.debouncedSaveContent();
    }
  };

  const handleModelerLoaded = (modeler) => {
    currentDiagramStore.setModeler(modeler);
    diagramControlStore.setModeler(modeler);
    attentionGrabberStore.setModeler(modeler);
    realtimeCollaborationStore.init({ permission });
    businessRuleTaskLinkStore.handleNavigationFromBusinessRuleTaskLink(modeler);
  };

  const handleModelerInit = () => {
    currentDiagramStore.trackDiagramView('direct');
  };

  const handleViewChange = (view) => {
    if (view === 'drd') {
      setIsSidebarAvailable(true);
    } else {
      setIsSidebarAvailable(false);
    }
  };

  const isViewOnly = permission.is(['COMMENT', 'READ']);
  const isReadOnly = permission.is(['READ']);
  const shouldRenderXMLEditor = permission.is(['WRITE', 'ADMIN']) && XMLEditorStore.isEditorOpen;

  return (
    <>
      {/* @ts-expect-error TS2769 */}
      <Styled.Wrapper $isDmn $isViewOnly={isViewOnly}>
        <Modeler
          diagram={diagram}
          initialContent={diagram.content}
          onChanged={handleChange}
          onModelerLoaded={handleModelerLoaded}
          onModelerInit={handleModelerInit}
          onViewChange={handleViewChange}
          bottomRight={<CanvasBottomRight permission={permission} location="dmnCanvas" />}
          additionalModules={getModelerPlugins()}
          isViewOnly={isViewOnly}
        />
        {/* @ts-expect-error TS2741 */}
        {!isReadOnly && isSidebarAvailable && <DetailsPanel permission={permission} />}
      </Styled.Wrapper>

      {!isViewOnly && deploymentErrorsStore.deploymentErrors && (
        <ResizablePanel
          panelKey="error-panel"
          handleAriaLabel="Resize error panel"
          open={!currentDiagramStore.isErrorPanelCollapsed}
          background="white"
          position="bottom"
          minSize={235}
          maxSize={560}
          sizeClosed={35}
          onOpenChange={(state) => currentDiagramStore.setIsErrorPanelCollapsed(state)}
        >
          {/* @ts-expect-error TS2741 */}
          <DiagramErrorPanel deployErrors={deploymentErrorsStore.deploymentErrors} />
        </ResizablePanel>
      )}

      {/* @ts-expect-error TS2741 */}
      {shouldRenderXMLEditor && <XMLEditor />}
    </>
  );
};

export default observer(DmnViewer);
