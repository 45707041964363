/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { FileUploaderButton, ListItem, Tab, TabList, TabPanels, Tabs, UnorderedList } from '@carbon/react';

import * as Shared from 'App/Pages/Project/Shared.styled';
import { idpApplicationStore, idpProjectStore } from 'stores';
import { EmptyState, Spinner } from 'primitives';
import { IdpProjectDocumentUpload, SadFace } from 'icons';
import { IDP_PROJECT_EXTRACTION_METHODS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';
import { EntityTable } from 'components';
import history from 'utils/history';

import IdpExtractionFields from './IdpExtractionFields';
import * as Styled from './IdpProject.styled';
import IdpProjectHeader from './IdpProjectHeader';
import { ClusterNotification } from './cluster-notification';
import IdpDocuments from './IdpDocuments';

const EXTRACT_DATA_TAB_INDEX = 1;
const ALLOWED_IDP_DOCUMENT_FILE_TYPES = ['.pdf'];
const TABS = ['upload', 'extract', 'evaluate', 'publish'];

const IdpProjectPage = () => {
  const [loading, setLoading] = useState(true);

  // @ts-expect-error TS2339
  const { slug, tab } = useParams();
  const { idpProject, idpDocuments, uploadIdpDocuments, deleteIdpDocument } = idpProjectStore;
  const { idpApplication } = idpApplicationStore;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [clusterStatus, setClusterStatus] = useState(null);

  const reset = () => {
    idpProjectStore.reset();
  };

  useEffect(() => {
    const init = async () => {
      await idpProjectStore.init(slug.split('--')[0]);
      setLoading(false);
    };

    init();
    if (isNoTabSelected()) {
      history.push(`/idp-projects/${slug}/${TABS[0]}`);
    }

    return () => reset();
  }, [slug]);

  useEffect(() => {
    if (!isNoTabSelected()) {
      setSelectedTabIndex(TABS.indexOf(tab));
    }
  }, [tab]);

  const isNoTabSelected = () => !tab || TABS.indexOf(tab) === -1;

  const IdpProjectNotFound = () => {
    return (
      // @ts-expect-error TS2739
      <EmptyState
        title="IDP project not found!"
        description="Sorry, your IDP project might have been deleted."
        icon={<SadFace width="48" height="48" />}
      />
    );
  };

  const TitleContainer = () => {
    return (
      <Styled.TitleContainer>
        <Shared.Title data-test="idp-project-name">{idpProject.name}</Shared.Title>
        <Styled.Subtitle data-test="idp-project-extractionMethod">
          {getIdpProjectExtractionMethod(idpProject.extractionMethod)}
        </Styled.Subtitle>
      </Styled.TitleContainer>
    );
  };

  const UploadButton = ({ onDocumentsUploadProgress }) => {
    return (
      <FileUploaderButton
        name="idp-documents"
        multiple
        disableLabelChanges
        labelText="Upload documents"
        size="lg"
        accept={ALLOWED_IDP_DOCUMENT_FILE_TYPES}
        onChange={(event) => uploadIdpDocuments(event.target.files, onDocumentsUploadProgress)}
      />
    );
  };

  const getIdpProjectExtractionMethod = (value) => {
    return IDP_PROJECT_EXTRACTION_METHODS.find((method) => method.value === value)?.title;
  };

  const extractIdpDocument = async (document) => {
    await idpProjectStore.fetchIdpExtractionFields(document.id);
    idpProjectStore.setActiveExtractionIdpDocument(document);
    setSelectedTabIndex(EXTRACT_DATA_TAB_INDEX);
  };

  const NoIdpDocumentsInIdpProject = () => {
    return (
      <EntityTable
        // @ts-expect-error TS2741
        emptyState={
          // @ts-expect-error TS2741
          <EmptyState
            icon={<IdpProjectDocumentUpload width="96" height="96" />}
            title="Upload Sample Documents"
            description={
              <Styled.EmptyStateDescriptionContainer>
                <span>
                  Sample documents help the system learn your document's structure, identify data patterns, and ensure
                  accurate extraction tailored to your needs.
                </span>
                <div>
                  <span>
                    To optimize extraction performance, upload 3-5 representative sample documents. Make sure they meet
                    the following criteria:
                  </span>
                  <UnorderedList>
                    <ListItem>
                      <b>Format: </b>
                      <span>PDF (not password-protected).</span>
                    </ListItem>
                    <ListItem>
                      <b>Content: </b>
                      <span>
                        Include the data fields you need to extract and cover all required fields in your predefined
                        structure.
                      </span>
                    </ListItem>
                    <ListItem>
                      <b>Size: </b>
                      <span>File must be within the size limit (4MB).</span>
                    </ListItem>
                  </UnorderedList>
                </div>
                <span>After uploading, go to the 'Extract fields tab'.</span>
              </Styled.EmptyStateDescriptionContainer>
            }
            // @ts-expect-error TS2741
            action={<UploadButton />}
          />
        }
        columns={[]}
        rows={[]}
        title={<TitleContainer />}
      />
    );
  };

  return (
    <>
      <IdpProjectHeader />
      {loading ? (
        <Spinner fullHeight />
      ) : idpProject ? (
        idpDocuments.length === 0 ? (
          <NoIdpDocumentsInIdpProject />
        ) : (
          <Styled.Container>
            <TitleContainer />
            <Styled.TabsContainer>
              <Tabs
                selectedIndex={selectedTabIndex}
                onChange={({ selectedIndex }) => history.push(`/idp-projects/${slug}/${TABS[selectedIndex]}`)}
              >
                <TabList aria-label="IDP project tabs" data-test="idp-project-tabs">
                  <Tab role="tab">Upload documents</Tab>
                  <Tab role="tab">Extract data</Tab>
                  <Tab role="tab">Evaluate extraction</Tab>
                  <Tab role="tab">Publish</Tab>
                </TabList>
                <TabPanels>
                  <Styled.TabPanel data-test="upload-documents-tab-panel">
                    <IdpDocuments
                      idpDocuments={idpDocuments}
                      onDocumentDelete={deleteIdpDocument}
                      onDocumentExtract={extractIdpDocument}
                      renderUploadButton={UploadButton}
                    />
                  </Styled.TabPanel>
                  <Styled.TabPanel data-test="extract-data-tab-panel">
                    {selectedTabIndex === 1 && (
                      <>
                        {idpApplication?.clusterId && (
                          <ClusterNotification
                            clusterId={idpApplication.clusterId}
                            onClusterStatusChange={setClusterStatus}
                          />
                        )}
                        <IdpExtractionFields clusterStatus={clusterStatus} />
                      </>
                    )}
                  </Styled.TabPanel>
                  <Styled.TabPanel data-test="evaluate-extraction-tab-panel">
                    {idpApplication?.clusterId && (
                      <ClusterNotification
                        clusterId={idpApplication.clusterId}
                        onClusterStatusChange={setClusterStatus}
                      />
                    )}
                  </Styled.TabPanel>
                  <Styled.TabPanel data-test="publish-tab-panel" />
                </TabPanels>
              </Tabs>
            </Styled.TabsContainer>
          </Styled.Container>
        )
      ) : (
        <IdpProjectNotFound />
      )}
    </>
  );
};

export default observer(IdpProjectPage);
