/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { ErrorPanel } from 'components';
import { currentDiagramStore } from 'stores';
import { Arrow } from 'icons';
import ErrorIcon from 'components/ErrorPanel/ErrorIcon';

import * as Styled from './XMLEditorErrorsPanel.styled';

function XMLEditorErrorsPanel() {
  const { isErrorPanelCollapsed, validationProblems } = currentDiagramStore;

  return (
    <Styled.Container role="tablist" aria-expanded={!isErrorPanelCollapsed} data-test="xml-errors-panel">
      <ErrorPanel.Title
        onClick={(e) =>
          e.target === e.currentTarget && currentDiagramStore.setIsErrorPanelCollapsed(!isErrorPanelCollapsed)
        }
        data-test="xml-errors-panel-title"
      >
        {/* @ts-expect-error TS2769 */}
        <ErrorPanel.Tab role="tab" aria-selected $active $isErrorPanelCollapsed={isErrorPanelCollapsed}>
          <ErrorPanel.StatusText>Output</ErrorPanel.StatusText>
        </ErrorPanel.Tab>

        <ErrorPanel.MenuRight>
          <ErrorPanel.Collapse
            // @ts-expect-error TS2769
            $isCollapsed={isErrorPanelCollapsed}
            onClick={() => currentDiagramStore.setIsErrorPanelCollapsed(!isErrorPanelCollapsed)}
            data-test="error-panel-collapse"
          >
            <Arrow width="12" />
          </ErrorPanel.Collapse>
        </ErrorPanel.MenuRight>
      </ErrorPanel.Title>

      <ErrorPanel.Errors>
        <ErrorPanel.XmlErrors>
          {validationProblems.errors?.map((error, index) => (
            // @ts-expect-error TS2769
            // eslint-disable-next-line react/no-array-index-key
            <Styled.ErrorPanelItem type="error" key={`error-${index}`}>
              <ErrorIcon category="error" /> {typeof error === 'string' ? error : error.message}
            </Styled.ErrorPanelItem>
          ))}

          {validationProblems.warnings?.map((warning, index) => (
            // @ts-expect-error TS2769
            // eslint-disable-next-line react/no-array-index-key
            <Styled.ErrorPanelItem type="warning" key={`warning-${index}`}>
              <ErrorIcon category="warn" /> {typeof warning === 'string' ? warning : warning.message}
            </Styled.ErrorPanelItem>
          ))}
        </ErrorPanel.XmlErrors>
      </ErrorPanel.Errors>
    </Styled.Container>
  );
}

export default observer(XMLEditorErrorsPanel);
