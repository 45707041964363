/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable, autorun, runInAction } from 'mobx';

import { inboundConnectorStore, publicationStore } from 'App/Pages/Diagram/stores';

class DetailsPanelTabsStore {
  selectedTabIndex = 0;

  TABS = {
    PROPERTIES: 0,
    COMMENTS: 1,
    INBOUND: 2,
    PUBLICATION: 3
  };

  availableTabs = [];

  #listener = null;

  constructor() {
    makeAutoObservable(this, { TABS: false });
  }

  init() {
    this.#listenForChangesAndComputeVisibleTabs();
  }

  reset() {
    this.selectedTabIndex = 0;
    this.setAvailableTabs([]);

    if (this.#listener) {
      this.#listener();
    }
  }

  #listenForChangesAndComputeVisibleTabs() {
    const defaultTabs = [this.TABS.PROPERTIES, this.TABS.COMMENTS];

    this.setAvailableTabs([...defaultTabs]);

    this.#listener = autorun(() => {
      const newTabs = [...defaultTabs];

      const { isSelectedElementInboundConnector } = inboundConnectorStore;
      const { isSelectedElementStartEvent } = publicationStore;

      if (isSelectedElementInboundConnector) {
        newTabs.push(this.TABS.INBOUND);
      }

      if (isSelectedElementStartEvent) {
        newTabs.push(this.TABS.PUBLICATION);
      }

      this.setAvailableTabs(newTabs, {
        isSelectedElementInboundConnector,
        isSelectedElementStartEvent
      });
    });
  }

  // @ts-expect-error TS2525
  setAvailableTabs(tabs, { isSelectedElementInboundConnector, isSelectedElementStartEvent } = {}) {
    runInAction(() => {
      this.#checkIfShouldFallbackToPropertiesTab({
        isSelectedElementInboundConnector,
        isSelectedElementStartEvent
      });

      this.availableTabs = tabs;
    });
  }

  #checkIfShouldFallbackToPropertiesTab({ isSelectedElementInboundConnector, isSelectedElementStartEvent }) {
    if (
      (this.isInboundConnectorTabSelected && !isSelectedElementInboundConnector) ||
      (this.isPublicationTabSelected && !isSelectedElementStartEvent)
    ) {
      this.switchTab(this.TABS.PROPERTIES);
    }
  }

  switchTab(index) {
    this.selectedTabIndex = index;
  }

  get isCommentsTabSelected() {
    return this.availableTabs[this.selectedTabIndex] === this.TABS.COMMENTS;
  }

  get isPropertiesTabSelected() {
    return this.availableTabs[this.selectedTabIndex] === this.TABS.PROPERTIES;
  }

  get isInboundConnectorTabSelected() {
    return this.availableTabs[this.selectedTabIndex] === this.TABS.INBOUND;
  }

  get isPublicationTabSelected() {
    return this.availableTabs[this.selectedTabIndex] === this.TABS.PUBLICATION;
  }
}

export default new DetailsPanelTabsStore();
