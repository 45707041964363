/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';
import { TileGroup } from '@carbon/react';
import { WarningAltFilled } from '@carbon/icons-react';

export const TilesContainer = styled(TileGroup)`
  & > div {
    display: flex;
    gap: 8px;
  }

  margin-bottom: 8px;
`;

export const OptionDescription = styled.p`
  margin-top: 16px;
  font-size: 14px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: ${({ hasDisclaimer }) => (hasDisclaimer ? 'space-between' : 'flex-end')};
`;

export const Disclaimer = styled.div`
  padding-right: 16px;
  font-size: 14px;
`;

export const CautionMessage = styled.span`
  font-size: 12px;
  vertical-align: top;
`;

export const YellowWarningIcon = styled(WarningAltFilled)`
  fill: var(--cds-support-warning, #f1c21b);
  [data-icon-path='inner-path'] {
    fill: black;
    opacity: 1;
  }
`;
