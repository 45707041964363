/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Button, Dialog } from 'primitives';
import { PermissionSelector, MultiSelectInput } from 'components';
import { invitationModalStore, projectStore, organizationStore } from 'stores';
import { MAX_INPUT_EMAILS } from 'utils/constants';

import * as Styled from './InvitationModal.styled';

const MAX_MESSAGE_LENGTH = 500;

export class InvitationModal extends Component {
  static propTypes = {
    onClose: PropTypes.func
  };

  componentDidMount() {
    const { project, collaborators } = projectStore;

    invitationModalStore.setProject(project, collaborators);
    invitationModalStore.fetchInviteSuggestions();
  }

  componentWillUnmount() {
    invitationModalStore.reset();
  }

  handleClick = async (currentOrganizationId, externalInvitees) => {
    await invitationModalStore.handleInviteClick(currentOrganizationId, externalInvitees);
    await projectStore.fetchCollaboratorData(projectStore.project.id);
  };

  render() {
    const { project } = projectStore;

    const { permission, message, invitees, suggestions, isSendingInvitations } = invitationModalStore.state;

    const { handleMessageChange, handlePermissionChange, messageLength, buttonDisabled, buttonText, validate } =
      invitationModalStore;
    const { currentOrganizationInfo, currentOrganizationId, organizationManagementPageUrl } = organizationStore;

    const canAddAnyUser = organizationStore.hasGeneralUserCreatePermission;

    const externalInvitees = invitees.filter((email) => !suggestions.find((suggestion) => suggestion.email === email));

    return (
      // @ts-expect-error TS2739
      <Dialog open={invitationModalStore.state.isInvitationModalVisible} onClose={invitationModalStore.hide}>
        <Dialog.Header>
          <Dialog.Title data-test="invitation-modal-title">Invite collaborators to "{project.name}"</Dialog.Title>
          <Dialog.Subtitle>Invited users will gain access to all files in the project.</Dialog.Subtitle>
        </Dialog.Header>

        <Dialog.Content>
          <MultiSelectInput
            placeholder={`Write or paste up to ${MAX_INPUT_EMAILS} email addresses and press Enter or Tab`}
            onValidate={(email) => validate(email, { validateOrganizationInvitee: !canAddAnyUser })}
            dataSource={invitees}
            suggestions={suggestions}
            maxLength={MAX_INPUT_EMAILS}
            action={<PermissionSelector value={permission} onChange={handlePermissionChange} />}
            organizationMembersOnly={!canAddAnyUser}
          />

          <Styled.Textarea
            // @ts-expect-error TS2769
            placeholder={`You have been invited to ${project.name}...`}
            multiline
            rows={5}
            value={message}
            onChange={handleMessageChange}
            data-test="invitation-message"
          />

          <Styled.HelperText data-test="character-count">
            {messageLength > MAX_MESSAGE_LENGTH
              ? 'Too many characters.'
              : `${MAX_MESSAGE_LENGTH - messageLength} characters left.`}
          </Styled.HelperText>

          {externalInvitees?.length > 0 && (
            <Styled.ExternalUserWarning>
              The following {externalInvitees.length > 1 ? 'users' : 'user'} will also be invited to the{' '}
              <strong>{currentOrganizationInfo.name}</strong> organization with <strong>read/write</strong> access to
              all applications:{' '}
              {externalInvitees.map((email, index) => (
                <span key={email}>
                  <strong>{email}</strong>
                  {index < externalInvitees.length - 1 && ', '}
                </span>
              ))}
              .
              <br />
              You can modify their permissions later through{' '}
              <a href={`${organizationManagementPageUrl}/users`} target="_blank" rel="noreferrer">
                Cloud User Management.
              </a>
            </Styled.ExternalUserWarning>
          )}
        </Dialog.Content>

        <Dialog.Footer>
          {/* @ts-expect-error TS2322 */}
          <Button
            disabled={buttonDisabled}
            onClick={() => this.handleClick(currentOrganizationId, externalInvitees)}
            variant="primary"
            data-test="invite-email-button"
          >
            {/* @ts-expect-error TS2769 */}
            {isSendingInvitations && <Styled.LoadingAnimation size="small" $light />}
            {buttonText}
          </Button>
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default observer(InvitationModal);
