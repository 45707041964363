/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { Button, Dialog } from 'primitives';

import mainProcessRedirectModalStore from './MainProcessRedirectModalStore';
import * as Styled from './MainProcessRedirectModal.styled';

function MainProcessRedirectModal({ onRedirect }) {
  const { actionType, isVisible, close } = mainProcessRedirectModalStore;
  const deployOnly = actionType === 'deploy';

  return (
    // @ts-expect-error TS2769
    <Styled.Wrapper open={isVisible} onClose={close}>
      <Dialog.Header>
        <Dialog.Title>{deployOnly ? 'Deploy process application' : 'Start instance'}</Dialog.Title>
      </Dialog.Header>
      <Styled.Content>
        <p>This resource is part of a process application.</p>
        <p>
          {deployOnly
            ? 'Deploy all resources of this process application from the main process page.'
            : 'Run the main process to complete the end-to-end journey including this resource, if it is linked properly.'}
        </p>
      </Styled.Content>
      <Dialog.Footer>
        {/* @ts-expect-error TS2322 */}
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        {/* @ts-expect-error TS2322 */}
        <Button variant="primary" onClick={onRedirect}>
          Go to main process
        </Button>
      </Dialog.Footer>
    </Styled.Wrapper>
  );
}

export default observer(MainProcessRedirectModal);
