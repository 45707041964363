/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { Button } from 'primitives';
import { homeStore } from 'stores';

const ActionButton = ({ variant, tabIndex }) => {
  const { isCreatingProject } = homeStore;

  return (
    // @ts-expect-error TS2322
    <Button
      onClick={() => homeStore.createProject()}
      data-test="create-project"
      variant={variant}
      loading={isCreatingProject}
      loaderSize="small"
      lightLoader
      tabIndex={tabIndex}
    >
      New project
    </Button>
  );
};

export default observer(ActionButton);
