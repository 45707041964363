/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';

import { getCalledElementProcessId } from 'utils/web-modeler-diagram-parser';

const getCallActivityLinksFromElements = (elements) => {
  let callActivities = [];
  elements?.forEach((element) => {
    if (element.flowElements) {
      callActivities = callActivities.concat(getCallActivityLinksFromElements(element.flowElements));
    } else {
      if (is(element, 'bpmn:CallActivity')) {
        const extensionElements = element?.get('extensionElements');
        const processId = getCalledElementProcessId(extensionElements);
        if (processId) {
          callActivities.push(processId);
        }
      }
    }
  });
  return callActivities;
};

export const getCallActivityLinks = (definitions) => {
  if (!definitions) {
    throw new Error('You need to pass modeler definitions to get the call activity links of the process');
  }

  const { rootElements } = definitions;
  const callActivityLinks = getCallActivityLinksFromElements(rootElements);

  if (callActivityLinks.length === 0) {
    return;
  }
  return callActivityLinks;
};
