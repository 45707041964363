/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { idpProjectService } from 'services';

import { incidentHandlingLogic, incidentHandlingLayout } from './bpmn-snippets';

export default async function runInstanceWithResult(
  idpProjectId,
  clusterId,
  bpmnXml,
  timeoutSeconds = 10,
  variables = {}
) {
  // add incident handling logic to bpmnXML
  const augmentedXML = addIncidentHandlingLogic(bpmnXml, timeoutSeconds);

  const deploymentResponse = await idpProjectService.deployProcess(idpProjectId, clusterId, augmentedXML);

  if (Array.isArray(variables)) {
    // run multiple instances in parallel
    return Promise.allSettled(
      variables.map((variables) =>
        idpProjectService.startInstance(idpProjectId, {
          clusterId,
          variables,
          timeoutSeconds: timeoutSeconds + 1,
          ...deploymentResponse
        })
      )
    );
  } else {
    // single instance run
    return await idpProjectService.startInstance(idpProjectId, {
      clusterId,
      variables,
      timeoutSeconds: timeoutSeconds + 1,
      ...deploymentResponse
    });
  }
}

function addIncidentHandlingLogic(bpmnXml, timeout) {
  // get the heights of all existing elements in the diagram. We need this information to position the additional elements correctly
  const parsedHeights = bpmnXml.matchAll(/<dc:Bounds x="[^"]+" y="([^"]+)".+height="([^"]+)"/gm);

  // find the bottom edge of the diagram (which is the maximum y position + height)
  let maxHeight = -Infinity;
  for (const match of parsedHeights) {
    maxHeight = Math.max(maxHeight, +match[1] + +match[2]);
  }

  // in the additional incident handling elements, move all elements down according to the calculated maxHeight
  // @ts-expect-error TS2550
  const layout = incidentHandlingLayout.replaceAll(/y="([^"]+)"/gm, (match) => {
    return `y="${maxHeight + +match.slice(3, 6)}"`;
  });

  // add the semantic incident handling elements (replace the timeout placeholder with the timeout provided by the function)
  const logicIndex = bpmnXml.lastIndexOf('</bpmn:process>');
  let newXml =
    bpmnXml.slice(0, logicIndex) + incidentHandlingLogic.replace('{{timeout}}', timeout) + bpmnXml.slice(logicIndex);

  // add the layout for the new incident handling elements
  const layoutIndex = newXml.lastIndexOf('</bpmndi:BPMNPlane>');
  newXml = newXml.slice(0, layoutIndex) + layout + newXml.slice(layoutIndex);

  return newXml;
}
