/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { AISparkle } from 'icons';

import * as Styled from './CamundaDocsAiButton.styled';

export default function CamundaDocsAiButton({ buttonRef = null, onClick, id = '' }) {
  return (
    <Styled.CamundaAIButton
      hasIconOnly
      iconDescription="Camunda Docs AI"
      kind="secondary"
      renderIcon={() => {
        return <AISparkle />;
      }}
      size="sm"
      ref={buttonRef}
      id={id}
      onClick={onClick}
    />
  );
}
