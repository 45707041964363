/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef, useState } from 'react';
import { Heading, Link, Section } from '@carbon/react';

import { trackingService } from 'services';
import { NonModalDialog } from 'primitives';
import { organizationStore } from 'stores';

import * as Styled from './CamundaDocsAiFeatureDiscovery.styled';
import CamundaDocsAiButton from './CamundaDocsAiButton';

export default function CamundaAiFeatureDiscovery({ location }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const buttonRef = useRef(null);

  return (
    <>
      <CamundaDocsAiButton
        onClick={() => {
          toggle();
          trackingService.trackDocsAIOpen({ enabled: false, from: location });
        }}
        buttonRef={buttonRef}
      />
      {/* @ts-expect-error TS2741 */}
      <NonModalDialog
        maxWidth="350px"
        onClose={() => setIsOpen(false)}
        onSubmit={() => {
          window.open(`${organizationStore.organizationManagementPageUrl}/settings`);
        }}
        open={isOpen}
        primaryButtonText="Enable"
        anchorEl={buttonRef?.current}
        selectorPrimaryFocus=".ai-features-docs-link"
        passiveModal={!organizationStore.hasElevatedOrganizationPermissions}
      >
        <Styled.DialogBody>
          <Section level={4}>
            <Styled.Tag className="some-class" type="cool-gray">
              Alpha feature
            </Styled.Tag>
            <Heading>Camunda AI features</Heading>
            <Styled.Description>
              {organizationStore.hasElevatedOrganizationPermissions ? (
                <>
                  Click <strong>Enable</strong> to get access to our{' '}
                  <Link
                    href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/advanced-modeling/camunda-docs-ai/"
                    aria-label="Docs AI"
                    target="_blank"
                  >
                    Docs AI
                  </Link>{' '}
                  and{' '}
                  <Link
                    href="https://docs.camunda.io/docs/next/components/early-access/alpha/bpmn-copilot/"
                    aria-label="BPMN copilot"
                    target="_blank"
                  >
                    BPMN Copilot
                  </Link>
                  .
                </>
              ) : (
                <>
                  <>
                    Contact your org admin to enable{' '}
                    <Link
                      href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/advanced-modeling/camunda-docs-ai/"
                      aria-label="Docs AI"
                      target="_blank"
                    >
                      Docs AI
                    </Link>{' '}
                    and{' '}
                    <Link
                      href="https://docs.camunda.io/docs/next/components/early-access/alpha/bpmn-copilot/"
                      aria-label="BPMN copilot"
                      target="_blank"
                    >
                      BPMN Copilot
                    </Link>
                    .
                  </>
                </>
              )}
            </Styled.Description>
          </Section>
        </Styled.DialogBody>
      </NonModalDialog>
    </>
  );
}
