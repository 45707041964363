/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

export const AddNewExtractionFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddNewExtractionFieldHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ $showNewExtractionFieldSection }) => ($showNewExtractionFieldSection ? '10px' : '')};
`;

export const AddNewExtractionFieldHeaderText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-weight: 600;
  }
`;

export const AddNewExtractionFieldNameAndTypeSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding-bottom: 20px;

  & > *:first-child {
    flex: 2;
  }

  & > *:nth-child(2) {
    flex: 1;
  }
`;

export const AddNewExtractionFieldFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  & > *:first-child {
    flex: 4;
  }

  & > *:nth-child(2) {
    flex: 1;
`;

export const ExtractDocumentSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  gap: 20px;

  & > * {
    flex: 1;
  }
`;

export const ExtractDocumentActionContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
`;

export const ExtractionFieldsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;

  @media screen and (max-height: 700px) {
    overflow-y: unset;
  }
`;

export const ExtractionFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 20px;

  & > *:first-child,
  & > *:nth-child(2) {
    flex: 0 1 47.5%;
  }

  & > *:last-child {
    flex: 0 1 5%;
  }
`;
