/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { DMN, IMPORT_DIALOG_SOURCE } from 'utils/constants';
import { extractFileNameFromResourceUrl, prepareOutputForSingleTemplate } from 'components/ImportModal/ImportHelpers';
import { getEntityIcon } from 'utils/helpers';

import BaseImportService from './BaseImportService';

class ImportDmnService extends BaseImportService {
  async import({ selectedProject, resourcesMetadata }) {
    // @ts-expect-error TS2353
    return this.importNoDuplicateCheck({ selectedProject, resourcesMetadata, type: DMN, source: IMPORT_DIALOG_SOURCE });
  }

  async prepareOutput(template) {
    return prepareOutputForSingleTemplate(template, DMN);
  }

  extractFileName(template, resource) {
    return extractFileNameFromResourceUrl(template, resource);
  }

  extractIcon(_template, _resource) {
    return getEntityIcon({ type: DMN });
  }

  executePostImportAction(history, _selectedProject, importedFiles) {
    if (importedFiles != null && importedFiles.length > 0) {
      history.push(`/diagrams/${importedFiles[0].id}`);
    }
  }
}

export default new ImportDmnService();
