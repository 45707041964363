/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { isModelElement } from 'diagram-js/lib/model';
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { trackingService } from 'services';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { currentDiagramStore } from 'stores';
import config from 'utils/config';

export class BpmnJSTrackingService {
  modeler = null;
  trackingModule = null;

  init(modeler) {
    this.modeler = modeler;

    if (!this.modeler) {
      throw new Error('Modeler is not available');
    }

    this.trackingModule = modeler.get('bpmnJSTracking', false);

    if (this.trackingModule) {
      this.trackingModule.enable();
      this.registerEvents();
    }
  }

  dispose() {
    this.trackingModule?.disable();
  }

  onTrackingEvent(event) {
    if (!this.#isTrackingEnabled) {
      return;
    }

    const { name, data } = event;
    const eventName = name.replace('.', ':');

    if (eventName === 'selection:select') {
      return;
    }

    const serializableData = this.#filterData(name, data);
    const { id: fileId, type } = currentDiagramStore.state.diagram;

    trackingService.trackDiagramAction({
      eventName,
      eventData: serializableData,
      fileId,
      type,
      mode: currentDiagramStore.isBPMN ? dedicatedModesStore.selectedModeLabel : undefined
    });
  }

  registerEvents() {
    this.trackingModule?.on('tracking.event', (event) => this.onTrackingEvent(event));
  }

  get #isTrackingEnabled() {
    // @ts-expect-error TS2339
    return config.features?.modelingTrackingEnabled && window.Osano?.cm?.analytics && config.mixpanel.enabled;
  }

  #replaceDiagramElement(object) {
    return {
      id: object.id,
      type: object.type,
      templateId: this.#getTemplateIdFromElement(object)
    };
  }

  #filterDiagramElements(data) {
    const replacer = (_, value) => {
      if (isModelElement(value)) {
        return this.#replaceDiagramElement(value);
      }

      if (Array.isArray(value)) {
        return value.map((item) => {
          if (isModelElement(item)) {
            return this.#replaceDiagramElement(item);
          }

          return item;
        });
      }

      return value;
    };

    return JSON.parse(JSON.stringify(data, replacer));
  }

  #getTemplateIdFromElement(element) {
    const businessObject = getBusinessObject(element);
    return businessObject && businessObject.modelerTemplate;
  }

  #getTemplateIdFromEntry(data) {
    let entryId = data.entryId;

    if (entryId.includes('replace.template-')) {
      entryId = entryId.replace('replace.template-', '');
    } else if (entryId.includes('append.template-')) {
      entryId = entryId.replace('append.template-', '');
    } else if (entryId.includes('create.template-')) {
      entryId = entryId.replace('create.template-', '');
    }

    return entryId;
  }

  #filterData(eventName, data) {
    // replace diagram elements with serializable data
    let serializableData = this.#filterDiagramElements(data);

    // get template id from popup menu entry
    if (eventName === 'popupMenu.trigger') {
      serializableData.templateId = this.#getTemplateIdFromEntry(data);
    }

    return serializableData;
  }
}

export default new BpmnJSTrackingService();
