/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';

import * as Styled from './Sidebar.styled';

Styled.Sidebar.propTypes = {
  // @ts-expect-error TS2353
  $isVisible: PropTypes.bool.isRequired
};

// @ts-expect-error TS2339
Styled.Sidebar.Inner = Styled.Inner;
// @ts-expect-error TS2339
Styled.Sidebar.Section = Styled.Section;
// @ts-expect-error TS2339
Styled.Sidebar.Title = Styled.Title;
// @ts-expect-error TS2339
Styled.Sidebar.Subtitle = Styled.Subtitle;
// @ts-expect-error TS2339
Styled.Sidebar.Separator = Styled.Separator;
// @ts-expect-error TS2339
Styled.Sidebar.Toggle = Styled.Toggle;
// @ts-expect-error TS2339
Styled.Sidebar.Footer = Styled.Footer;

export default Styled.Sidebar;
