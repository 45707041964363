/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

import { LinkStyleProps } from './LinkRenderer.styled';
import * as NameRendererStyled from './NameRenderer.styled';

export const Type = NameRendererStyled.Type;

export const Icon = NameRendererStyled.Icon;

export const Title = NameRendererStyled.Title;

// intended to match style of Link from LinkRenderer.styled.js
export const ContentWrapper = styled.div([LinkStyleProps]);
