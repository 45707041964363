/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled, { css } from 'styled-components';
import { UserAvatar as BaseUserAvatar, ChatBot as BaseChatBot } from '@carbon/react/icons';
import { styles } from '@carbon/elements';

/**
 * @type {import('styled-components').IStyledComponent<'web', {
 *     $type: 'human'|'ai';
 * } & React.HTMLAttributes<HTMLDivElement>>}
 */
export const Container = styled.div(
  ({ $type }) => `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 50px 0 8px;
  margin-left: 0;

  ${
    $type === 'human' &&
    css`
      flex-direction: row-reverse;
      padding: 0 8px 0 50px;
    `
  }
`
);

const arrowSharedStyles = css`
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
`;

/**
 * @type {import('styled-components').IStyledComponent<'web', {
 *     $type: 'human'|'ai';
 *     $isProcessing: boolean;
 * } & React.HTMLAttributes<HTMLDivElement>>}
 */
export const ChatMessageBubble = styled.div`
  white-space: pre-wrap;
  ${styles.bodyCompact01};
  color: var(--cds-text-primary);

  ${({ $isProcessing }) =>
    $isProcessing
      ? css`
          padding: 1px 3px 1px 9px;
        `
      : css`
          padding: 8px;
          flex: 1;
        `}

  ${({ $type }) =>
    $type === 'ai' &&
    css`
      background-color: var(--cds-field-02);

      position: relative;
      &::after {
        ${arrowSharedStyles};
        right: 100%;
        border-width: 8px 10px 8px 0;
        border-color: transparent var(--cds-field-02) transparent transparent;
      }
    `}

  ${({ $type }) =>
    $type === 'human' &&
    css`
      background-color: var(--cds-field-hover-03);

      position: relative;
      &::after {
        ${arrowSharedStyles};
        left: 100%;
        border-width: 8px 0 8px 10px;
        border-color: transparent transparent transparent var(--cds-field-hover-03);
      }
    `}
`;

export const UserAvatar = styled(BaseUserAvatar)`
  margin-left: var(--cds-spacing-05);
  align-self: flex-end;
`;

export const ChatBot = styled(BaseChatBot)`
  margin-right: var(--cds-spacing-05);
  align-self: flex-end;
`;
