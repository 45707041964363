/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { Row } from '@carbon/react';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';

import capitalize from 'utils/capitalize';

import * as Styled from './BrowseBlueprintsModal.styled';

export const BrowseBlueprintsModalTopFilters = ({ filters, setFilters, availableFilters, setSearchValue }) => {
  const [filterLabels, setFilterLabels] = useState({});
  const SEARCH_VALUE_CHANGE_DEBOUNCE = 500;

  /**
   * Extract labels for the filters
   */
  const getFilterLabels = () => {
    const filterLabels = {};
    const topFilters = availableFilters?.find((filter) => filter.groupName === 'top')?.filters;
    if (topFilters) {
      topFilters.forEach((filter) => {
        filterLabels[filter.value] = filter.label;
      });
    }
    return filterLabels;
  };

  /**
   * Setup function
   */
  useEffect(() => {
    if (availableFilters?.length > 0) {
      const filters = getFilterLabels();
      setFilterLabels(filters);
    }
  }, [availableFilters]);

  /**
   * Utility function for removing the first word
   */
  const removeFirstWord = (sentence) => {
    return sentence?.substr(sentence?.indexOf(' ') + 1);
  };

  /**
   * Utility function for singularizing a word
   */
  const singularize = (word) => {
    if (word.endsWith('ses') || word.endsWith('xes') || word.endsWith('zes')) {
      return word.slice(0, -2);
    }
    if (word.endsWith('shes') || word.endsWith('ches')) {
      return word.slice(0, -3);
    }

    if (word.endsWith('ies')) {
      return word.slice(0, -3) + 'y';
    }

    return word.slice(0, -1);
  };

  /**
   * Adjust label after selecting a filter
   */
  const adjustLabels = (filterGroup, filterValueArray) => {
    const originalLabels = getFilterLabels();
    if (filterValueArray.length > 0) {
      if (originalLabels[filterGroup].startsWith('All')) {
        // remove first word
        filterLabels[filterGroup] = removeFirstWord(originalLabels[filterGroup]);
      }
      if (filterValueArray.length === 1) {
        filterLabels[filterGroup] = singularize(filterLabels[filterGroup]);
      }
    } else {
      filterLabels[filterGroup] = originalLabels[filterGroup];
    }
    filterLabels[filterGroup] = capitalize(filterLabels[filterGroup]);
    setFilterLabels(filterLabels);
  };

  /**
   * Handle filter onclick
   */
  const handleMultiselectFilterCheckBoxOnclick = (filterGroup, filterValueArray) => {
    // always override
    filters[filterGroup] = filterValueArray;
    setFilters(JSON.parse(JSON.stringify(filters)));

    adjustLabels(filterGroup, filterValueArray);
  };

  /**
   * Search for a blueprint.
   */
  const searchBlueprint = debounce(
    (event) => {
      setSearchValue(event.target.value);
    },
    SEARCH_VALUE_CHANGE_DEBOUNCE,
    {
      trailing: true
    }
  );

  return (
    <Styled.NoGutterFlexGrid condensed>
      <Row condensed>
        <Styled.CondensedColumn sm={4} md={4} lg={4}>
          <Styled.BlueprintsSearch
            size="lg"
            placeholder="Search blueprints"
            labelText="Search"
            closeButtonLabelText="Clear search input"
            id="search-for-a-connector"
            onChange={searchBlueprint}
          />
        </Styled.CondensedColumn>
        {availableFilters
          ?.filter((availableFilter) => 'top' === availableFilter.groupName)
          .map((topFilters) => {
            return topFilters?.filters?.map((filter) => {
              return (
                <Styled.CondensedColumn sm={4} md={4} lg={4} key={filter.value}>
                  <Styled.BrowseBlueprintsMultiSelect
                    label={filterLabels[filter.value] || 'dummy-label'} // Fallback needed because label is set async and there will be a warning in console if no label present
                    id={filter.value}
                    items={filter.options}
                    // @ts-expect-error TS2339
                    itemToString={(item) => (item ? item.label : '')}
                    onChange={(obj) => {
                      handleMultiselectFilterCheckBoxOnclick(
                        filter.value,
                        // @ts-expect-error TS2339
                        obj.selectedItems.map((selectedItem) => selectedItem.value)
                      );
                    }}
                  />
                </Styled.CondensedColumn>
              );
            });
          })}
      </Row>
    </Styled.NoGutterFlexGrid>
  );
};

export default observer(BrowseBlueprintsModalTopFilters);
