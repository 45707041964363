/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useGateValue } from 'App/Statsig';
import config from 'utils/config';

const BPMN_COPILOT_FEATURE = 'bpmn-copilot-web-modeler';

export default function useIsBpmnCopilotEnabled() {
  const isFeatureEnabled = useGateValue(BPMN_COPILOT_FEATURE);
  return config?.statsig?.enabled && isFeatureEnabled;
}
