/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';

import { clusterService } from 'services';
import { findExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

// Matches both `secrets.secretName` and `{{secrets.secretName}}`
const secretsFormatRegex = /(?:{{)?\bsecrets\.([\w-]+)\b(?:}})?/g;
// Used to strip `secrets.` from the secret name
const stripSecretsRegex = /(?:{{)?secrets\.|\b(?:}})?/g;

export const useConnectorsWithMissingSecrets = ({ currentOrganization, selectedClusterId, connectors }) => {
  const [connectorsCount, setConnectorsCount] = useState(0);

  useEffect(() => {
    (async () => {
      await updateConnectorsCount();
    })();
  }, [selectedClusterId]);

  /**
   * Update connectors count when the window is focused; this is to ensure that the count is always up-to-date
   * when the user switches back to the tab after updating the secrets
   */
  useEffect(() => {
    const onFocus = async () => {
      if (document.hidden) {
        return;
      }
      await updateConnectorsCount();
    };
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const updateConnectorsCount = async () => {
    if (selectedClusterId) {
      const connectorsWithMissingSecrets = await getConnectorsWithMissingSecrets();
      // @ts-expect-error TS2339
      setConnectorsCount(connectorsWithMissingSecrets.length);
    }
  };

  const getConnectorsThatRequireSecrets = () => {
    const connectorElementsWithSecrets = [];

    connectors.forEach((el) => {
      const referencedSecrets = [...getIoMappingWithSecrets(el), ...getPropertiesWithSecrets(el)]
        .map((item) => {
          const source = item.get('source') || item.get('value');
          const matches = source?.match(secretsFormatRegex).map((match) => match.replace(stripSecretsRegex, ''));
          return matches?.[0];
        })
        .filter(Boolean);

      if (referencedSecrets?.length) {
        connectorElementsWithSecrets.push({
          element: el,
          secrets: referencedSecrets
        });
      }
    });

    return connectorElementsWithSecrets;
  };

  const getConnectorsWithMissingSecrets = async () => {
    if (!currentOrganization || !selectedClusterId) {
      return 0;
    }

    const existingSecrets = (await clusterService.getConnectorSecrets(currentOrganization.id, selectedClusterId)) || {};
    const existingSecretsArr = Object.entries(existingSecrets).map(([key, value]) => ({ name: key, value }));

    const connectorElementsThatRequireSecrets = getConnectorsThatRequireSecrets();
    const connectorElementsWithMissingSecrets = [];

    connectorElementsThatRequireSecrets?.forEach(({ element: connector, secrets }) => {
      const missingSecrets = secrets?.filter(
        (secret) => !existingSecretsArr.some(({ name, value }) => name === secret && value?.trim() !== '')
      );

      if (missingSecrets?.length > 0) {
        connectorElementsWithMissingSecrets.push({
          connector,
          missingSecrets
        });
      }
    });

    return connectorElementsWithMissingSecrets;
  };

  return connectorsCount;
};

function getIoMappingWithSecrets(node) {
  const ioMapping = findExtensionElement(node, 'zeebe:IoMapping');

  if (!ioMapping) {
    return [];
  }

  return ioMapping.get('inputParameters').filter((inputParameter) => isValidSecret(inputParameter.get('source')));
}

function getPropertiesWithSecrets(node) {
  const properties = findExtensionElement(node, 'zeebe:Properties');

  if (!properties) {
    return [];
  }

  return properties.get('properties').filter((property) => isValidSecret(property.get('value')));
}

const isValidSecret = (value) => {
  return value?.includes('secrets.') && secretsFormatRegex.test(value);
};
