/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import localStorage from './localstorage';

export default function (error) {
  // @ts-expect-error TS2363
  if (Date.now() - localStorage.getItem('lastFailedAsyncImport') < 10000) {
    // if a chunk loading error occured recently (<10s ago), the requested chunk might
    // actually be missing, and we throw the error to avoid infinite reloads
    throw error;
  } else {
    // if the chunk is missing, the user might have an outdated index.html or app bundle
    // reload the window to get the latest version
    localStorage.setItem('lastFailedAsyncImport', Date.now());
    window.location.reload();
  }
}
