/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Column, RadioTile, FileUploaderDropContainer, Tag, Checkbox } from '@carbon/react';
import { WarningFilled, CheckmarkFilled } from '@carbon/icons-react';

export const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
`;

export const PageContentWrapper = styled.div`
  max-width: 740px;
`;

export const Divider = styled.hr`
  width: 100%;
  border-top: 1px solid var(--cds-border-subtle-01);
  border-bottom: none;
  border-left: none;
  border-right: none;
`;

export const CreateElementTemplateTypeTileWrapper = styled.div`
  height: 100%;
  display: grid;
`;

export const CreateElementTemplateTypeRadioTile = styled(RadioTile)`
  height: 100%;
`;

export const RadioButtonColumn = styled(Column)`
  justify-self: start;
  margin-bottom: 0.5rem;
`;

export const FullWidthFileUploaderDropContainer = styled(FileUploaderDropContainer)`
  min-width: 100%;
`;

export const MethodTag = styled(Tag)`
  margin-top: 0px;
  margin-left: 16px;
`;

export const NestedCheckboxChildContainer = styled.div`
  margin-left: 20px;
`;

export const MethodCheckbox = styled(Checkbox)`
  .cds--checkbox-label-text {
    width: 640px;
  }
`;

export const RedErrorFilledIcon = styled(WarningFilled)`
  margin-bottom: -3px;
  fill: var(--cds-support-error, #da1e28);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;

export const GreenCheckmarkFilled = styled(CheckmarkFilled)`
  position: relative;
  left: -28px;
  fill: var(--cds-support-success, #24a148);
  [data-icon-path='inner-path'] {
    fill: white;
    opacity: 1;
  }
`;

export const MethodNotSupported = styled.span`
  margin-left: 20px;
  color: var(--cds-support-error, #da1e28);
`;

export const TextInputWithButtonContainer = styled.div`
  display: flex;
`;

export const TextInputWithIconContainer = styled.div`
  flex: 2;
  margin-right: 2em;
  position: relative;
  display: flex;
  align-items: center;
`;
