/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const HelperText = styled('div')`
  width: 80%;
`;

export const SectionTitle = styled.h5`
  font-weight: normal;
  margin-bottom: 10px;
`;

export const NoClusterSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const ClusterTitle = styled.span`
  display: block;
`;
