/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default function Clipboard() {}

Clipboard.prototype.set = function (data) {
  // @ts-expect-error TS2551
  window.__clipboard = data;
};

Clipboard.prototype.get = function () {
  // @ts-expect-error TS2551
  return window.__clipboard;
};

Clipboard.prototype.clear = function () {
  // @ts-expect-error TS2551
  const data = window.__clipboard;

  // @ts-expect-error TS2551
  delete window.__clipboard;

  return data;
};

Clipboard.prototype.isEmpty = function () {
  // @ts-expect-error TS2551
  return !window.__clipboard;
};
