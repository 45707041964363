/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-expect-error TS2307
import BpmnModdle from 'bpmn-moddle';
import ModelerModdleExtension from 'modeler-moddle/resources/modeler.json';

export default async function parseXMLtoModdleDefinition(xml) {
  const moddle = new BpmnModdle({
    modeler: ModelerModdleExtension
  });
  const { rootElement } = await moddle.fromXML(xml);

  return rootElement;
}
