/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { InlineLoading, Stack } from '@carbon/react';

import Message from './Message';

export default function Messages(props) {
  const { messages, isProcessing } = props;

  if (!messages || messages.length === 0) {
    return null;
  }

  return (
    <Stack gap={5}>
      {messages.map((message) => (
        <Message key={message.id} type={message.type}>
          {message.text}
        </Message>
      ))}
      {isProcessing && (
        <Message type="ai" isProcessing>
          <InlineLoading data-test="ai-processing" />
        </Message>
      )}
    </Stack>
  );
}
