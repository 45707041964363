/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

import { Avatar, IconButton } from 'primitives';

export const EditCommentFormFooter = styled.div`
  margin-top: var(--spacing-tiny);

  text-align: right;

  > button:first-of-type {
    margin-right: var(--spacing-tiny);
  }
`;

export const Comment = styled.article`
  display: flex;

  padding: 15px;

  font-size: 13px;

  border-radius: 3px;

  ${(props) =>
    props.hasHover &&
    `
    &:hover {
      background-color: rgba(245, 245, 245, 0.6);

      button {
        opacity: 1;
      }
    }
  `};
`;

export const CommentContent = styled.div`
  flex: 1;
`;

export const CommentHeader = styled.header`
  position: relative;

  margin-bottom: var(--spacing-tiny);
`;

export const CommentUser = styled.h5`
  display: inline-block;
  margin: 0 8px 0 0;

  color: var(--grey-darken-23);

  font-size: 13px;
`;

export const CommentDate = styled.time`
  font-size: 12px;

  color: var(--grey-lighten-50);
`;

export const CommentText = styled.p`
  margin: 0;

  line-height: 1.4;
  word-break: break-word;
  font-size: unset;

  color: var(--grey-darken-33);

  a {
    color: var(--cds-blue-60);
  }
`;

export const UserAvatar = styled(Avatar)`
  margin-right: 15px;
`;

export const EditCommentButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
`;
