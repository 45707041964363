/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useExperimentWithTracking } from 'App/Statsig';
import config from 'utils/config';

const EXPERIMENT_NAME = 'embed_academy_how-to';

export default function useIsPlayAcademyExperiment({ tracking = true } = {}) {
  const experiment = useExperimentWithTracking({
    experimentName: EXPERIMENT_NAME,
    tracking
  });

  // @ts-expect-error TS2339
  return config?.statsig?.enabled && experiment?.config?.get('enable_feature', false);
}
