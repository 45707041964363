/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { fileService } from 'services';
import { breadcrumbStore, notificationStore, currentDiagramStore } from 'stores';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import { FORM } from 'utils/constants';
import buildSlug from 'utils/buildSlug';
import { createEntity, generateIdFromElementName, trackSubResourceCreation } from 'components/TargetSelector/utils';
import { createOrUpdateExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

export default async function createForm(target, history) {
  /**
   * Links the form to the selected element and saves the diagram.
   * @param {Object} form The form to link to the selected element.
   * @returns {Promise<void>} Resolves when the form is linked and the diagram is saved.
   */
  const linkFormToDiagramAndSave = async (form) => {
    let formContent;
    try {
      formContent = JSON.parse(form.content);
    } catch (ex) {
      console.error(ex);
      notificationStore.showError('Could not parse form content. Please try again.');
    }

    const element = formLinkStore.selectedElement;
    const formId = formContent.id;
    const modeler = currentDiagramStore.modeler;

    createOrUpdateExtensionElement(element, 'zeebe:FormDefinition', { formId }, modeler);

    await currentDiagramStore.saveContent();
  };

  const elementName = formLinkStore.selectedElementName;
  const attributes = elementName
    ? {
        name: elementName,
        formId: generateIdFromElementName(elementName, FORM)
      }
    : {};
  const entity = createEntity({
    type: FORM,
    attributes,
    target
  });

  try {
    const form = await fileService.create(entity);

    await linkFormToDiagramAndSave(form);

    trackSubResourceCreation({
      newFileId: form.id,
      newFileType: FORM,
      newFileTarget: target
    });
    breadcrumbStore.toggleEditingFor('form');
    history.push(`/forms/${buildSlug(form.id, form.name)}`);
  } catch (ex) {
    console.error(ex);
    notificationStore.showError('Could not create new form. Please try again.');
  }
}
