/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button } from '@carbon/react';
import { Fragment } from 'react';
import { observer } from 'mobx-react';

import config from 'utils/config';
import { ClustersList, ClusterUpdateNotification, CreateNewClusterSection } from 'components';
import { idpApplicationStore, organizationStore } from 'stores';

import * as Styled from './ClusterSelection.styled';

function ClusterSelectionSaas(props) {
  return (
    idpApplicationStore.isCreationModalVisible && (
      <section>
        <Styled.SectionTitle>Select a cluster </Styled.SectionTitle>
        {/* @ts-expect-error TS2741 */}
        <ClusterUpdateNotification isModalOpen>
          {!props.clusters || props.clusters.length === 0 ? (
            <Styled.NoClusterSection>
              <span>
                Your organization doesn't have any clusters yet. Create a stable cluster to store and test your sample
                documents.
              </span>
            </Styled.NoClusterSection>
          ) : (
            <Fragment>
              <span>
                Select a cluster to store and test your sample documents. Deleting the cluster will permanently remove
                all documents and configurations.
              </span>
              <ClustersList {...props} />
            </Fragment>
          )}
        </ClusterUpdateNotification>

        {/* @ts-expect-error TS2607 */}
        <CreateNewClusterSection
          creationElement={
            <Button
              size="md"
              onClick={() => {
                window.open(
                  `https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/clusters?create=true`
                );
              }}
              kind={!props.clusters || props.clusters.length === 0 ? 'primary' : 'tertiary'}
            >
              Create new cluster
            </Button>
          }
          clusterType="DEV"
        />
      </section>
    )
  );
}

export default observer(ClusterSelectionSaas);
