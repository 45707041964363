/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Toggle } from '@carbon/react';

import { Sidebar } from 'primitives';
import { milestoneStore } from 'stores';

const MilestonesDiffingToggle = () => {
  return (
    // @ts-expect-error TS2339
    <Sidebar.Footer>
      <Toggle
        toggled={milestoneStore.state.isDiffingEnabled}
        onToggle={milestoneStore.setDiffingEnabled}
        labelText="Show changes"
        hideLabel
        data-test="diffing-toggle"
        size="sm"
        aria-label="Show changes"
        id="diffing-toggle"
      />
      {/* @ts-expect-error TS2339 */}
    </Sidebar.Footer>
  );
};

export default observer(MilestonesDiffingToggle);
