/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Document, Thumbnail } from 'react-pdf';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  background-color: var(--silver-base-97);
  padding-top: 20px;
`;

export const Sidebar = styled.div`
  height: 100vh;
  overflow-y: auto;
  background-color: var(--silver-base-97);
  padding-right: 5px;
  border-right: 1px solid var(--silver-darken-87);
  margin-left: auto;
`;

export const PageThumbnail = styled(Thumbnail)`
  &.active {
    display: block;
    border: 2px solid var(--cds-blue-70);
  }
`;

export const PdfDocument = styled(Document)`
  margin-left: auto;
  max-width: 650px;
  overflow: auto;
`;

export const LoaderContainer = styled.div`
  margin: 0 auto;
`;
