/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { isUndefined, without } from 'min-dash';
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil';
import {
  SelectEntry,
  TextFieldEntry,
  TextAreaEntry,
  isSelectEntryEdited,
  isTextFieldEntryEdited,
  isTextAreaEntryEdited
} from '@bpmn-io/properties-panel';
import { useService } from 'bpmn-js-properties-panel';

import {
  createUserTaskFormId,
  FORM_TYPES,
  getFormDefinition,
  getFormType,
  getUserTaskForm,
  userTaskFormIdToFormKey
} from 'App/Pages/Diagram/FormLinking/utils';
import { createElement } from 'utils/web-modeler-diagram-parser/create-element';
import { getRootElement } from 'utils/web-modeler-diagram-parser/get-root-element';
import { getOrCreateExtensionElements } from 'utils/web-modeler-diagram-parser/get-or-create-extension-elements';
import { createUpdateModdlePropertiesCommand } from 'utils/web-modeler-diagram-parser/create-update-moddle-properties-command';

/**
 * NOTE: This file contains portions of code that were copied from the bpmn-js-properties-panel
 * library and adapted. This because the core library does not allow to have the form group for elements
 * that are not UserTask. (see #4583, and #6615)
 *
 * We have created an issue to clean this up, as soon as the core library allows us to do so.
 * See issue: #4737
 */
export function FormProps(props) {
  const { element } = props;
  const doesSupportForms = is(element, 'bpmn:UserTask') || is(element, 'bpmn:StartEvent');

  if (!doesSupportForms) {
    return [];
  }

  const entries = [
    {
      id: 'formType',
      component: FormType,
      isEdited: isSelectEntryEdited
    }
  ];

  const formType = getFormType(element);

  if (formType === FORM_TYPES.CAMUNDA_FORM_EMBEDDED) {
    entries.push({
      id: 'formConfiguration',
      component: FormConfiguration,
      isEdited: isTextAreaEntryEdited
    });
  } else if (formType === FORM_TYPES.CAMUNDA_FORM_LINKED) {
    entries.push({
      id: 'formId',
      component: FormId,
      isEdited: isTextFieldEntryEdited
    });
  } else if (formType === FORM_TYPES.CUSTOM_FORM) {
    entries.push({
      id: 'customFormKey',
      component: CustomFormKey,
      isEdited: isTextFieldEntryEdited
    });
  }

  return entries;
}

function FormType(props) {
  const { element } = props;

  const injector = useService('injector'),
    translate = useService('translate');

  const getValue = () => {
    return getFormType(element) || '';
  };

  const setValue = (value) => {
    if (value === FORM_TYPES.CAMUNDA_FORM_EMBEDDED) {
      setUserTaskForm(injector, element, '');
    } else if (value === FORM_TYPES.CAMUNDA_FORM_LINKED) {
      setFormId(injector, element, '');
    } else if (value === FORM_TYPES.CUSTOM_FORM) {
      setCustomFormKey(injector, element, '');
    } else {
      removeFormDefinition(injector, element);
    }
  };

  const getOptions = () => {
    return [
      { value: '', label: translate('<none>') },
      {
        value: FORM_TYPES.CAMUNDA_FORM_LINKED,
        label: translate('Camunda form (linked)')
      },
      {
        value: FORM_TYPES.CAMUNDA_FORM_EMBEDDED,
        label: translate('Camunda form (embedded)')
      }
    ];
  };

  return SelectEntry({
    element,
    id: 'formType',
    label: translate('Type'),
    getValue,
    setValue,
    getOptions
  });
}

function FormConfiguration(props) {
  const { element } = props;

  const debounce = useService('debounceInput'),
    injector = useService('injector'),
    translate = useService('translate');

  const getValue = () => {
    return getUserTaskForm(element).get('body');
  };

  const setValue = (value) => {
    setUserTaskForm(injector, element, isUndefined(value) ? '' : value);
  };

  return TextAreaEntry({
    element,
    id: 'formConfiguration',
    label: translate('Form JSON configuration'),
    rows: 4,
    getValue,
    setValue,
    debounce
  });
}

function FormId(props) {
  const { element } = props;

  const debounce = useService('debounceInput'),
    injector = useService('injector'),
    translate = useService('translate');

  const getValue = () => {
    return getFormDefinition(element).get('formId');
  };

  const setValue = (value) => {
    setFormId(injector, element, isUndefined(value) ? '' : value);
  };

  return TextFieldEntry({
    element,
    id: 'formId',
    label: translate('Form ID'),
    getValue,
    setValue,
    debounce
  });
}

function CustomFormKey(props) {
  const { element } = props;

  const debounce = useService('debounceInput'),
    injector = useService('injector'),
    translate = useService('translate');

  const getValue = () => {
    return getFormDefinition(element).get('formKey');
  };

  const setValue = (value) => {
    setCustomFormKey(injector, element, isUndefined(value) ? '' : value);
  };

  return TextFieldEntry({
    element,
    id: 'customFormKey',
    label: translate('Form key'),
    getValue,
    setValue,
    debounce
  });
}

// helpers /////////////

/**
 * @typedef { { cmd: string, context: Object } } Command
 * @typedef {Command[]} Commands
 * @typedef {import('diagram-js/lib/model/Types').Element} Element
 * @typedef {import('bpmn-js/lib/model/Types').ModdleElement} ModdleElement
 * @typedef {import('didi').Injector} Injector
 */

/**
 * @param {Injector} injector
 * @param {Element} element
 *
 * @returns { {
 *   commands: Commands,
 *   formDefinition: ModdleElement
 * } }
 */
function getOrCreateFormDefintition(injector, element) {
  let formDefinition = getFormDefinition(element);

  if (formDefinition) {
    return {
      commands: [],
      formDefinition
    };
  }

  // @ts-expect-error TS2345
  const { extensionElements, commands } = getOrCreateExtensionElements(injector, element);

  formDefinition = createFormDefinition(injector, {}, extensionElements);

  return {
    commands: [
      ...commands,
      // @ts-expect-error TS2345
      createUpdateModdlePropertiesCommand(element, extensionElements, {
        values: [...extensionElements.get('values'), formDefinition]
      })
    ],
    formDefinition
  };
}

/**
 // @ts-expect-error TS2304
 * @param {Injector} injector
 * @param {Element} element
 *
 * @returns { {
 *   commands: Commands,
 *   formDefinition: ModdleElement,
 *   userTaskForm: ModdleElement
 * } }
 */
function getOrCreateUserTaskForm(injector, element) {
  let userTaskForm = getUserTaskForm(element);

  if (userTaskForm) {
    return {
      commands: [],
      formDefinition: getFormDefinition(element),
      userTaskForm
    };
  }

  const rootElement = getRootElement(element);

  const { extensionElements, commands: extensionElementsCommands } = getOrCreateExtensionElements(
    injector,
    // @ts-expect-error TS2345
    element,
    rootElement
  );

  const { formDefinition, commands: formDefinitionCommands } = getOrCreateFormDefintition(injector, element);

  const formId = createUserTaskFormId();

  userTaskForm = createUserTaskForm(
    injector,
    {
      id: formId
    },
    extensionElements
  );

  return {
    commands: [
      ...extensionElementsCommands,
      ...formDefinitionCommands,
      // @ts-expect-error TS2345
      createUpdateModdlePropertiesCommand(element, extensionElements, {
        values: [...extensionElements.get('values'), userTaskForm]
      }),
      // @ts-expect-error TS2345
      createUpdateModdlePropertiesCommand(element, formDefinition, {
        formKey: userTaskFormIdToFormKey(formId)
      })
    ],
    formDefinition,
    userTaskForm
  };
}

function setFormId(injector, element, formId) {
  let { commands, formDefinition } = getOrCreateFormDefintition(injector, element);

  const commandStack = injector.get('commandStack');

  commandStack.execute('properties-panel.multi-command-executor', [
    ...commands,
    createUpdateModdlePropertiesCommand(element, formDefinition, {
      formId
    })
  ]);
}

function setCustomFormKey(injector, element, formKey) {
  let { commands, formDefinition } = getOrCreateFormDefintition(injector, element);

  const commandStack = injector.get('commandStack');

  commandStack.execute('properties-panel.multi-command-executor', [
    ...commands,
    createUpdateModdlePropertiesCommand(element, formDefinition, {
      formKey
    })
  ]);
}

function setUserTaskForm(injector, element, body) {
  let { commands, userTaskForm } = getOrCreateUserTaskForm(injector, element);

  const commandStack = injector.get('commandStack');

  commandStack.execute('properties-panel.multi-command-executor', [
    ...commands,
    createUpdateModdlePropertiesCommand(element, userTaskForm, {
      body
    })
  ]);
}

function removeFormDefinition(injector, element) {
  const formDefinition = getFormDefinition(element);

  /**
   * @type {import('bpmn-js/lib/features/modeling/Modeling').default}
   */
  const modeling = injector.get('modeling');

  if (formDefinition) {
    const businessObject = getBusinessObject(element),
      extensionElements = businessObject.get('extensionElements');

    modeling.updateModdleProperties(element, extensionElements, {
      values: without(extensionElements.get('values'), formDefinition)
    });
  }
}

/**
 * @param {Injector} injector
 * @param {Object} properties
 * @param {ModdleElement} parent
 *
 * @returns {ModdleElement}
 */
function createFormDefinition(injector, properties, parent) {
  const bpmnFactory = injector.get('bpmnFactory');

  return createElement('zeebe:FormDefinition', properties, parent, bpmnFactory);
}

/**
 * @param {Injector} injector
 * @param {Object} properties
 * @param {ModdleElement} parent
 *
 * @returns {ModdleElement}
 */
function createUserTaskForm(injector, properties, parent) {
  const bpmnFactory = injector.get('bpmnFactory');

  return createElement('zeebe:UserTaskForm', properties, parent, bpmnFactory);
}
