/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

export const Title = styled.div`
  background-color: var(--cm-color-white-base);
  border-top: 1px solid var(--silver-darken-80);
  border-bottom: 1px solid var(--silver-darken-80);
  cursor: pointer;
`;

export const StatusIcon = styled.span`
  svg {
    vertical-align: middle;
  }
  margin-right: var(--spacing-small);
`;

export const StatusText = styled.span`
  vertical-align: middle;
`;

export const Collapse = styled.span`
  float: right;
  cursor: pointer;
  ${({ $isCollapsed }) => $isCollapsed && ` svg { transform: rotate(180deg); } `}
  padding: 7px 10px;
  height: 36px;

  border-radius: 4px;
  &:hover {
    background: var(--silver-darken-87);
  }
`;

export const Errors = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: white;
`;

export const Tab = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 6px 18px 4px 18px;
  font-weight: ${(props) => (props.$active && !props.$isErrorPanelCollapsed ? '500' : '')};
  border-bottom: ${(props) => (props.$active && !props.$isErrorPanelCollapsed ? '2px solid var(--cds-blue-60)' : '')};
  margin-left: 12px;
`;

export const Badge = styled.span`
  background-color: var(--grey-base-40);
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
  color: white;
  display: inline-block;
  margin-left: 6px;
  padding: 1px 6px;

  @media (max-width: 2000px) {
    font-size: 10px;
  }
`;

export const MenuRight = styled.div`
  float: right;
`;

export const DeploymentErrors = styled.div`
  padding: 0 20px;
  white-space: pre-wrap;
`;

export const XmlErrors = styled.div`
  padding: 5px 20px 0;
  white-space: pre-wrap;
`;
