/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

export const SectionHeader = styled.header`
  font-size: 16px;
  font-weight: bold;
  padding: 0;
`;

export const Description = styled.div`
  margin-bottom: var(--spacing-small);
`;

export const HelperText = styled.div`
  font-size: 12px;
  line-height: 12px;
  top: -15px;
  position: absolute;
  right: 0;
  white-space: nowrap;
  ${({ $hasError }) => ($hasError ? 'color: var(--cm-color-red-base);' : '')}
`;
