/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import React, { useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { breadcrumbStore, organizationStore, userStore } from 'stores';
import buildPath from 'utils/build-path';

import * as Styled from './TopBar.styled';
import {
  useHelpCenterAndNotifications,
  useInfoLinks,
  useNavbar,
  useOrganizations,
  useReloadClustersOnClusterCreationUpgradeOrDeletion,
  useUser
} from './hooks';

const TopBarCommon = ({
  showAppBar = true,
  showOrganizations = true,
  showCookiePreferences = true,
  showInfoBar = true,
  showUserBar = true,
  showHelpCenterAndNotifications = true,
  licenseTag = {
    show: false,
    expiresAt: undefined,
    isCommercial: false,
    isProductionLicense: false
  },
  termsOfUseUrl,
  forwardRefLink
}) => {
  const history = useHistory();

  const { currentOrganizationInfo } = organizationStore;

  const orgSideBar = useOrganizations({ showOrganizations });
  const { helpCenterConfig, notificationsConfig } = useHelpCenterAndNotifications({ showHelpCenterAndNotifications });
  const infoSideBar = useInfoLinks({ showInfoBar });
  const userSideBar = useUser({
    showUserBar,
    termsOfUseUrl,
    showCookiePreferences
  });
  const navbar = useNavbar({
    orgName: currentOrganizationInfo?.name,
    licenseTag
  });
  useReloadClustersOnClusterCreationUpgradeOrDeletion();

  const hideAppBar = !showAppBar;

  return (
    // @ts-expect-error TS2769
    <Styled.Wrapper data-test="top-bar" $hideApps={hideAppBar}>
      <Styled.Navigation
        app={{
          ariaLabel: 'Camunda Modeler',
          name: 'Modeler',
          routeProps: {
            className: 'clickable',
            onClick: () => {
              if (userStore.isAuthenticated) {
                history.push('/');
              } else {
                window.location.assign(buildPath('/login'));
              }
            }
          }
        }}
        navbar={navbar}
        appBar={{
          isOpen: false,
          ariaLabel: 'App Panel',
          appTeaserRouteProps: {
            operate: {
              to: '/appTeaser/operate'
            },
            optimize: {
              to: '/appTeaser/optimize'
            },
            tasklist: {
              to: '/appTeaser/tasklist'
            }
          }
        }}
        orgSideBar={orgSideBar}
        infoSideBar={infoSideBar}
        userSideBar={userSideBar}
        // @ts-expect-error TS2769
        helpCenter={helpCenterConfig}
        notificationSideBar={notificationsConfig}
        forwardRef={forwardRefLink}
      />

      {/* @ts-expect-error TS2769 */}
      <Styled.Breadcrumbs data-test="breadcrumbs" $appBarHidden={hideAppBar}>
        <Observer>{() => breadcrumbStore.breadcrumbs}</Observer>
      </Styled.Breadcrumbs>

      <Styled.SubHeader>
        <Observer>{() => breadcrumbStore.subHeader}</Observer>
      </Styled.SubHeader>
    </Styled.Wrapper>
  );
};

const Breadcrumbs = (props) => {
  // Each child (breadcrumb) needs to have a key prop, since it is
  // rendered as an uncontrolled component in TopBar.js. By looping over
  // `cloneElement` here, said key is set automatically.
  const children = React.Children.map(props.children, (child) => {
    if (child) {
      return React.cloneElement(child);
    }
  });

  useEffect(() => {
    breadcrumbStore.setBreadcrumbs(children);
  }, [children]);

  return null;
};

const SubHeader = ({ children }) => {
  useEffect(() => {
    breadcrumbStore.setSubHeader(children);

    return () => breadcrumbStore.setSubHeader(null);
  }, [children]);

  return null;
};

TopBarCommon.Breadcrumbs = Breadcrumbs;
TopBarCommon.SubHeader = SubHeader;

TopBarCommon.propTypes = {
  showAppBar: PropTypes.bool,
  showOrganizations: PropTypes.bool,
  showCookiePreferences: PropTypes.bool,
  licenseTag: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    expiresAt: PropTypes.number,
    isCommercial: PropTypes.bool,
    isProductionLicense: PropTypes.bool
  }),
  termsOfUseUrl: PropTypes.string
};

export default observer(TopBarCommon);
