/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { createAuth0Client } from '@auth0/auth0-spa-js';
import { runInAction } from 'mobx';

import history from 'utils/history';
import config from 'utils/config';
import { ERROR_REASON_MAINTENANCE, JWT_ORGANIZATION_CLAIM, NO_ORG_CLAIM } from 'utils/constants';
import { tracingService } from 'services';
import { STATUS_SERVICE_UNAVAILABLE } from 'Server/util/shared-utils';

import BaseAuthService from './AuthService.common';

class AuthService extends BaseAuthService {
  async #createAuthClient() {
    this.authClient = await createAuth0Client({
      domain: `weblogin.${config.camundaCloudBaseDomain}`,
      clientId: config.oAuth2.clientId,
      authorizationParams: {
        audience: config.oAuth2.token.audience,
        redirect_uri: `${config.modelerUrl}/login-callback`,
        scope: 'openid email profile',
        useRefreshTokens: true
      }
    });
  }

  /**
   * Triggers the token refresh using a silent approach.
   *
   * The {@link import('@auth0/auth0-spa-js').Auth0Client#getTokenSilently()} method will return the cached token if
   * it's not expired yet (with a threshold of 60seconds before the expiration).
   *
   * If the token is expired or going to expire, it gets refreshed.
   *
   * The above mechanism is handled by the auth0 client internally.
   *
   * @param { Object } config
   * @param { boolean } [config.useCache] - whether to use the cache or not
   */
  async refreshToken({ useCache = true } = {}) {
    if (this.authClient) {
      try {
        const token = await this.authClient.getTokenSilently({
          cacheMode: useCache ? 'on' : 'off'
        });
        this.setToken(token);

        return new Promise((resolve, reject) => {
          if (!token) {
            reject(new Error('Missing token'));
            return;
          }

          resolve(token);
        });
      } catch (error) {
        console.error(error);
        // @ts-expect-error TS2554
        tracingService.traceError(error);
        this.logout();
      }
    }
  }

  async fetchJWTUser() {
    try {
      const user = await this.authClient.getUser();
      if (user) {
        runInAction(() => {
          this.jwtUser = { ...user };
        });
      } else {
        this.logout();
      }
    } catch (error) {
      console.error(error);
      // @ts-expect-error TS2554
      tracingService.traceError(error);
      this.logout();
    }
  }

  async loginWithRedirect() {
    const returnTo = this.getReturnToFromUrl();

    if (!this.authClient) {
      await this.#createAuthClient();
    }

    if (await this.authClient.isAuthenticated()) {
      this.#handlePersistedSession(returnTo);
    } else {
      this.authClient.loginWithRedirect({
        appState: {
          returnTo
        }
      });
    }
  }

  async #handlePersistedSession(returnTo) {
    await this.prepareSession();
    history.push(returnTo || '/');
  }

  #handleNoOrganizationClaim() {
    window.location.assign(`https://console.${config.camundaCloudBaseDomain}/onboarding`);
  }

  #refreshTokenInvalidatingCache(resolve, reject) {
    const noOrgClaimReason = { reason: NO_ORG_CLAIM };

    this.authClient
      .getTokenSilently({ cacheMode: 'off' })
      .then(() => {
        this.fetchJWTUser()
          .then(() => {
            if (this.jwtUser[JWT_ORGANIZATION_CLAIM]?.length) {
              resolve(this.jwtUser[JWT_ORGANIZATION_CLAIM]);
            } else {
              this.#handleNoOrganizationClaim();
              reject(noOrgClaimReason);
            }
          })
          .catch((error) => {
            console.error(error);
            return reject(noOrgClaimReason);
          });
      })
      .catch((error) => {
        console.error(error);
        return reject(noOrgClaimReason);
      });
  }

  async getTokenAndFetchAuthProviderUser() {
    try {
      await this.authClient.getTokenSilently();
    } catch (error) {
      console.error(error);
      throw error;
    }
    await this.fetchJWTUser();

    return new Promise((resolve, reject) => {
      if (this.jwtUser[JWT_ORGANIZATION_CLAIM]?.length) {
        resolve(this.jwtUser[JWT_ORGANIZATION_CLAIM]);
      } else {
        this.#refreshTokenInvalidatingCache(resolve, reject);
      }
    });
  }

  async handleRedirectCallback() {
    if (!this.authClient) {
      await this.#createAuthClient();
    }

    let appState = {};

    try {
      const redirectRes = await this.authClient.handleRedirectCallback();
      appState = redirectRes.appState;

      await this.getTokenAndFetchAuthProviderUser();
      await this.createModelerUser();

      runInAction(() => {
        this.isReady = true;
      });

      history.push(appState.returnTo || '/');
    } catch (error) {
      // If a request has failed because the restapi is in maintenance mode, the user should be redirected to the
      // maintenance page instead of being logged out. So this special case is ignored here (the redirect is already
      // triggered in Service#handleRestapiInMaintenanceMode).
      const restAPIInMaintenance =
        error?.status === STATUS_SERVICE_UNAVAILABLE && error?.reason === ERROR_REASON_MAINTENANCE;

      // When there is no org claim the user should be redirected to console
      // (and this is already happening in handleNoOrganizationClaim)
      // Instead of being logged out
      const noOrgClaim = error?.reason === NO_ORG_CLAIM;

      const preventLogout = restAPIInMaintenance || noOrgClaim;
      if (preventLogout) {
        return;
      }

      console.error('Error while redirecting the user', error);
      // @ts-expect-error TS2554
      tracingService.traceError(error);
      this.logout();
    }
  }

  async logout() {
    if (!this.authClient) {
      await this.#createAuthClient();
    }

    this.reset();

    this.authClient.logout({
      logoutParams: {
        returnTo: config.modelerUrl
      }
    });
  }
}

export default new AuthService();
