/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'primitives';

import * as Styled from './DropdownButton.styled';

const DropdownButton = forwardRef(
  (
    // @ts-expect-error TS2339
    { component = 'button', children, disabled = false, loading = false, onMainButtonClick, onDropdownClick, ...props },
    ref
  ) => (
    <Styled.Wrapper>
      {/* @ts-expect-error TS2322 */}
      <Button
        as={component}
        ref={ref}
        $isDropdown={false}
        disabled={loading || disabled}
        onClick={onMainButtonClick}
        {...props}
      >
        {children}
      </Button>
      <Button
        // @ts-expect-error TS2322
        $isDropdown
        disabled={loading || disabled}
        onClick={onDropdownClick}
        {...props}
        data-test={props['data-test'] ? `${props['data-test']}-button` : undefined}
      />
    </Styled.Wrapper>
  )
);

DropdownButton.propTypes = {
  ...Button.propTypes,
  // @ts-expect-error TS2353
  onMainButtonClick: PropTypes.func,
  onDropdownClick: PropTypes.func
};

export default DropdownButton;
