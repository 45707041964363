/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Container, ChatMessageBubble, UserAvatar, ChatBot } from './Message.styled';

export default function Message(props) {
  const { children, type, isProcessing } = props;

  return (
    <Container $type={type}>
      {type === 'ai' ? <ChatBot data-test="chatbot-avatar" /> : <UserAvatar data-test="user-avatar" />}
      <ChatMessageBubble $type={type} $isProcessing={isProcessing}>
        {children}
      </ChatMessageBubble>
    </Container>
  );
}
