/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useAreAiFeaturesEnabled, useIsBpmnCopilotEnabled } from 'experiments/hooks';
import { currentDiagramStore, milestoneStore } from 'stores';
import { trackingService } from 'services';

import { CamundaDocsAiButton, CamundaDocsAiFeatureDiscovery, CamundaAiFeatureDiscovery } from './docs-ai/';
import { CamundaAiButtonWithCopilot } from './bpmn-copilot/';

export default function CamundaAIButton({ location }) {
  const areAIFeaturesEnabled = useAreAiFeaturesEnabled();

  const { isAdminOrEditor: hasCorrectProjectPermissions, isBPMN: isDiagramBpmn } = currentDiagramStore;
  const showBpmnCopilot = isDiagramBpmn && hasCorrectProjectPermissions && useIsBpmnCopilotEnabled();

  return areAIFeaturesEnabled ? (
    <AIWidgetTrigger location={location} showBpmnCopilot={showBpmnCopilot} />
  ) : (
    <>
      {showBpmnCopilot ? (
        <CamundaAiFeatureDiscovery location={location} />
      ) : (
        <CamundaDocsAiFeatureDiscovery location={location} />
      )}
    </>
  );
}

const AIWidgetTrigger = ({ location, showBpmnCopilot }) => {
  return showBpmnCopilot ? (
    <CamundaAiButtonWithCopilot
      location={location}
      modeler={currentDiagramStore.modeler}
      saveDiagramContent={currentDiagramStore.saveContent}
      addNewMilestone={() =>
        milestoneStore.createAutosaved(currentDiagramStore.state.diagram, 'bpmnCopilot', {
          name: 'Autosaved before Copilot (AI) generation'
        })
      }
    />
  ) : (
    <CamundaDocsAiButton
      id="camunda-ai-button"
      onClick={() => trackingService.trackDocsAIOpen({ enabled: true, from: location })}
    />
  );
};
