/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';
import { useExperiment } from '@statsig/react-bindings';

import { trackingService } from 'services';

export default function useExperimentWithTracking({ experimentName, tracking = true }) {
  const experiment = useExperiment(experimentName);

  useEffect(() => {
    if (tracking && experiment?.groupName) {
      trackingService.trackExperiment({
        experimentKey: experimentName,
        group: experiment?.groupName
      });
    }
  }, [tracking]);

  return experiment;
}
