/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// File contains the hard-coded version of the client and webapp, will be
// overwritten by CI build, do not move or modify manually!
export const version = '3fade1f618518c9a06f79c3b9e632631f0c5ae08';

export const versionShort = (() => {
  return version.substring(0, 7);
})();
