/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, Dropdown, IconButton, InlineLoading, OverflowMenu, OverflowMenuItem, TextInput } from '@carbon/react';
import { Information } from '@carbon/react/icons';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';
import { useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { Divider } from 'primitives';
import {
  EXTRACTION_FIELD_TYPES,
  EXTRACTION_MODELS,
  EXTRACTION_NOTIFICATION_STEPS
} from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';
import { getExtractionFieldNameInvalidText } from 'App/Pages/Project/IdpApplication/IdpProject/utils/utils';
import { idpApplicationStore, idpProjectStore } from 'stores';
import EditExtractionFieldModal from 'App/Pages/Project/IdpApplication/IdpProject/IdpExtractionFields/extraction-fields/edit-extraction-field-modal/EditExtractionFieldModal';
import OverrideTestCaseModal from 'App/Pages/Project/IdpApplication/IdpProject/IdpExtractionFields/extraction-fields/override-test-case-modal/OverrideTestCaseModal';
import { CLUSTER_STATUS } from 'utils/cluster/constants';
import useNavigationGuard from 'App/Pages/Project/IdpApplication/IdpProject/hooks/useNavigationGuard';
import IdpExtractionInProgressWarningModal from 'App/Pages/Project/IdpApplication/IdpProject/idp-extraction-in-progress-warning-modal/IdpExtractionInProgressWarningModal';

import * as Styled from './ExtractionFields.styled';

const ExtractionFields = ({ onUpdateExtractionField, onUpdateExtractionFieldValues, clusterStatus }) => {
  const [newExtractionFieldName, setNewExtractionFieldName] = useState('');
  const [newExtractionFieldType, setNewExtractionFieldType] = useState(EXTRACTION_FIELD_TYPES[0].id);
  const [newExtractionFieldPrompt, setNewExtractionFieldPrompt] = useState('');
  const [showNewExtractionFieldSection, setShowNewExtractionFieldSection] = useState(true);
  const [isEditExtractionFieldModalVisible, setIsEditExtractionFieldModalVisible] = useState(false);
  const [editingExtractionField, setEditingExtractionField] = useState(null);
  const [isExtractingIdpDocument, setIsExtractingIdpDocument] = useState(false);
  const [extractionModel, setExtractionModel] = useState(null);
  const inputRef = useRef(null);
  const { idpProject, idpExtractionFields, activeExtractionIdpDocument } = idpProjectStore;
  const { idpApplication } = idpApplicationStore;
  const {
    isModalOpen: isIdpExtractionInProgressWarningModalOpen,
    handleConfirm,
    handleCancel
  } = useNavigationGuard({
    isDirty: isExtractingIdpDocument
  });

  const resetAddNewExtractionFields = () => {
    setNewExtractionFieldName('');
    setNewExtractionFieldType(EXTRACTION_FIELD_TYPES[0].id);
    setNewExtractionFieldPrompt('');
  };

  const toggleExpandNewExtractionFieldSection = () => {
    setShowNewExtractionFieldSection((current) => {
      if (!current) {
        // `setTimeout` is needed here because the `setShowNewExtractionFieldSection` call is asynchronous. Since we only
        // want to set the focus once the value is set and input is visible.
        setTimeout(() => inputRef.current?.focus());
      }
      return !current;
    });
    resetAddNewExtractionFields();
  };

  const isExtractionDisabled = () => {
    return (
      isExtractingIdpDocument ||
      idpExtractionFields.length === 0 ||
      !extractionModel ||
      clusterStatus !== CLUSTER_STATUS.HEALTHY
    );
  };

  const isSaveAsTestCaseDisabled = () => {
    return !idpProjectStore.idpExtractionFields.some(
      (idpExtractionField) =>
        !!idpExtractionField.extractedValue && idpExtractionField.extractedValue !== idpExtractionField.expectedValue
    );
  };

  const canAddNewExtractionField = () => {
    return (
      !newExtractionFieldName ||
      !!getExtractionFieldNameInvalidText(newExtractionFieldName, idpExtractionFields) ||
      !newExtractionFieldType ||
      !newExtractionFieldPrompt
    );
  };

  const openEditExtractionModal = (field) => {
    setEditingExtractionField(field);
    setIsEditExtractionFieldModalVisible(true);
  };

  const handleAddNewExtractionField = async () => {
    const idpExtractionFields = await idpProjectStore.addIdpExtractionField(
      idpProject.id,
      newExtractionFieldName,
      newExtractionFieldType,
      newExtractionFieldPrompt
    );
    if (idpExtractionFields?.length === 1 && !extractionModel) {
      idpProjectStore.setExtractionStepNotification(EXTRACTION_NOTIFICATION_STEPS.SELECT_AN_EXTRACTION_FIELD);
    }
    resetAddNewExtractionFields();
  };

  const handleDeleteExtractionField = async (extractionFieldId) => {
    await idpProjectStore.deleteIdpExtractionField(extractionFieldId);
  };

  const handleExtractDocument = async () => {
    setIsExtractingIdpDocument(true);
    await idpProjectStore.extractIdpDocuments(
      idpProject.id,
      idpApplication.clusterId,
      [activeExtractionIdpDocument.id],
      idpExtractionFields,
      extractionModel
    );
    setIsExtractingIdpDocument(false);
  };

  const handleOverrideTestCase = () => onUpdateExtractionFieldValues?.(extractionModel, idpExtractionFields);

  const handleLlmPromptDescriptionChange = (id, newPrompt) => {
    idpProjectStore.setIdpExtractionFields(
      idpExtractionFields.map((field) => (field.id === id ? { ...field, llmPromptDescription: newPrompt } : field))
    );
  };

  return (
    <>
      <Styled.ExtractDocumentSectionContainer data-test="extract-document-section">
        <Dropdown
          id="extraction-model"
          titleText="Extraction model"
          label="Select extraction model"
          items={EXTRACTION_MODELS}
          itemToString={(model) => (model ? model.name : '')}
          onChange={({ selectedItem }) => setExtractionModel(selectedItem.id)}
        />
        <Styled.ExtractDocumentActionContainer>
          {isExtractingIdpDocument ? (
            <InlineLoading description="Extracting..." status="active" />
          ) : (
            <Button size="md" disabled={isExtractionDisabled()} onClick={handleExtractDocument}>
              Extract document
            </Button>
          )}
          <Button
            size="md"
            kind="tertiary"
            disabled={isSaveAsTestCaseDisabled()}
            onClick={() => idpProjectStore.setIsOverrideTestCaseModalVisible(true)}
          >
            Save as test case
          </Button>
        </Styled.ExtractDocumentActionContainer>
      </Styled.ExtractDocumentSectionContainer>
      <Divider />
      <Styled.AddNewExtractionFieldContainer data-test="add-new-extraction-field-section">
        {/*@ts-expect-error TS2769*/}
        <Styled.AddNewExtractionFieldHeader $showNewExtractionFieldSection={showNewExtractionFieldSection}>
          <Styled.AddNewExtractionFieldHeaderText>
            <span>Extraction fields</span>
            <IconButton size="md" kind="ghost" label="Some helping text" align="right">
              <Information />
            </IconButton>
          </Styled.AddNewExtractionFieldHeaderText>
          <Button
            kind="ghost"
            type="button"
            size="md"
            renderIcon={showNewExtractionFieldSection ? ChevronUp : ChevronDown}
            onClick={toggleExpandNewExtractionFieldSection}
          >
            Add field
          </Button>
        </Styled.AddNewExtractionFieldHeader>
        {showNewExtractionFieldSection && (
          <>
            <Styled.AddNewExtractionFieldNameAndTypeSection>
              <TextInput
                ref={inputRef}
                id="new-extraction-field-name"
                autoComplete="off"
                type="text"
                labelText=""
                placeholder="Field name"
                value={newExtractionFieldName}
                onChange={(event) => setNewExtractionFieldName(event.target.value)}
                invalid={!!getExtractionFieldNameInvalidText(newExtractionFieldName, idpExtractionFields)}
                invalidText={getExtractionFieldNameInvalidText(newExtractionFieldName, idpExtractionFields)}
              />
              <Dropdown
                id="new-extraction-field-type"
                titleText=""
                label=""
                initialSelectedItem={EXTRACTION_FIELD_TYPES[0]}
                items={EXTRACTION_FIELD_TYPES}
                itemToString={(type) => (type ? type.name : '')}
                onChange={({ selectedItem }) => setNewExtractionFieldType(selectedItem.id)}
              />
            </Styled.AddNewExtractionFieldNameAndTypeSection>
            <Styled.AddNewExtractionFieldFooter>
              <TextInput
                required
                id="new-extraction-field-prompt"
                autoComplete="off"
                type="text"
                labelText=""
                placeholder="Add prompt - describe the expected outcome"
                value={newExtractionFieldPrompt}
                onChange={(event) => setNewExtractionFieldPrompt(event.target.value)}
              />
              <Button
                kind="tertiary"
                type="button"
                size="md"
                disabled={canAddNewExtractionField()}
                onClick={handleAddNewExtractionField}
              >
                Add
              </Button>
            </Styled.AddNewExtractionFieldFooter>
          </>
        )}
      </Styled.AddNewExtractionFieldContainer>
      {idpExtractionFields.length > 0 && (
        <>
          <Divider />
          <Styled.ExtractionFieldsSectionContainer data-test="extraction-fields-section">
            {idpExtractionFields.map((field) => {
              const { id, name, llmPromptDescription, extractedValue, expectedValue } = field;
              return (
                <Styled.ExtractionFieldContainer key={id}>
                  <TextInput
                    id={`prompt-${name}`}
                    data-test={`prompt-${name}`}
                    autoComplete="off"
                    type="text"
                    labelText={name}
                    value={llmPromptDescription}
                    onChange={(event) => handleLlmPromptDescriptionChange(id, event.target.value)}
                    onBlur={() => onUpdateExtractionField?.(field)}
                  />
                  <TextInput
                    disabled
                    id={`extracted-value-${name}`}
                    data-test={`extracted-value-${name}`}
                    autoComplete="off"
                    type="text"
                    labelText=""
                    helperText={expectedValue ? `Expected output: ${expectedValue}` : ''}
                    placeholder="Extract document to see results"
                    value={extractedValue}
                    invalid={!!expectedValue && !!extractedValue && extractedValue !== expectedValue}
                    invalidText={`Doesn't match expected output: ${expectedValue}`}
                  />
                  <OverflowMenu
                    data-test={`context-menu-${name}`}
                    flipped
                    size="md"
                    aria-label="Extraction field Context Action"
                    aria-haspopup="true"
                    align="left"
                  >
                    <OverflowMenuItem
                      data-test={`context-menu-${name}-edit`}
                      itemText="Edit"
                      onClick={() => openEditExtractionModal(field)}
                    />
                    <OverflowMenuItem
                      data-test={`context-menu-${name}-delete`}
                      isDelete
                      itemText="Delete"
                      onClick={() => handleDeleteExtractionField(id)}
                    />
                  </OverflowMenu>
                </Styled.ExtractionFieldContainer>
              );
            })}
          </Styled.ExtractionFieldsSectionContainer>
          <EditExtractionFieldModal
            isOpen={isEditExtractionFieldModalVisible}
            editingExtractionField={editingExtractionField}
            onSaveChanges={onUpdateExtractionField}
            onCloseModal={() => setIsEditExtractionFieldModalVisible(false)}
          />
        </>
      )}
      <OverrideTestCaseModal onOverrideTestCase={handleOverrideTestCase} />
      <IdpExtractionInProgressWarningModal
        isOpen={isIdpExtractionInProgressWarningModalOpen}
        modalText={`Extraction is in progress for IDP document '${activeExtractionIdpDocument?.name}'. Navigating away will result in a loss of extraction result.`}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </>
  );
};

export default observer(ExtractionFields);
