/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { EntityTable, ShareView } from 'components';
import { NameRenderer } from 'components/EntityTable/CellRenderer';
import { Spinner, Tooltip } from 'primitives';
import { NoAccess, Project } from 'icons';
import { diagramExtensionStore, projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores/index';
import { getAuthorName, getEntityIcon, getEntityLink, getEntityName, getSortingPositionByEntity } from 'utils/helpers';
import { IdpApplicationCreationModal } from 'App/Pages/Project/IdpApplication';

import ProjectHeader from './ProjectHeader';
import Collaborators from './Collaborators';
import ActionButton from './ActionButton';
import getBatchActions from './BatchActions';
import EmptyState from './EmptyState';
import EntityAction from './EntityAction';
import { getFileDate } from './utils';
import * as Styled from './Shared.styled';

const ProjectPage = () => {
  // @ts-expect-error TS2339
  const { slug } = useParams();
  const { project, loading, entities } = projectStore;

  const { batchActions, targetSelector } = getBatchActions();

  useEffect(() => {
    // @ts-expect-error TS2554
    projectStore.init('project', slug.split('--')[0]);

    return () => projectStore.reset();
  }, [slug]);

  return (
    <Fragment>
      <ProjectHeader />
      {loading ? (
        <Spinner fullHeight />
      ) : project.id ? (
        <Fragment>
          <Styled.Container>
            <EntityTable
              action={({ tabIndex }) => (
                // @ts-expect-error TS2322
                <ActionButton label="Create new" onUpload={projectStore.uploadFiles} tabIndex={tabIndex} />
              )}
              // @ts-expect-error TS2322
              batchActions={batchActions}
              // @ts-expect-error TS2741
              emptyState={<EmptyState />}
              columns={[
                {
                  key: 'name',
                  header: 'Name',
                  renderer: NameRenderer,
                  noPadding: true,
                  sortable: true
                },
                {
                  key: 'creator',
                  header: 'Creator',
                  width: '150px',
                  sortable: true
                },
                {
                  key: 'lastChanged',
                  header: 'Last changed',
                  width: '200px',
                  sortable: true
                },
                {
                  key: 'entityAction',
                  header: '',
                  width: '50px',
                  noPadding: true,
                  renderer: (entity) => <EntityAction entity={entity} />
                }
              ]}
              // @ts-expect-error TS2322
              onDrop={hasAccess(project, actions.MODIFY_DIAGRAM) && projectStore.uploadFiles}
              containerLabel="project"
              title={
                <Styled.Title data-test="project-name">
                  <Project width="24" height="24" />
                  <Tooltip title={project.name} showOnlyOnOverflow>
                    <span className="overflow-ellipsis">{project.name}</span>
                  </Tooltip>
                </Styled.Title>
              }
              rows={entities.map((entity) => ({
                __sortingPosition: getSortingPositionByEntity(entity),
                id: entity.id,
                name: {
                  name: getEntityName(entity),
                  icon: getEntityIcon(entity),
                  link: getEntityLink(entity),
                  onClick: () => {
                    if (entity.type === 'BPMN') {
                      dedicatedModesStore.resetViewModeIndexForPlay();
                    }
                  }
                },
                creator: getAuthorName(entity),
                lastChanged: getFileDate(entity),
                entityAction: entity
              }))}
            />

            {hasAccess(project, actions.VIEW_COLLABORATORS) && <Collaborators />}

            {diagramExtensionStore.state.isShareModalVisible && (
              <ShareView diagram={diagramExtensionStore.state.diagram} onClose={diagramExtensionStore.hideShareModal} />
            )}

            {targetSelector}
          </Styled.Container>
          <IdpApplicationCreationModal />
        </Fragment>
      ) : (
        <EmptyState
          // @ts-expect-error TS2322
          title="Project not found!"
          description={
            <>
              We couldn't locate the project.
              <br />
              Please check your access or ensure the project exists.
            </>
          }
          icon={<NoAccess />}
        />
      )}
    </Fragment>
  );
};

export default observer(ProjectPage);
