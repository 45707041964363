/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { clustersStore, idpApplicationStore } from 'stores';

import getClustersWithDocumentHandling from './../utils/get-clusters-with-document-handling';
import CreationModal from './CreationModal.common';
import { ClusterSelection } from './../cluster-selection';

function CreationModalSaaS() {
  const clusters = getClustersWithDocumentHandling(clustersStore.clusters);
  const firstAvailableClusterWithDocumentHandling = clusters.find((cluster) => !cluster.disabled);

  const [selectedClusterId, setSelectedClusterId] = useState(firstAvailableClusterWithDocumentHandling?.uuid);

  useEffect(() => {
    // Loading the cluster list, when opening the modal
    if (idpApplicationStore.isCreationModalVisible) {
      clustersStore.loadClusters();
    }
  }, [idpApplicationStore.isCreationModalVisible, clustersStore.loadClusters]);

  useEffect(() => {
    // When the list of clusters gets updated, we verify if the current selected one is still present.
    // If it is not, then we select the first one by default
    if (!clusters?.find((cluster) => cluster.uuid === selectedClusterId)) {
      setSelectedClusterId(firstAvailableClusterWithDocumentHandling?.uuid);
    }
  }, [clusters]);

  return (
    // @ts-expect-error TS2741
    <CreationModal
      clusters={clusters}
      modalContentExtensionSection={
        <ClusterSelection
          clusters={clusters}
          selectedClusterId={selectedClusterId}
          onClusterIdSelection={(clusterId) => setSelectedClusterId(clusterId)}
        />
      }
      getAdditionalParametersWhenFinalizingCreation={() => [selectedClusterId]}
    />
  );
}

export default observer(CreationModalSaaS);
