/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

import { CollaboratorItem as CollaboratorItemComponent } from 'components';

export const UserSuggestions = styled.div`
  position: absolute;
  top: calc(100% - var(--spacing-small));
  left: var(--spacing-small);
  right: var(--spacing-small);
  z-index: 1;
  max-height: 200px;
  overflow: auto;
  border-radius: var(--spacing-tiny);
  background-color: white;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

export const CollaboratorItem = styled(CollaboratorItemComponent)`
  padding: var(--spacing-small);

  &:hover {
    background-color: var(--silver-darken-94);
  }

  ${(props) => props.isSelected && `background-color: var(--silver-darken-94);`};
  ${(props) => (props.isCursorNotAllowed ? `cursor: not-allowed` : `cursor: pointer;`)};
`;

export const Grid = styled.div`
  display: flex;
  position: relative;
`;

export const Action = styled.div`
  margin-left: 10px;
`;

export const WarningContainer = styled.div`
  display: flex;
  gap: 5px;
  padding-top: 5px;
`;

export const WarningMessage = styled.p`
  font-size: 11px;
  font-weight: bold;
`;

export const WarningAction = styled.p`
  font-size: 11px;
  font-weight: normal;
`;

export const EmptyStateTitle = styled.header`
  text-align: center;
  font-weight: bold;
  padding: var(--spacing-small) 0 0 0;
`;

export const EmptyStateContent = styled.div`
  text-align: center;
  padding: 0 var(--spacing-huge) var(--spacing-medium) var(--spacing-huge);
`;

export const EmptyStateSubtitle = styled.div`
  margin-top: var(--spacing-small);
`;

export const EmptyStateParagraph = styled.div`
  margin-top: var(--spacing-small);
  color: var(--grey-lighten-50);
`;
