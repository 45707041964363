/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';

import { Dialog } from 'primitives';
import { diffingStore } from 'stores';

import DiffingDetailsModalContent from './DiffingDetailsModalContent';

const DiffingDetailsModal = () => (
  // @ts-expect-error TS2739
  <Dialog open={diffingStore.state.diffingDetailsShown} onClose={diffingStore.hideDiffingDetails}>
    <DiffingDetailsModalContent />
  </Dialog>
);

export default observer(DiffingDetailsModal);
