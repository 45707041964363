/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';

import { commentService, projectService, trackingService } from 'services';
import { notificationStore, userStore } from 'stores';
import { dedicatedModesStore, detailsPanelTabsStore } from 'App/Pages/Diagram/stores';
import { formatDateToString } from 'utils/date-format';
import localStorage from 'utils/localstorage';

const getLocalStorageSidebarState = () => {
  const storedValue = localStorage.getItem('sidebar_visible');
  const sidebarStateNotInLS = storedValue === null;
  return sidebarStateNotInLS ? true : storedValue === 'true';
};

const DEFAULT_STATE = {
  isSidebarVisible: getLocalStorageSidebarState(),
  suggestions: [],
  comments: [],
  isFetchingComments: false,
  diagram: null,
  current: {
    shape: null
  }
};

class CommentsStore {
  bpmnjs = null;

  state = Object.assign({}, DEFAULT_STATE);

  dedicatedModesStoreDisposer;

  constructor() {
    makeObservable(this, {
      state: observable,
      init: action,
      reset: action,
      toggleSidebar: action,
      setCurrentElement: action,
      setSidebarVisible: action,
      currentElementBusinessObject: computed,
      setModeler: action,
      refreshCommentList: action,
      makePropertiesVisible: action,
      sidebarTitle: computed,
      elementIds: computed,
      comments: computed
    });
  }

  /**
   * Initialiazes this store with the diagram and project and toggles
   * the sidebar depending on the localStorage value.
   *
   * @param {Object} diagram The current diagram.
   * @param {Object} project The current project.
   */
  init(diagram, project) {
    this.state.diagram = diagram;
    this.state.project = project;

    if (project) {
      this.getComments();
      this.getMentionSuggestions();
    }

    this.#listenToDedicatedModeChange();
  }

  #listenToDedicatedModeChange() {
    this.dedicatedModesStoreDisposer = autorun(() => {
      const lsValue = localStorage.getItem('sidebar_visible');
      const sidebarStateNotInLS = lsValue === null;
      const isDesignMode = dedicatedModesStore.isDesignMode;

      runInAction(() => {
        this.state.isSidebarVisible = sidebarStateNotInLS ? !isDesignMode : JSON.parse(lsValue);
      });
    });
  }

  /**
   * Resets this store to its default values.
   */
  reset = () => {
    this.state = Object.assign({}, DEFAULT_STATE);
    this.state.isSidebarVisible = getLocalStorageSidebarState();
    this.bpmnjs = null;

    if (typeof this.dedicatedModesStoreDisposer === 'function') {
      this.dedicatedModesStoreDisposer();
    }
  };

  /**
   * Opens/closes the sidebar, depending on the current
   * state.
   */
  toggleSidebar = () => {
    this.setSidebarVisible(!this.state.isSidebarVisible);
  };

  /**
   * Sets the sidebar visibility and stores the value
   * @param isVisible
   */
  setSidebarVisible = (isVisible) => {
    this.state.isSidebarVisible = isVisible;
    localStorage.setItem('sidebar_visible', isVisible);
  };

  /**
   * Sets the current BPMN element which has
   * been selected by the user.
   *
   * @param {Object} shape The BPMN shape that has been selected.
   */
  setCurrentElement({ shape }) {
    this.state.current.shape = shape;
  }

  get currentElementBusinessObject() {
    return this.state.current && this.state.current.shape && this.state.current.shape.businessObject;
  }

  setModeler = (modeler) => {
    this.bpmnjs = modeler;

    this.linkCommentFromURL();
  };

  /**
   * After a BPMN element has been removed by the user,
   * this function deletes all the comments associated with it.
   *
   * @param {Array} elements The elements who have been removed.
   */
  removeUnusedComments(elements) {
    elements.forEach((element) => {
      commentService
        .destroyByNode(this.state.diagram.id, element.id, userStore.originAppInstanceId)
        .then(() => {
          runInAction(() => {
            this.state.comments = this.state.comments.filter((comment) => {
              if (comment.reference !== element.id) {
                return true;
              }
            });
          });
        })
        .catch(() => notificationStore.showError('Yikes! Could not delete your comments, please try again later.'));
    });
  }

  /**
   * Updates the comment list after a comment has been added,
   * updated or removed.
   *
   * @param {Object} changedComment
   */
  refreshCommentList = (changedComment, type) => {
    switch (type) {
      case 'COMMENT_ADDED':
        this.state.comments = [...this.state.comments, changedComment];
        break;

      case 'COMMENT_EDITED':
        this.state.comments = this.state.comments.map((comment) => {
          if (comment.id === changedComment.id) {
            return changedComment;
          }

          return comment;
        });
        break;

      case 'COMMENT_REMOVED':
        this.state.comments = this.state.comments.filter((comment) => comment.id !== changedComment.id);
    }
  };

  /**
   * Make the sidebar visible and select the properties tab,
   * if it is available in the current view.
   */
  makePropertiesVisible() {
    if (dedicatedModesStore.isDesignMode) {
      return;
    }

    detailsPanelTabsStore.switchTab(detailsPanelTabsStore.TABS.PROPERTIES);
    this.state.isSidebarVisible = true;
  }

  /**
   * Checks whether a BPMN element has comments or not.
   *
   * @param {Object} element The BPMN element.
   * @return {Boolean}
   */
  hasComments(element) {
    return this.state.comments.some((comment) => {
      return comment.reference === element;
    });
  }

  /**
   * Loads the project collaborators which in return will be used
   * for mention suggestions in the comment section.
   */
  getMentionSuggestions() {
    projectService
      .fetchMentionSuggestions(this.state.project.id)
      .then((suggestions) => {
        runInAction(() => {
          this.state.suggestions = suggestions
            .filter((suggestion) => suggestion.id !== userStore.userId)
            .map((suggestion) => ({
              ...suggestion,
              isSelected: false
            }));
        });
      })
      .catch(() => notificationStore.showError('Yikes! Could not get project collaborators, please try again later.'));
  }

  /**
   * Loads the comments from the backend and stores
   * them in the state.
   */
  getComments() {
    runInAction(() => (this.state.isFetchingComments = true));

    commentService
      .fetchByDiagramId(this.state.diagram.id)
      .then((comments) =>
        runInAction(() => {
          this.state.comments = comments;
        })
      )
      .catch(() => notificationStore.showError('Yikes! Could not get the comments, please try again later.'))
      .finally(() => runInAction(() => (this.state.isFetchingComments = false)));
  }

  linkCommentFromURL() {
    const url = new URL(location.href);

    if (url.searchParams.has('comment')) {
      const comment = this.state.comments.find((comment) => comment.id === url.searchParams.get('comment'));

      if (comment) {
        if (comment.reference) {
          const element = this.bpmnjs.get('elementRegistry').get(comment.reference);

          if (element) {
            this.bpmnjs.get('selection').select(element);
          }
        }

        if (!this.state.isSidebarVisible) {
          runInAction(() => (this.state.isSidebarVisible = true));
        }
      }
    }
  }

  /**
   * Persists a new comment in the database and refreshes
   * the comment state.
   *
   * @param {String} content The comment's content.
   */
  createComment = (content) => {
    const { diagram, current } = this.state;

    commentService
      .create({
        fileId: diagram.id,
        content,
        reference: current.shape && current.shape.id,
        // @ts-expect-error TS2353
        originAppInstanceId: userStore.originAppInstanceId
      })
      .then((comment) => this.refreshCommentList(comment, 'COMMENT_ADDED'))
      .catch(() => notificationStore.showError('Yikes! Could not add your comment, please try again later.'));

    // Get all previous comments.
    const comments = this.comments.filter((comment) => typeof comment == 'object');

    // Get previous commenters, e.g. collaborators that have made a
    // comment prior to the current one. This doesn't include comments from the currently
    // logged-in user.
    const commenters = new Set(
      comments.filter((comment) => comment.author.id !== userStore.userId).map((comment) => comment.author.id)
    );

    trackingService.trackAddComment({
      file: diagram,
      elementType: current?.shape?.type || 'process',
      mentions: (content.match(new RegExp('@', 'g')) || []).length,
      previousCommenters: commenters.size,
      previousComments: comments.length
    });
  };

  /**
   * Updates and persists an existing comment in the database
   * and refreshes the comment state.
   *
   * @param {Object} comment The existing comment.
   * @param {String} [comment.content] The comment's content.
   * @param {String} [comment.commentId] The comment's ID.
   */
  updateComment({ content, commentId }) {
    commentService
      .update(commentId, {
        content,
        // @ts-expect-error TS2353
        originAppInstanceId: userStore.originAppInstanceId
      })
      .then((comment) => this.refreshCommentList(comment, 'COMMENT_EDITED'))
      .catch(() => notificationStore.showError('Yikes! Could not update your comment, please try again later.'));
  }

  /**
   * Removes an existing comment from the database.
   *
   * @param {Object} comment The existing comment.
   */
  removeComment(comment) {
    commentService
      .destroy(comment.id, userStore.originAppInstanceId)
      .then(() => this.refreshCommentList(comment, 'COMMENT_REMOVED'))
      .catch(() => notificationStore.showError('Yikes! Could not delete your comment, please try again later.'));
  }

  /**
   * Returns the sidebar title, which is either the selected element's name, its type (with additional spaces added),
   * or the diagram name.
   *
   * @returns {String}
   */
  get sidebarTitle() {
    const { shape } = this.state.current;

    if (shape) {
      if (shape.businessObject.name) {
        return shape.businessObject.name;
      }

      return shape.type.replace('bpmn:', '').replace(/(\B[A-Z])/g, ' $1');
    }

    return (this.state.diagram && this.state.diagram.name) || '';
  }

  /**
   * Returns an array with BPMN elements that either
   * have a comment or are currently selected.
   *
   * This list is used to render the React Portals.
   *
   * @returns {Array}
   */
  get elementIds() {
    const { comments } = this.state;
    const ids = [];

    if (comments) {
      comments.forEach((comment) => {
        if (!ids.includes(comment.reference) && comment.reference) {
          ids.push(comment.reference);
        }
      });
    }

    return ids;
  }

  get currentSelectedElementId() {
    return this.state.current?.shape?.id;
  }

  /**
   * Returns an array of comments, based on the currently
   * selected element and grouped by date.
   *
   * @returns {Array}
   */
  get comments() {
    const sections = [];

    if (this.state.comments) {
      this.state.comments.forEach((comment) => {
        if (this.state.current.shape) {
          if (comment.reference !== this.state.current.shape.id) {
            return;
          }
        } else if (!this.state.current.shape && comment.reference) {
          return;
        }

        const title = formatDateToString(comment.created);

        if (!sections.includes(title)) {
          sections.push(title);
        }

        sections.push(comment);
      });
    }

    return sections;
  }
}

export default new CommentsStore();
