/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { clustersStore, processApplicationStore } from 'stores';

import CreationModal from './CreationModal.common';
import DefaultClusterSelection from './DefaultClusterSelection';

function CreationModalSaaS() {
  const devClusters = clustersStore.devClusters;

  const [selectedClusterId, setSelectedClusterId] = useState(devClusters[0]?.uuid);

  useEffect(() => {
    // Loading the cluster list, when opening the modal
    if (processApplicationStore.isCreationModalVisible) {
      clustersStore.loadClusters();
    }
  }, [processApplicationStore.isCreationModalVisible, clustersStore.loadClusters]);

  useEffect(() => {
    // When the list of clusters gets updated, we verify if the current selected one is still present.
    // If it is not, then we select the first one by default
    if (!devClusters.find((cluster) => cluster.uuid === selectedClusterId)) {
      setSelectedClusterId(devClusters[0]?.uuid);
    }
  }, [devClusters]);

  return (
    <CreationModal
      modalContentExtensionSection={
        <DefaultClusterSelection
          clusters={devClusters}
          selectedClusterId={selectedClusterId}
          onClusterIdSelection={(clusterId) => setSelectedClusterId(clusterId)}
        />
      }
      getAdditionalParametersWhenFinalizingCreation={() => [selectedClusterId]}
      // @ts-expect-error TS2322
      onModalClose={() => setSelectedClusterId(devClusters[0]?.uuid)}
    />
  );
}

export default observer(CreationModalSaaS);
