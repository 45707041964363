/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

const deploymentStates = {
  IDLE: 'idle',
  DEPLOYING: 'deploying',
  DEPLOYMENT_FAILED: 'deployment-failed',
  DEPLOYMENT_FAILED_NOTIFICATION: 'deployment-failed-notification',
  RUNNING: 'running',
  RUN_FAILED: 'deployment-failed',
  RUN_FAILED_NOTIFICATION: 'deployment-failed-notification'
};

export default deploymentStates;
