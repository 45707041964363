/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, CodeSnippet } from '@carbon/react';

import config from 'utils/config';
import { organizationStore } from 'stores';

import * as Styled from './RbaResourceDeployedModal.styled';

export default function RbaResourceDeployedModal({ open, onClose, processId, decisionIds }) {
  const isDMN = decisionIds?.length > 0;
  const title = isDMN ? 'DMN' : 'Process';
  const modelKind = isDMN ? 'decision' : 'process';
  const label = `${modelKind.charAt(0).toUpperCase() + modelKind.slice(1)} ID`;
  const snippetSection = isDMN ? (
    <Styled.MultipleSnippets>
      {decisionIds?.map((decisionId) => (
        <CodeSnippet key={decisionId} type="single" copyButtonDescription="Copy">
          {decisionId}
        </CodeSnippet>
      ))}
    </Styled.MultipleSnippets>
  ) : (
    <CodeSnippet type="single" copyButtonDescription="Copy">
      {processId}
    </CodeSnippet>
  );

  return (
    // @ts-expect-error TS2769
    <Styled.Dialog open={open} onClose={onClose}>
      <Styled.Header>
        <Styled.Title>{`${title} successfully deployed.`}</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <div>
          Your organization has enabled resource-based authorizations. As a result, you need to grant access to other
          org members to view the {`${modelKind} model`}. Please copy the {modelKind} ID and proceed to Console.
        </div>
        <div>
          <label>{label}</label>
          {snippetSection}
        </div>
      </Styled.Content>
      <Styled.Footer>
        <Button
          variant="primary"
          onClick={() => {
            window.open(
              `https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/management/users`,
              '_blank'
            );
          }}
        >
          Grant resource access in Console
        </Button>
      </Styled.Footer>
    </Styled.Dialog>
  );
}
