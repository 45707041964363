/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { Input } from 'primitives';
import { publicationStore } from 'App/Pages/Diagram/stores';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';

import StartForm from './StartForm';
import * as Styled from './AddVariable.styled';

const AddVariable = ({ payload, setPayload, isValidPayload, disabled = false }) => {
  // @ts-expect-error TS2339
  const startEventWithConflicts = formLinkStore.startEventHasFormConflicts;
  const formContent = formLinkStore.startEventFormContent;
  const shouldShowStartForm = publicationStore.isStartEventAttachedToForm && formContent;

  useEffect(() => {
    return () => {
      setPayload('');
    };
  }, []);

  return shouldShowStartForm ? (
    <>
      <Styled.SectionHeader>Start form</Styled.SectionHeader>
      {!startEventWithConflicts && formContent && (
        <Styled.Description>
          Fill out the form to specify the process input via the start event. Read more about{' '}
          <a
            href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/run-or-publish-your-process/#publish-via-a-public-form"
            target="_blank"
            rel="noreferrer"
          >
            start forms
          </a>
          .
        </Styled.Description>
      )}
      <StartForm setPayload={setPayload} />
    </>
  ) : (
    <>
      <Styled.SectionHeader>Add variables (optional)</Styled.SectionHeader>
      <Styled.Description>
        Optionally specify data to add to the newly created instance. Variables can be accessed inside the process
        instance. Read more about{' '}
        <a href="https://docs.camunda.io/docs/product-manuals/concepts/variables" target="_blank" rel="noreferrer">
          variables
        </a>
        .
      </Styled.Description>
      <Input
        // @ts-expect-error TS2322
        multiline
        grow
        label="Variables"
        // @ts-expect-error TS2769
        addon={<Styled.HelperText $hasError={!isValidPayload}>JSON format</Styled.HelperText>}
        value={payload}
        onChange={(evt) => setPayload(evt.target.value)}
        error={!isValidPayload}
        disabled={disabled}
        placeholder='Example: {"orderNumber": "A12BH98", "date": "2020-10-15", "amount": 185.34}'
      />
    </>
  );
};

export default observer(AddVariable);
