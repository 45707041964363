/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  processApplicationStore,
  diagramExtensionStore,
  notificationStore,
  projectStore,
  idpApplicationStore
} from 'stores';
import { IconButton } from 'primitives';
import { Dropdown, TargetSelector } from 'components';
import { fileService, trackingService } from 'services';
import { Dots } from 'icons';
import {
  getExportType,
  isBPMN,
  isDiagram,
  isFile,
  isIdpApplication,
  isIdpProject,
  isMainProcess,
  isProcessApplication
} from 'utils/helpers';
import hasAccess, { actions } from 'utils/user-access';
import { FOLDER, IDP_APPLICATION, PROCESS_APPLICATION } from 'utils/constants';

export default function EntityAction({ entity }) {
  const history = useHistory();
  const { project, folder, isFolder, fromProcessApplicationId, fromIdpApplicationId } = projectStore;
  const { processApplication } = processApplicationStore;
  const { idpApplication } = idpApplicationStore;
  const [anchorEl, setAnchorEl] = useState(null);
  const [moveAnchorEl, setMoveAnchorEl] = useState(null);

  const handleDelete = () => {
    setAnchorEl(null);

    projectStore.deleteEntities(entity);
  };

  const handleDuplicate = () => {
    setAnchorEl(null);

    projectStore.duplicateFiles(entity);
  };

  const handleDownload = async () => {
    setAnchorEl(null);

    const fileSize = await projectStore.downloadEntities([entity]);
    if (fileSize) {
      let from = 'project';
      if (fromProcessApplicationId) {
        from = 'process-application';
      } else if (isFolder) {
        from = 'folder';
      }

      trackingService.trackFileExport({
        fileId: entity.id,
        fileTypeKey: entity.type,
        // @ts-expect-error TS2332
        exportType: getExportType(entity.type),
        // @ts-expect-error TS2332
        from,
        fileCount: isFile(entity) ? 1 : 0,
        folderCount: !isFile(entity) ? 1 : 0,
        fileSize
      });
    }
  };

  const handleMove = async (target) => {
    setAnchorEl(null);
    setMoveAnchorEl(null);

    if (target.projectId) {
      const redirectTarget = isProcessApplication(target) ? 'process-applications' : 'folders';
      if (await projectStore.moveEntities(entity, target.projectId, target.id)) {
        history.push(`/${redirectTarget}/${target.id}`);
      }
    } else {
      if (await projectStore.moveEntities(entity, target.id, undefined)) {
        history.push(`/projects/${target.id}`);
      }
    }
  };

  const handleShare = () => {
    setAnchorEl(null);
    fileService
      .fetchById(entity.id)
      .then((diagram) => {
        diagramExtensionStore.setDiagram(diagram);
        diagramExtensionStore.showShareModal();
      })
      .catch(() => notificationStore.showError('Could not get the diagram information.'));
  };

  const handleUpdateMainProcess = async () => {
    setAnchorEl(null);

    await processApplicationStore.reassignMainProcess(entity.id);
  };

  const items = [];

  const entityCanBeReassignedAsMainProcess =
    hasAccess(project, actions.MODIFY_DIAGRAM) && fromProcessApplicationId && isBPMN(entity) && !isMainProcess(entity);
  if (entityCanBeReassignedAsMainProcess) {
    items.push(
      // @ts-expect-error TS2739
      <Dropdown.ListItem key="update-main-process" onClick={handleUpdateMainProcess} data-test="update-main-process">
        Reassign main process
      </Dropdown.ListItem>
    );
  }

  if (hasAccess(project, actions.SHARE_DIAGRAM) && isDiagram(entity)) {
    items.push(
      // @ts-expect-error TS2739
      <Dropdown.ListItem key="share-diagram" onClick={handleShare} data-test="share-diagram">
        Share or embed
      </Dropdown.ListItem>
    );
  }

  if (hasAccess(project, actions.VIEW_PROJECT) && !isIdpApplication(entity) && !isIdpProject(entity)) {
    items.push(
      // @ts-expect-error TS2739
      <Dropdown.ListItem key="download-file" onClick={handleDownload} data-test="download-file">
        Download
      </Dropdown.ListItem>
    );
  }

  if (hasAccess(project, actions.MODIFY_DIAGRAM)) {
    if (isFile(entity)) {
      items.push(
        // @ts-expect-error TS2739
        <Dropdown.ListItem key="duplicate-diagram" onClick={handleDuplicate} data-test="duplicate-diagram">
          Duplicate
        </Dropdown.ListItem>
      );
    }

    if (!isMainProcess(entity) && !isIdpProject(entity)) {
      let invalidTargetIds = [];
      let startingPoint;
      if (fromProcessApplicationId) {
        invalidTargetIds = [entity.id, fromProcessApplicationId];
        startingPoint = {
          ...processApplication,
          type: FOLDER,
          folderType: PROCESS_APPLICATION
        };
      } else if (fromIdpApplicationId) {
        invalidTargetIds = [entity.id, fromIdpApplicationId];
        startingPoint = {
          ...idpApplication,
          type: IDP_APPLICATION
        };
      } else if (isFolder) {
        invalidTargetIds = [entity.id, folder.id];
        startingPoint = folder;
      } else {
        invalidTargetIds = [entity.id, project.id];
        startingPoint = project;
      }

      items.push(
        <Fragment key="move-diagrams">
          {/* @ts-expect-error TS2739 */}
          <Dropdown.ListItem
            hasSubDropdown
            data-test="move-diagram"
            onClick={(evt) => setMoveAnchorEl(moveAnchorEl ? null : evt.currentTarget)}
          >
            Move
          </Dropdown.ListItem>
          <TargetSelector
            anchorEl={moveAnchorEl}
            open={Boolean(moveAnchorEl)}
            startingPoint={startingPoint}
            invalidTargetIds={invalidTargetIds}
            selectedEntities={[entity]}
            onSubmit={handleMove}
          />
        </Fragment>
      );
    }
  }

  if (hasAccess(project, actions.DELETE_DIAGRAM) && !isMainProcess(entity)) {
    items.push(
      // @ts-expect-error TS2739
      <Dropdown.ListItem key="delete-diagram" onClick={handleDelete} data-test="delete-diagram">
        Delete
      </Dropdown.ListItem>
    );
  }

  if (items.length > 0) {
    return (
      <Fragment>
        <IconButton
          // @ts-expect-error TS2769
          $isActive={Boolean(anchorEl)}
          aria-label="Entity Context Action"
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          data-test="entity-context-dropdown"
          $noRadius
        >
          <Dots />
        </IconButton>

        <Dropdown
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setMoveAnchorEl(null);
          }}
        >
          {items}
        </Dropdown>
      </Fragment>
    );
  }

  return null;
}
