/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Dropdown } from '@carbon/react';

export const DocumentNavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0 0 0;
`;

export const PageNavigationContainer = styled.div`
  padding-left: 5px;
`;

export const PdfViewerHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
`;

export const DocumentDropdown = styled(Dropdown)`
  max-width: 400px;
`;
