/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { runInAction } from 'mobx';

import { trackingService } from 'services';

export default function useViewModes(milestoneStore) {
  const VIEW_MODES = {
    VISUAL: 0,
    CODE: 1
  };
  const [view, setView] = useState(VIEW_MODES.VISUAL);

  const reselectAndApplyDiffing = () => {
    milestoneStore.select([milestoneStore.state.selection.primary], false);
  };

  const switchView = (viewModeIndex) => {
    milestoneStore.setIsCodeView(viewModeIndex === VIEW_MODES.CODE);

    const isDiffingNotSupported = milestoneStore.isDMN && viewModeIndex === VIEW_MODES.VISUAL;
    if (isDiffingNotSupported) {
      runInAction(() => {
        milestoneStore.state.isDiffingEnabled = false;
        milestoneStore.state.selection.secondary = null;
      });
    } else {
      milestoneStore.restoreDiffingUserPreferenceFromLS();
      reselectAndApplyDiffing();
    }

    setView(viewModeIndex);

    trackingService.trackDiagramModeChange(
      milestoneStore.state?.diagram?.id,
      milestoneStore.state?.diagram?.type,
      viewModeIndex === VIEW_MODES.VISUAL ? 'version-visual-view' : 'version-code-view',
      milestoneStore.state?.project?.permissionAccess?.toLowerCase()
    );
  };

  return { view, switchView, VIEW_MODES };
}
