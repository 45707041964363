/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { WithExternalLinkIcon } from 'primitives';

import * as Styled from './Notification.styled';

export default function Notification({ message, link, linkText }) {
  return (
    <div>
      <div>{message}</div>
      {link && (
        <Styled.Link href={link} target="_blank" className="bold">
          {/* @ts-expect-error TS2739 */}
          <WithExternalLinkIcon label={linkText} />
        </Styled.Link>
      )}
    </div>
  );
}
