/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link, TextInput, InlineNotification } from '@carbon/react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { notificationStore, processApplicationStore } from 'stores';
import { milestoneService, tracingService } from 'services';
import { Spinner } from 'primitives';

import * as Styled from './ProcessApplicationVersionTagModal.styled';

export default function ProcessApplicationVersionTagModal({
  onRequestClose,
  open,
  numberOfResources,
  handleVersionCreation,
  modalHeading = 'Create a version',
  preDescriptionNodeElement = null
}) {
  const numberOfResourcesDescription = numberOfResources ? `all resources (${numberOfResources})` : 'all resources';

  const [processAppVersionTag, setProcessAppVersionTag] = useState('');
  const [placeholder, setPlaceholder] = useState('v1.0');
  const [loadingMilestones, setLoadingMilestones] = useState(false);

  const hasValidVersion = processAppVersionTag.trim().length;

  const onRequestSubmit = () => {
    if (hasValidVersion) {
      handleVersionCreation(processAppVersionTag)
        .then(() => notificationStore.showSuccess('New process application version added to history.'))
        .catch(() =>
          notificationStore.showError(
            'Yikes! Could not create the version for this process application. Please try again later.'
          )
        );
      setProcessAppVersionTag('');
      onRequestClose();
    }
  };

  useEffect(() => {
    const fetchMilestoneName = async () => {
      if (open) {
        setLoadingMilestones(true);
        try {
          const fileId = processApplicationStore.processApplication.mainProcessFileId;
          const milestoneName = await milestoneService.getFirstMilestoneNameByFileId(fileId);

          if (milestoneName) {
            setPlaceholder(milestoneName);
          }
        } catch (ex) {
          notificationStore.showError(
            'Could not fetch the versions for this process application. Please try again later.'
          );
          tracingService.traceError(ex, 'Failed to fetch versions for process application');
        } finally {
          setLoadingMilestones(false);
        }
      }
    };

    fetchMilestoneName();
  }, [open]);

  return createPortal(
    <Styled.ProcessApplicationVersionTagModal
      open={open}
      modalHeading={modalHeading}
      primaryButtonText="Create"
      primaryButtonDisabled={!hasValidVersion}
      secondaryButtonText="Cancel"
      onRequestSubmit={onRequestSubmit}
      onRequestClose={onRequestClose}
    >
      {loadingMilestones ? (
        <Spinner />
      ) : (
        <Styled.ModalContent>
          <Styled.VersionTagDescription>
            {preDescriptionNodeElement}
            <span>
              Name your version with a version tag to track it through the development lifecycle and ensure the right
              version is called.
            </span>
            <Link
              href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#versioning"
              target="_blank"
            >
              Learn more
            </Link>
          </Styled.VersionTagDescription>

          <TextInput
            data-modal-primary-focus
            id="version-tag"
            labelText="Version tag"
            placeholder={placeholder}
            value={processAppVersionTag}
            onChange={(event) => {
              setProcessAppVersionTag(event.target.value);
            }}
          />

          <InlineNotification kind="info" title="Version creation" lowContrast hideCloseButton>
            <label>
              Versions will be created for <strong>{numberOfResourcesDescription}</strong> in this process application.
            </label>
          </InlineNotification>
        </Styled.ModalContent>
      )}
    </Styled.ProcessApplicationVersionTagModal>,
    document.body
  );
}
