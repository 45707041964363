/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

import { IconButton } from 'primitives';

export const COMPONENT_HEIGHT = 345;
export const COMPONENT_WIDTH = 300;
export const COMPONENT_HEADER_HEIGHT = 40;
export const COMPONENT_ITEM_HEIGHT = 36;
export const SPACING = 4;

export const Container = styled.div`
  position: absolute;
  z-index: var(--z-dropdown);
`;

export const Description = styled.p`
  padding: 8px 20px;
  font-weight: 500;
  color: var(--grey-darken-23);
  font-size: 14px;
  border-bottom: 1px solid var(--silver-darken-87);
`;

export const Wrapper = styled.div`
  width: ${COMPONENT_WIDTH}px;
  height: ${COMPONENT_HEIGHT}px;
  display: flex;
  flex-direction: column;
  background: var(--silver-base-98);
  border-radius: var(--spacing-tiny);
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px 0px;
`;

export const Header = styled.div`
  height: ${COMPONENT_HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: 0 21px;
  border-bottom: 1px solid var(--silver-darken-87);
  background: var(--cm-color-white-base);
`;

export const BackButton = styled.button`
  border-radius: 50%;
  border: none;
  background-color: var(--silver-darken-94);
  padding: 0;
  margin: 0 8px 0 -12px;
  cursor: pointer;
  color: var(--grey-lighten-50);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not([disabled]):hover {
    background-color: var(--silver-darken-87);
  }

  &:not([disabled]):active {
    background-color: var(--silver-darken-80);
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const Title = styled.h3`
  font-weight: 500;
  color: var(--grey-darken-23);
  font-size: 14px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Body = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 5px 0;
  background: var(--cm-color-white-base);
`;

export const Message = styled.p`
  margin: 10px 20px;
  font-size: 14px;
`;

export const ConfirmLinkMessage = styled(Message)`
  font-size: 13px;
`;

export const Code = styled.code`
  background-color: var(--silver-darken-94);
  border-radius: 4px;
  padding: 3px 5px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--grey-darken-23);
  cursor: default;
  background-color: transparent;
  border: none;
  margin: 0;
  font: inherit;
  height: ${COMPONENT_ITEM_HEIGHT}px;
  padding: 0 7px 0 20px;
  border: 1px solid transparent;
  text-align: left;

  > svg {
    margin-right: 10px;
    flex-shrink: 0;
  }

  > button {
    opacity: 0;
  }

  &:hover > button {
    opacity: 1;
  }

  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return `
        pointer-events: none;
        color: var(--grey-lighten-56);
      `;
    }
  }}

  ${({ $isSelected, $isStatic }) => {
    if ($isSelected) {
      return `
        background-color: var(--silver-darken-94);
        border-radius: 3px;
        border: 1px solid var(--cds-blue-60);
        color: var(--grey-darken-23);

        > button {
          opacity: 1;
        }
      `;
    } else if (!$isStatic) {
      return `
        &:hover {
          background-color: var(--silver-darken-94);
        }
      `;
    }
  }}
`;

export const ItemName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`;

export const ItemButton = styled.button`
  border-radius: 50%;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0 0 0 auto;
  cursor: pointer;
  color: var(--grey-lighten-50);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${({ $isSelected }) => {
    if ($isSelected) {
      return `
        background-color: var(--silver-darken-94);
        color: var(--grey-darken-23);

        &:hover {
          background-color: rgba(0, 0, 0, .07);
        }

        &:focus {
          background-color: rgba(0, 0, 0, .15);
        }
      `;
    } else {
      return `
        &:hover {
          background-color: rgba(0, 0, 0, .07);
        }

        &:focus {
          background-color: rgba(0, 0, 0, .15);
        }
      `;
    }
  }}
`;

export const NewItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 7px;

  > button {
    margin-left: 5px;
  }
`;

export const Footer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid var(--silver-darken-87);

  > div:last-child {
    margin-left: 20px;
  }
`;

export const FooterLeft = styled.div`
  margin-right: auto;
`;

export const CreateButton = styled(IconButton)`
  margin-right: auto;
`;

export const InfoMessage = styled.div`
  padding: 5px 20px;
  color: var(--grey-lighten-50);
  font-size: 13px;
  border-top: 1px solid var(--silver-darken-87);
  background: var(--cm-color-white-base);
`;
