/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { userStore, organizationStore } from 'stores';
import { trackingService } from 'services';
import { WithExternalLinkIcon } from 'primitives';

export default ({ showOrganizations }) => {
  const { isAuthenticated } = userStore;
  const { currentOrganizationInfo, availableOrganizations } = organizationStore;

  const [elements, setElements] = useState([]);
  const [customElements, setCustomElements] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated && showOrganizations && availableOrganizations && currentOrganizationInfo) {
      if (showOrganizations) {
        setElements(
          organizationStore.availableOrganizations
            .filter((organization) => organization.uuid !== currentOrganizationInfo.uuid)
            .map((organization) => ({
              key: organization.uuid,
              label: organization.name,
              onClick: async () => {
                trackingService.trackTopBarAction('select', 'organizations');
                history.push(`/org/${organization.uuid}`);
              }
            }))
        );

        setCustomElements({
          activeOrganization: {
            orgName: currentOrganizationInfo.name,
            activeLabel: 'Active Organization',
            otherLabel: 'Other Organizations',
            action: {
              // @ts-expect-error TS2741
              label: <WithExternalLinkIcon label="Manage" iconColor="var(--cds-link-primary, #0f62fe)" />,
              onClick: () => {
                setIsOpen(false);
                trackingService.trackTopBarAction('manage', 'organizations');
                window.open(organizationStore.organizationManagementPageUrl, '_blank');
              }
            }
          }
        });
      }
    }
  }, [isAuthenticated, availableOrganizations, currentOrganizationInfo]);

  return availableOrganizations?.length > 0
    ? {
        type: 'org',
        isOpen,
        ariaLabel: 'Organizations',
        customElements,
        elements
      }
    : undefined;
};
