/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

export const DropTarget = styled.div`
  position: relative;
  display: ${(props) => props.$displayStatus};
  overflow-x: hidden;
  flex: 1;

  ${({ $isDragging, $text }) => {
    if ($isDragging) {
      return `
        overflow: hidden;

        &::before {
          content: '${$text}';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(248, 248, 248, 0.66);
          border: 2px dashed var(--grey-lighten-56);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2; // must be > 1 because of the .bio-properties-panel-header defined in the properties-panel
          box-sizing: border-box;
        }
      `;
    }
  }}
`;
