/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Layer } from '@carbon/react';

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 32px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const Section = styled(Layer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ $orientation }) => ($orientation === 'left' ? '16px 20px 16px 20px' : '0 20px 16px 20px')};
  background-color: white;
  gap: 10px;
  overflow-y: auto;
  max-height: ${({ $isExtractionStepNotificationExists }) => ($isExtractionStepNotificationExists ? '62vh' : '69vh')};
`;

export const ActionableNotificationContainer = styled.div`
  padding-bottom: 20px;

  .cds--actionable-notification,
  .cds--inline-notification {
    /*
     * This is added to allow notification to span the entire width of the screen.
     */
    max-inline-size: 100%;
  }
`;

export const LoaderContainer = styled.div`
  margin: 0 auto;
`;
