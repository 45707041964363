/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const NotificationContainer = styled.div`
  padding-bottom: 20px;

  .cds--actionable-notification,
  .cds--inline-notification {
    /*
     * This is added to allow notification to span the entire width of the screen.
     */
    max-inline-size: 100%;
  }
`;
