/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-expect-error TS2307
import BPMNModdle from 'bpmn-moddle';
import modelerModdle from 'modeler-moddle/resources/modeler.json';
import zeebeModdle from 'zeebe-bpmn-moddle/resources/zeebe.json';
import DMNModdle from 'dmn-moddle';

import { BPMN, DMN } from 'utils/constants';

const moddles = {
  [BPMN]: new BPMNModdle({ zeebe: zeebeModdle, modeler: modelerModdle }),
  [DMN]: new DMNModdle()
};

export default async function (xml, type = BPMN) {
  if (!xml) {
    throw new Error('You need to pass a xml string');
  }
  const { rootElement } = await moddles[type].fromXML(xml);

  if (!rootElement) {
    throw new Error('Unable to parse definitions from passed xml string.');
  }

  return rootElement;
}
