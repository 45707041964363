/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useMemo, Fragment } from 'react';

import { IconButton } from 'primitives';
import { Dropdown } from 'components';
import { userStore, homeStore } from 'stores';
import { Dots } from 'icons';
import hasAccess, { actions } from 'utils/user-access';
import currentUserIsACollaborator from 'utils/currentUserIsACollaborator';

export default function EntityAction({ project }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const hasOtherAdmins = useMemo(() => {
    return (
      project.collaborators?.filter(
        (collaborator) => collaborator.permissionAccess == 'ADMIN' && collaborator.id != userStore.userId
      ).length > 0
    );
  }, [project]);

  const isCollaboratorInProject = useMemo(() => {
    return currentUserIsACollaborator(project.collaborators);
  }, [project]);

  const handleDeleteProject = () => {
    setAnchorEl(null);

    homeStore.deleteProjects(project);
  };

  const handleLeaveProject = () => {
    setAnchorEl(null);

    homeStore.leaveProjects(project);
  };

  const items = [];

  if (hasAccess(project, actions.DELETE_PROJECT)) {
    items.push(
      // @ts-expect-error TS2739
      <Dropdown.ListItem key="delete-project" onClick={handleDeleteProject}>
        Delete
      </Dropdown.ListItem>
    );
  }

  if (hasAccess(project, actions.LEAVE_PROJECT) && isCollaboratorInProject && hasOtherAdmins) {
    items.push(
      // @ts-expect-error TS2739
      <Dropdown.ListItem key="leave-project" onClick={handleLeaveProject}>
        Leave project
      </Dropdown.ListItem>
    );
  }

  if (items.length > 0) {
    return (
      <Fragment>
        <IconButton
          // @ts-expect-error TS2769
          $isActive={Boolean(anchorEl)}
          aria-label="Entity Context Action"
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          data-test="entity-context-dropdown"
          $noRadius
        >
          <Dots />
        </IconButton>

        <Dropdown anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {items}
        </Dropdown>
      </Fragment>
    );
  }

  return null;
}
