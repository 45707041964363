/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';

import * as Styled from './Divider.styled';

Styled.Divider.propTypes = {
  // @ts-expect-error TS2353
  variant: PropTypes.oneOf(['big', 'normal', 'none'])
};

Styled.Divider.defaultProps = {
  // @ts-expect-error TS2353
  variant: 'normal'
};

export default Styled.Divider;
