/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import { Stack } from '@carbon/react';
import styled from 'styled-components';

export const Type = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: var(--cds-text-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const Icon = styled.div`
  width: 48px;
  height: 48px;
  padding: ${({ $customIconPadding }) => ($customIconPadding ? $customIconPadding : '15px 10px 10px')};
`;

export const Title = styled(Stack)`
  align-items: center;
`;

export const TagContainer = styled.div`
  margin-left: 60px;
  height: 18px;
  align-self: center;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;
