/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from '@carbon/react';

import Tooltip from 'App/Pages/Diagram/Tooltip';
import { Link as Hyperlink } from 'primitives';
import { Dropdown, TargetSelector } from 'components';
import { commentsStore, currentDiagramStore, notificationStore } from 'stores';
import { selectElementAndFocusField } from 'App/Pages/Diagram/properties-panel-util';
import { folderService, trackingService } from 'services';
import buildSlug from 'utils/buildSlug';
import {
  createOrUpdateExtensionElement,
  removeExtensionElement
} from 'utils/web-modeler-diagram-parser/extension-elements-util';
import * as Styled from 'App/Pages/Diagram/LinkOverlay.styled';

import businessRuleTaskLinkStore from './BusinessRuleTaskLinkStore';

const Overlay = observer(({ element, readOnly }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkedDecisions, setLinkedDecisions] = useState({});
  const [contextDetails, setContextDetails] = useState(null);
  const { project } = currentDiagramStore.state;

  const { isElementLinked } = businessRuleTaskLinkStore;

  const getLinkedDecisions = async () => {
    const decisions = await businessRuleTaskLinkStore.getExistingLinks(element);
    setLinkedDecisions(decisions);
  };

  const getProjectDetails = async () => {
    const updatedProject = await currentDiagramStore.updateProject(project.id);
    setContextDetails(updatedProject);
  };

  const getBrowsableContextDetails = async () => {
    const context = await folderService.fetchById(currentDiagramStore.state.diagram.folderId);
    setContextDetails(context);
  };

  const modeler = currentDiagramStore.modeler;

  const contextPad = modeler.get('contextPad'),
    eventBus = modeler.get('eventBus');

  eventBus.on('contextPad.linkResource', (context) => {
    const { element, originalEvent } = context;

    if (contextPad.isOpen(element)) {
      handleClick(originalEvent);
    }
  });

  useEffect(() => {
    if (anchorEl) {
      if (isElementLinked(element)) {
        getLinkedDecisions();
      } else if (currentDiagramStore.ofProcessApplicationOrFolder) {
        getBrowsableContextDetails();
      } else {
        getProjectDetails();
      }
    }
  }, [anchorEl, element]);

  const trackResourceLinking = (action) => {
    trackingService.trackResourceLinking({
      action,
      resource: 'decision',
      from: 'BPMN'
    });
  };

  const linkDecision = (decisionId) => {
    createOrUpdateExtensionElement(element, 'zeebe:CalledDecision', { decisionId }, modeler);

    focusDecisionIdField();

    notificationStore.showSuccess('Business rule task has been successfully linked.');

    trackResourceLinking('link');

    setAnchorEl(null);
  };

  const unlinkDecision = () => {
    removeExtensionElement(element, 'zeebe:CalledDecision', modeler);

    focusDecisionIdField();

    notificationStore.showSuccess('Business rule task has been unlinked.');

    trackResourceLinking('unlink');

    setAnchorEl(null);
  };

  const focusDecisionIdField = async () => {
    // TODO(philippfromme): layout should be kept in a separate store
    commentsStore.makePropertiesVisible();
    await selectElementAndFocusField({
      modeler: currentDiagramStore.modeler,
      element: element,
      groupId: 'group-calledDecision',
      fieldId: '#bio-properties-panel-decisionId'
    });
  };

  const handleClick = (event) => {
    // directEditing and popupMenu are not available in navigated viewer (used in read-only mode)
    currentDiagramStore.modeler?.get('directEditing')?.complete();
    currentDiagramStore.modeler.get('popupMenu', false)?.close();

    if (!anchorEl) {
      trackResourceLinking('openMenu');
    }

    let target = event.delegateTarget || event.target;

    if (target.closest('.djs-context-pad')) {
      target = target.closest('.djs-context-pad');
    }

    setAnchorEl(anchorEl ? null : target);
  };

  // @ts-expect-error TS2339
  const { links, broken, expression } = linkedDecisions;
  const isCalledDecisionPresent = links?.length > 0 || broken || expression;

  return (
    <Styled.Container>
      <Tooltip label="Link decision">
        <Styled.LinkButton onClick={handleClick} data-test="business-rule-task-link-button" />
      </Tooltip>
      {isElementLinked(element) ? (
        <Dropdown
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          width={360}
          align="left"
          noMargin
          noPadding
        >
          {/* @ts-expect-error TS2769 */}
          <Dropdown.Title noPadding>Calls decision:</Dropdown.Title>
          {isCalledDecisionPresent ? (
            <>
              {links?.length > 0 && (
                <Styled.LinkContainer>
                  <ul>
                    {links?.map((diagram) => (
                      <li key={diagram.id}>
                        <Styled.Link
                          data-test={`linked-diagram-${diagram.id}`}
                          onClick={() => {
                            businessRuleTaskLinkStore.setDecisionIdToOpen(diagram.decision.id);
                            history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
                          }}
                        >
                          {diagram.name} &#x2192; {diagram.decision.name || diagram.decision.id}
                        </Styled.Link>
                      </li>
                    ))}
                  </ul>
                </Styled.LinkContainer>
              )}

              {links?.length > 1 && (
                <Styled.InfoMessage>
                  During execution, the most recently deployed diagram having the linked decision ID will be called.
                </Styled.InfoMessage>
              )}
              {broken && (
                <>
                  <Styled.InfoResourceId>{broken}</Styled.InfoResourceId>
                  <Styled.InfoMessage>
                    A decision with this ID could not be found in the current project.
                  </Styled.InfoMessage>
                </>
              )}
              {expression && (
                <>
                  <Styled.InfoResourceId>{expression}</Styled.InfoResourceId>
                  <Styled.InfoMessage>
                    The decision ID is defined as an{' '}
                    <Hyperlink href="https://docs.camunda.io/docs/components/concepts/expressions/" target="_blank">
                      expression
                    </Hyperlink>{' '}
                    that will be evaluated at runtime.
                  </Styled.InfoMessage>
                </>
              )}

              {!readOnly && (
                <Styled.Footer>
                  <Styled.FooterActions>
                    <Button kind="danger--ghost" size="sm" onClick={unlinkDecision} data-test="unlink-button">
                      Unlink
                    </Button>
                  </Styled.FooterActions>
                </Styled.Footer>
              )}
            </>
          ) : (
            <Styled.Spinner />
          )}
        </Dropdown>
      ) : (
        <>
          {contextDetails && (
            <TargetSelector
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              startingPoint={contextDetails}
              onSubmit={linkDecision}
              action="business-rule-task-link"
              showFiles
              description="Link a decision"
              align="bottom"
            />
          )}
        </>
      )}
    </Styled.Container>
  );
});

export default function BusinessRuleTaskLinkOverlay({ ...props }) {
  const selector = `.djs-overlays[data-container-id="${props.element.id}"] .business-rule-task-menu`;
  const container = document.querySelector(selector);
  if (!container) {
    return null;
  }
  // @ts-expect-error TS2739
  return ReactDOM.createPortal(<Overlay {...props} />, container);
}
