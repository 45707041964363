/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Modal } from '@carbon/react';

export const EditExtractionFieldModal = styled(Modal)`
  .cds--modal-content {
    /*
     * Needed for dropdowns inside the modal to render properly
     * See https://github.com/carbon-design-system/carbon/issues/4684#issuecomment-554090767
     */
    overflow: visible;
  }
`;
