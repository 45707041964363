/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import { InlineLoading } from '@carbon/react';

import * as Styled from './PdfViewer.styled';
import { PdfDocument } from './PdfViewer.styled';

import 'styles/react-pdf.scss';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const PdfViewer = ({ file, pageNumber = 1, scale = 1, onDocumentLoadSuccess, onPageChange }) => {
  const [pdf, setPdf] = useState(null);

  const onLoadSuccess = (pdf) => {
    setPdf(pdf);
    onDocumentLoadSuccess?.({ numPages: pdf.numPages });
  };

  const changePage = (pageNumber) => {
    onPageChange?.(pageNumber);
  };

  return (
    <Styled.Container data-test="pdf-viewer-section">
      {/* Main PDF Viewer */}
      {file && (
        <PdfDocument
          file={file}
          onLoadSuccess={onLoadSuccess}
          loading={
            <Styled.LoaderContainer>
              <InlineLoading status="active" iconDescription="Loading" description="Loading document..." />
            </Styled.LoaderContainer>
          }
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </PdfDocument>
      )}
      {/* Sidebar */}
      {pdf && (
        <Styled.Sidebar>
          {Array.from({ length: pdf.numPages }, (_, index) => (
            <Styled.PageThumbnail
              pdf={pdf}
              key={`Thumbnail-${index + 1}`}
              pageNumber={index + 1}
              width={100}
              onItemClick={() => changePage(index + 1)}
              className={pageNumber === index + 1 ? 'active' : ''}
            />
          ))}
        </Styled.Sidebar>
      )}
    </Styled.Container>
  );
};

export default PdfViewer;
