/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Button } from '@carbon/react';
import { Close } from '@carbon/icons-react';

import { Loader } from 'primitives';
import useFocusTrap from 'hooks/useFocusTrap';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import ClickOutsideListener from 'primitives/ClickOutsideListener/ClickOutsideListener';

import * as Styled from './NonModalDialog.styled';
import usePositioning from './usePositioning';

// TODO(philippfromme): rename modal dialogs and non-modal dialogs according to
// see https://carbondesignsystem.com/patterns/dialog-pattern#modal-dialogs and
// https://carbondesignsystem.com/patterns/dialog-pattern#non-modal-dialogs
export default function NonModalDialog({
  children,
  maxWidth = '750px',
  onClose,
  isSubmitting,
  onSubmit,
  open,
  primaryButtonText = 'Submit',
  primaryButtonDisabled = false,
  selectorPrimaryFocus,
  anchorEl,
  alignment = 'top',
  justify = 'right',
  passiveModal = false
}) {
  const ref = useRef();

  useFocusTrap({ open, ref, selectorPrimaryFocus });

  const onKeyDown = useKeyboardTrap({ onClose, ref });

  const position = usePositioning({
    dialogEl: ref?.current,
    anchorEl,
    alignment,
    justify
  });

  return createPortal(
    <Styled.NonModalDialog
      // @ts-expect-error TS2769
      $isOpen={open}
      $position={position}
      onKeyDown={onKeyDown}
      ref={ref}
      $maxWidth={maxWidth}
      role="dialog"
    >
      {/* @ts-expect-error TS2741 */}
      <ClickOutsideListener insideRef={ref} anchorEl={anchorEl} onClickOutside={onClose} />
      <Styled.Header>
        <Styled.CloseButton>
          <Button
            kind="ghost"
            renderIcon={Close}
            iconDescription="Close"
            tooltipPosition="left"
            hasIconOnly
            onClick={onClose}
            data-test="non-modal-dialog-close"
          />
        </Styled.CloseButton>
      </Styled.Header>
      <Styled.Body>{children}</Styled.Body>
      {!passiveModal && (
        <Styled.Footer>
          {isSubmitting ? (
            <Styled.FooterLoadingContainer>
              {/* @ts-expect-error TS2769 */}
              <Loader size="small" /> Submitting
            </Styled.FooterLoadingContainer>
          ) : (
            <Button kind="primary" onClick={onSubmit} disabled={primaryButtonDisabled}>
              {primaryButtonText}
            </Button>
          )}
        </Styled.Footer>
      )}
    </Styled.NonModalDialog>,
    document.body
  );
}
