/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Dropdown } from 'components';
import { IconButton } from 'primitives';
import { Dots } from 'icons';
import { userStore, projectStore, organizationStore, notificationStore } from 'stores';
import { invitationService, tracingService } from 'services';
import hasAccess, { actions } from 'utils/user-access';
import { getAllRoles } from 'utils/member-roles';
import { isOrganizationInvitePending } from 'utils/helpers';
import currentUserIsACollaborator from 'utils/currentUserIsACollaborator';

import * as Styled from './Collaborators.styled';

export function CollaboratorsAction({ collaborator }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { project, collaborators } = projectStore;
  const { currentOrganizationId } = organizationStore;
  const { isSuperUserModeActive } = userStore;

  const isLoggedInUser = userStore.userId === collaborator.id;
  const admins = collaborators.filter(({ permissionAccess }) => permissionAccess === 'ADMIN');

  const handleDelete = () => {
    setAnchorEl(null);

    projectStore.deleteCollaborator(collaborator);
  };

  const handlePermissionChange = (permissionAccess) => {
    setAnchorEl(null);

    projectStore.updateCollaboratorPermission(collaborator.email, collaborator.iamId, permissionAccess);
  };

  const handleProjectLeave = async () => {
    setAnchorEl(null);

    if (await projectStore.leaveProject(collaborator)) {
      history.push('/');
    }
  };

  const handleResendInvitation = async (organizationId, collaborator) => {
    setAnchorEl(null);

    try {
      await invitationService.resendInvitation(organizationId, collaborator);
      notificationStore.showSuccess('The invitation was successfully sent!');
    } catch (ex) {
      notificationStore.showError('Yikes! Could not resend the invitation. Please try again later.');
      tracingService.traceError(ex, 'Failed to resend invitation');
    }
  };

  const dropdownOptions = [];

  if (hasAccess(project, actions.MODIFY_COLLABORATORS)) {
    if (!isLoggedInUser) {
      dropdownOptions.push(
        // @ts-expect-error TS2739
        <Dropdown.ListItem key="remove" onClick={handleDelete} data-test="remove-collaborator">
          Remove
        </Dropdown.ListItem>
      );

      if (isOrganizationInvitePending(collaborator) && organizationStore.hasGeneralUserCreatePermission) {
        dropdownOptions.push(
          // @ts-expect-error TS2739
          <Dropdown.ListItem
            key="resend"
            onClick={() => {
              handleResendInvitation(currentOrganizationId, collaborator);
            }}
            data-test="resend-collaborator-invitation"
          >
            Resend invitation
          </Dropdown.ListItem>
        );
      }
    }

    if (isSuperUserModeActive || !isLoggedInUser || admins.length > 1) {
      dropdownOptions.push(
        // @ts-expect-error TS2739
        <Dropdown.ListItem key="change-role" data-test="change-role" hasSubDropdown>
          Change role
          <Dropdown.SubDropdown>
            {getAllRoles().map((role) => (
              // @ts-expect-error TS2739
              <Dropdown.ListItem
                onClick={() => handlePermissionChange(role.id)}
                title={role.description}
                key={role.id}
                data-test={`change-permission-${role.id}`}
              >
                <Styled.Icon>{role.icon}</Styled.Icon>
                {role.title}
              </Dropdown.ListItem>
            ))}
          </Dropdown.SubDropdown>
        </Dropdown.ListItem>
      );
    }
  }

  if (isLoggedInUser && currentUserIsACollaborator(collaborators)) {
    if ((collaborator.permissionAccess === 'ADMIN' && admins.length > 1) || collaborator.permissionAccess !== 'ADMIN') {
      dropdownOptions.push(
        // @ts-expect-error TS2739
        <Dropdown.ListItem key="leave" onClick={handleProjectLeave} data-test="leave-project">
          Leave project
        </Dropdown.ListItem>
      );
    }
  }

  if (dropdownOptions.length > 0) {
    return (
      <>
        <IconButton
          // @ts-expect-error TS2769
          $isActive={Boolean(anchorEl)}
          aria-label="Collaborator Context Action"
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          data-test="entity-context-dropdown"
        >
          <Dots />
        </IconButton>

        <Dropdown anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {dropdownOptions}
        </Dropdown>
      </>
    );
  }

  return null;
}

export default observer(CollaboratorsAction);
