/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Linter } from '@camunda/linting';
import { Linter as ModelingLinter } from 'bpmnlint';
import { CloudElementTemplatesLinterPlugin } from 'bpmn-js-element-templates';

// @ts-expect-error TS2307
import bpmnLinterConfig from './.bpmnlintrc';

async function lintDesign(contents) {
  // cf. https://github.com/bpmn-io/bpmnlint#api
  const bpmnLinter = new ModelingLinter(bpmnLinterConfig);

  const reportMap = await bpmnLinter.lint(contents);

  return Object.entries(reportMap)
    .map(([rule, results]) => {
      return results.map((result) => ({ ...result, rule }));
    })
    .flat();
}

async function lintImplement(contents, modeler) {
  const templates = modeler.get('elementTemplates').getAll();

  const linter = new Linter({
    modeler: 'web',
    plugins: [CloudElementTemplatesLinterPlugin(templates), bpmnLinterConfig]
  });

  return await linter.lint(contents);
}

/**
 * @param {object} modeler
 * @param {boolean} isImplementMode - whether to lint for implementation
 *
 * @returns {Promise<Array>} - linting results
 */
export const performLinting = async (modeler, isImplementMode) => {
  const contents = modeler.getDefinitions();

  const results = await (isImplementMode ? lintImplement(contents, modeler) : lintDesign(contents));

  const categories = ['error', 'warn', 'info'];

  // sort based on category precedence
  return results.flat().sort((a, b) => {
    return categories.indexOf(a.category) - categories.indexOf(b.category);
  });
};
