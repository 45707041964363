/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import Copilot from '@camunda/bpmn-copilot-client';

import bpmnCopilotService from 'experiments/ai-features/bpmn-copilot/BpmnCopilotService';
import { tracingService } from 'services';

class BpmnCopilotClient {
  async invoke({ modeler, addNewMilestone, textPrompt }) {
    const copilot = new Copilot({
      bpmnjs: modeler,
      invoke: async (requestBody) => {
        try {
          const jsonResponse = await bpmnCopilotService.invokeBpmnCopilot(requestBody);
          const response = new Response(JSON.stringify(jsonResponse));

          await addNewMilestone();

          return response;
        } catch (error) {
          console.error('Call to BPMN Copilot service failed');
          tracingService.traceError(error, 'Call to BPMN Copilot service failed');
          return Promise.reject(error);
        }
      }
    });

    return copilot.invoke(textPrompt);
  }
}

export default new BpmnCopilotClient();
