/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { FOLDER, PROCESS_APPLICATION, PROJECT } from 'utils/constants';
import { userStore } from 'stores';

import Service from './Service';

class ProjectService extends Service {
  /**
   * Fetches all projects for the current organization.
   *
   * @returns {Promise}
   */
  async fetchByOrganizationId(organizationId, includeDetails = false) {
    const url = `/internal-api/organizations/${organizationId}/projects`;

    const queryParams = new URLSearchParams();
    if (includeDetails) {
      queryParams.append('includeDetails', 'true');
    }
    if (userStore.isSuperUserModeActive) {
      queryParams.append('superUserModeEnabled', 'true');
    }

    const urlWithParams = `${url}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;

    const response = await this.get(urlWithParams);
    return response.map((item) => ({ ...item, type: PROJECT }));
  }

  /**
   * Fetches a single project, including files and folders if requested.
   *
   * @param {Object} payload
   * @param {String} [payload.projectId]
   * @param {Boolean} [payload.includeFiles]
   * @param {Boolean} [payload.includeFolders]
   * @returns {Promise}
   */
  async fetchById({ projectId, includeFiles = false, includeFolders = false }) {
    // @ts-expect-error TS2345
    const queryString = new URLSearchParams({
      includeFiles,
      includeFolders,
      ...(userStore.isSuperUserModeActive ? { superUserModeEnabled: true } : {})
    }).toString();
    const response = await this.get(`/internal-api/projects/${projectId}${queryString ? `?${queryString}` : ''}`);

    return {
      ...response,
      type: PROJECT,
      folders: response.folders?.map((folder) => ({ ...folder, type: FOLDER })),
      processApplications: response.processApplications?.map((processApplication) => ({
        ...processApplication,
        type: PROCESS_APPLICATION
      }))
    };
  }

  fetchCollaborators(projectId) {
    // @ts-expect-error TS2345
    const queryString = new URLSearchParams({
      includeInvitations: true,
      ...(userStore.isSuperUserModeActive ? { superUserModeEnabled: true } : {})
    }).toString();

    return this.get(`/internal-api/projects/${projectId}/collaborators?${queryString}`);
  }

  fetchTemplates(projectId) {
    return this.get(`/internal-api/projects/${projectId}/connector-templates`);
  }

  /**
   * Fetches all files that belong to the given `projectId`.
   *
   * @param {String} projectId
   * @param {String} processId
   * @returns {Promise}
   */
  fetchFilesByProcessId(projectId, processId) {
    return this.get(`/internal-api/projects/${projectId}/files?processId=${processId}`);
  }

  fetchFilesByDecisionId(projectId, decisionId) {
    return this.get(`/internal-api/projects/${projectId}/files?decisionId=${decisionId}`);
  }

  fetchFilesByFormId(projectId, formId) {
    return this.get(`/internal-api/projects/${projectId}/files?formId=${formId}`);
  }

  /**
   * Fetches all user mention suggestions for a project. The mentions
   * are displayed when writing a comment for diagram.
   *
   * @param {String} projectId
   * @returns {Promise}
   */
  fetchMentionSuggestions(projectId) {
    return this.get(`/internal-api/projects/${projectId}/collaborators`);
  }

  /**
   * Creates a new project.
   *
   * @param {Object} payload
   * @param {String} payload.name The new project's name.
   * @returns {Promise}
   */
  create(payload) {
    return this.post(`/internal-api/projects`, payload);
  }

  /**
   * Renames a specific project.
   *
   * @param {String} projectId
   * @param {Object} payload
   * @param {String} payload.name The new project name.
   * @returns {Promise}
   */
  rename(projectId, payload) {
    return this.patch(`/internal-api/projects/${projectId}`, payload);
  }

  /**
   * Removes a specific project.
   *
   * @param {String} projectId
   * @returns {Promise}
   */
  destroy(projectId) {
    return this.delete(`/internal-api/projects/${projectId}`);
  }

  /**
   * Removes a set of projects.
   *
   * @param {String[]} payload
   * @returns {Promise}
   */
  destroyMany(payload) {
    return this.delete(`/internal-api/projects`, payload);
  }

  /**
   * Creates a new user invitation for the given `projectId`.
   *
   * @param {String} projectId The project for the invitation.
   * @param {Object} payload
   * @param {String[]} payload.emails An array of emails to send the invitation to.
   * @param {String} payload.text The optional email body.
   * @param {String} payload.projectAccess The project access that the invited users should have.
   * @returns {Promise}
   */
  createInvitation(projectId, payload) {
    return this.post(`/internal-api/projects/${projectId}/invite`, payload);
  }

  /**
   * Updates the permissions for a specific user, provided by the given `email`.
   *
   * @param {String} projectId The project for which the user's permissions should be changed.
   * @param {Object} payload
   * @param {String} payload.email The email of the account, whose permissions should be changed.
   * @param {String} payload.permissionAccess The new permission access.
   * @returns {Promise}
   */
  updateCollaboratorPermissions(projectId, payload) {
    return this.patch(`/internal-api/projects/${projectId}/collaborators`, payload);
  }

  /**
   * Removes an existing collaborator from a project.
   *
   * @param {String} projectId
   * @param {Object} payload
   * @param {String} payload.email
   * @returns {Promise}
   */
  destroyCollaborator(projectId, payload) {
    return this.delete(`/internal-api/projects/${projectId}/collaborators`, payload);
  }
}

export default new ProjectService();
