/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { createPortal } from 'react-dom';
import { Fragment, useEffect, useReducer } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { is } from 'bpmn-js/lib/util/ModelUtil';

import { Button, Tooltip } from 'primitives';
import { ChevronLeft, ChevronRight, Plus } from 'icons';
import pluralize from 'utils/pluralize';
import {
  isFolder,
  isFolderOrProcessApplication,
  isIdpApplication,
  isProcessApplication,
  isProject
} from 'utils/helpers';
import { FOLDER, PROCESS_APPLICATION } from 'utils/constants';
import { selectedElementStore } from 'App/Pages/Diagram/stores';

import { isLinkAction } from './utils';
import { getInitialState, reducer } from './reducer';
import usePosition from './usePosition';
import useProjectData from './useProjectData';
import useOutsideClick from './useOutsideClick';
import NewItem from './NewItem';
import ItemIcon from './ItemIcon';
import { CreateNewFormButton, FormLinkEmptyState } from './FormLink';
import { BusinessRuleTaskLinkEmptyState, CreateNewDmnButton } from './BusinessRuleTaskLink';
import { CreateNewBpmnButton } from './CallActivityLink';
import * as Styled from './TargetSelector.styled';

const TargetSelector = ({
  align = 'auto',
  anchorEl,
  open,
  startingPoint,
  onSubmit,
  invalidTargetIds = [],
  action = 'move',
  showFiles = false,
  description,
  handleClickOutside = () => {},
  selectedEntities
}) => {
  const containerRef = useOutsideClick(handleClickOutside);
  const [state, dispatch] = useReducer(reducer, getInitialState(startingPoint, action, showFiles, invalidTargetIds));
  const { position, ready } = usePosition(anchorEl, open, align);
  const { fetchChild, fetchParent, createChild, fetchRelatedBPMNDiagrams, fetchRelatedDMNDiagrams, fetchRelatedForms } =
    useProjectData(state, dispatch);

  const { selectedElement } = selectedElementStore;

  const isProjectContext = state.level === 'root';

  const handleSubmit = async () => {
    const target = state.selected || state.current;

    const sourceProjectId = isProject(startingPoint) ? startingPoint.id : startingPoint.projectId;
    const targetProjectId = isProject(target) ? target.id : target.projectId;

    // If the target project is a different one from the source project, we
    // need to confirm the action first.
    if (action === 'link' && state.level !== 'linking-duplicates') {
      const otherBPMNDiagramsWithSameProcessId = await fetchRelatedBPMNDiagrams(target);

      if (otherBPMNDiagramsWithSameProcessId.length) {
        // @ts-expect-error TS2554
        dispatch({
          type: 'OPEN_DUPLICATE_CONFIRMATION',
          payload: otherBPMNDiagramsWithSameProcessId
        });
      } else {
        onSubmit?.(target);
      }
    } else if (action === 'business-rule-task-link') {
      let diagramId, projectId, decisionId;

      if (target.type === 'DECISION') {
        decisionId = target.id;
        diagramId = target.diagramId;
      } else {
        diagramId = target.id;
        decisionId = target.decisions[0]?.id;
      }
      projectId = target.projectId;

      if (state.level === 'linking-duplicates') {
        onSubmit?.(decisionId);
      } else {
        const otherDMNDiagramsWithSameDecisionId = await fetchRelatedDMNDiagrams(diagramId, projectId, decisionId);

        if (otherDMNDiagramsWithSameDecisionId.length) {
          // @ts-expect-error TS2554
          dispatch({
            type: 'OPEN_DUPLICATE_CONFIRMATION',
            payload: otherDMNDiagramsWithSameDecisionId
          });
        } else {
          onSubmit?.(decisionId);
        }
      }
    } else if (action === 'form-link') {
      if (state.level === 'linking-duplicates') {
        onSubmit?.(target);
      } else {
        const otherFormsWithSameFormId = await fetchRelatedForms(target.formId, target.projectId, target.id);

        if (otherFormsWithSameFormId.length) {
          // @ts-expect-error TS2554
          dispatch({
            type: 'OPEN_DUPLICATE_CONFIRMATION',
            payload: otherFormsWithSameFormId
          });
        } else {
          onSubmit?.(target);
        }
      }
    } else if (action === 'move' && sourceProjectId !== targetProjectId && state.level !== 'confirmation') {
      // @ts-expect-error TS2554
      dispatch({ type: 'OPEN_CONFIRMATION', payload: target });
    } else {
      onSubmit?.(target);
    }
  };

  const getEmptyProjectText = () => {
    if (state.level === 'process application') {
      if (action === 'link') {
        return 'This process application does not contain any diagrams.';
      } else if (action === 'form-link') {
        return 'This process application does not contain any forms.';
      } else if (action === 'business-rule-task-link') {
        return 'This process application does not contain any decisions.';
      }

      return 'Process applications cannot contain subfolders.';
    }

    let text = `This ${state.level} does not contain any subfolders`;
    if (action === 'link') {
      text += ' or diagrams';
    } else if (action === 'form-link') {
      text += ' or forms';
    } else if (action === 'business-rule-task-link') {
      text += ' or decisions';
    }
    return `${text}.`;
  };

  const getButtonText = () => {
    switch (action) {
      case 'copy':
        return 'Copy' + (!state.selected ? ' here' : '');
      case 'link':
      case 'business-rule-task-link':
      case 'form-link':
        return 'Link';
      default:
        return 'Move' + (!state.selected ? ' here' : '');
    }
  };

  const getInitialName = () => {
    if (isProjectContext) {
      return 'New project';
    }

    return 'New folder';
  };

  const getTooltipTitle = () => {
    if (isProjectContext && !state.selected) {
      return 'Please select a project.';
    }

    // If the `invalidTargetIds` array contains 2 items, it means that
    // one file is about to be moved.
    // Example: [currentLocation, selectedItem]
    if (invalidTargetIds.length === 2) {
      return 'Item is already in this location.';
    }

    // If it contains 3 or more items, it means that
    // multiple file are about to be moved.
    // Example: [currentLocation, selectedItem1, selectedItem2]
    return 'Items are already in this location.';
  };

  const getInfoMessage = () => {
    let resourceName = '';
    switch (action) {
      case 'link':
        resourceName = 'process';
        break;
      case 'form-link':
        resourceName = 'form';
        break;
      case 'business-rule-task-link':
        resourceName = 'decision';
    }

    return `Or enter any ${resourceName} ID in the properties panel`;
  };

  useEffect(() => {
    const handleKeyDown = (evt) => {
      if (evt.key === 'Enter') {
        handleSubmit();
      }
    };

    if (!state.isMoveButtonDisabled) {
      document.addEventListener('keydown', handleKeyDown);

      return () => document.removeEventListener('keydown', handleKeyDown);
    }
  }, [state.isMoveButtonDisabled, state.level]);

  if (!ready) {
    return null;
  }

  const isFormLink = action === 'form-link';
  const formLinkedElementLabel = isFormLink
    ? is(selectedElement, 'bpmn:StartEvent')
      ? 'start event'
      : 'user task'
    : null;
  const isFormOrFolderPresent = isFormLink && state.items.length !== 0;

  const isBusinessRuleTaskLinking = action === 'business-rule-task-link';
  const isDecisionOrFolderPresent = isBusinessRuleTaskLinking && state.items.length !== 0;

  const isCallActivityLinking = action === 'link';

  const isInProjectLevel = state.level === 'project';

  const isItemDisabled = (state, item) => {
    if (!selectedEntities) {
      return state.isNewItemVisible || item.isDisabled;
    }

    for (const selectedEntity of selectedEntities) {
      const isListItemProcessApplication = isProcessApplication(item);
      const isListItemIdpApplication = isIdpApplication(item);
      const isSelectedEntityFolderOrProcessApplicationOrIdpApplication =
        isFolderOrProcessApplication(selectedEntity) || isIdpApplication(selectedEntity);

      const output =
        state.isNewItemVisible ||
        item.isDisabled ||
        // folders or process applications or idp applications cannot be moved into other process applications and idp applications
        (isSelectedEntityFolderOrProcessApplicationOrIdpApplication &&
          (isListItemProcessApplication || isListItemIdpApplication));

      if (output) {
        return output;
      }
    }

    return false;
  };

  const getDisabledItemMessage = (item) => {
    const isListItemProcessApplication = isProcessApplication(item);
    const isSelectedEntityProcessApplication = selectedEntities?.some(isProcessApplication);
    const isSelectedEntityIdpApplication = selectedEntities?.some(isIdpApplication);
    const isSelectedEntityFolder = selectedEntities?.some(isFolder);

    if (isSelectedEntityProcessApplication && isListItemProcessApplication) {
      return 'Process applications cannot be moved into other process applications.';
    } else if (isSelectedEntityIdpApplication && isListItemProcessApplication) {
      return 'IDP applications cannot be moved into other process applications.';
    } else if (isSelectedEntityFolder && isListItemProcessApplication) {
      return 'Folders cannot be moved into process applications.';
    } else if (isSelectedEntityFolder) {
      return "Container folders can't be moved into their subfolders.";
    }
  };

  return createPortal(
    <Styled.Container
      style={{ ...position }}
      data-dropdown
      ref={containerRef}
      role="dialog"
      aria-label="Target selector"
    >
      <Styled.Wrapper style={{ ...position }} data-dropdown>
        {description && <Styled.Description>{description}</Styled.Description>}
        {isFormLink && isInProjectLevel && !isFormOrFolderPresent ? (
          <FormLinkEmptyState target={state.current} />
        ) : isBusinessRuleTaskLinking && isInProjectLevel && !isDecisionOrFolderPresent ? (
          <BusinessRuleTaskLinkEmptyState target={state.current} />
        ) : (
          <>
            <Styled.Header>
              {state.isBackButtonVisible && (
                <Styled.BackButton
                  title="Go back"
                  disabled={state.isNewItemVisible}
                  onClick={fetchParent}
                  data-test="move-level-up"
                >
                  <ChevronLeft width="16" height="16" />
                </Styled.BackButton>
              )}

              <ItemIcon type={isProcessApplication(state.current) ? PROCESS_APPLICATION : FOLDER} />

              <Styled.Title data-test="move-component-title" title={state.title}>
                {state.title || 'Home'}
              </Styled.Title>
            </Styled.Header>

            <Styled.Body>
              {state.level === 'confirmation' && !state.isNewItemVisible ? (
                <Styled.Message data-test="confirm-move-message">
                  These items will inherit permissions from the project they are moved to. Some people may gain or lose
                  access.
                </Styled.Message>
              ) : state.level === 'linking-duplicates' ? (
                <Fragment>
                  <Styled.ConfirmLinkMessage data-test="confirm-link-message">
                    {`This ${
                      action === 'link' ? 'call activity' : isFormLink ? formLinkedElementLabel : 'business rule task'
                    } will also be linked to the following ${pluralize(
                      isFormLink ? 'form' : 'diagram',
                      state.items.length
                    )}, because they share the same `}
                    {action === 'link' ? (
                      <Tooltip title={state.current.processId}>
                        <Styled.Code>process ID</Styled.Code>
                      </Tooltip>
                    ) : isFormLink ? (
                      <Tooltip title={state.current.formId}>
                        <Styled.Code>form ID</Styled.Code>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={state.current.type === 'DECISION' ? state.current?.id : state.current?.decisions[0]?.id}
                      >
                        <Styled.Code>decision ID</Styled.Code>
                      </Tooltip>
                    )}
                    :
                  </Styled.ConfirmLinkMessage>

                  {state.items.map((item) => (
                    // @ts-expect-error TS2769
                    <Styled.Item data-test={`item-${item.name || item.id}`} key={item.id} $isStatic>
                      <ItemIcon type={item.type} />
                      <Styled.ItemName>{item.name || item.id}</Styled.ItemName>
                    </Styled.Item>
                  ))}
                </Fragment>
              ) : state.items.length === 0 && !state.isNewItemVisible ? (
                <Styled.Message data-test="empty-message">{getEmptyProjectText()}</Styled.Message>
              ) : (
                state.items.map((item) => (
                  <Tooltip key={item.id} disabled={!isItemDisabled(state, item)} title={getDisabledItemMessage(item)}>
                    <div>
                      <Styled.Item
                        onClick={() =>
                          // @ts-expect-error TS2554
                          dispatch({
                            type: 'TOGGLE_SELECT_ITEM',
                            payload: item
                          })
                        }
                        // @ts-expect-error TS2769
                        $isSelected={item.id === state.selected?.id}
                        $isDisabled={isItemDisabled(state, item)}
                        data-test={`item-${item.name || item.id}`}
                      >
                        {showFiles && <ItemIcon type={item.type} />}
                        <Styled.ItemName title={item.name}>{item.name || item.id}</Styled.ItemName>

                        {item.isOpenable && (
                          <Styled.ItemButton
                            data-test={`select-item-${item.name}`}
                            onClick={(evt) => {
                              evt.stopPropagation();
                              fetchChild(item, isProjectContext);
                            }}
                            // @ts-expect-error TS2769
                            $isSelected={item.id === state.selected?.id}
                            title={`Open ${item.name}`}
                          >
                            <ChevronRight width="22" height="22" />
                          </Styled.ItemButton>
                        )}
                      </Styled.Item>
                    </div>
                  </Tooltip>
                ))
              )}

              {state.isNewItemVisible && (
                <NewItem
                  onSubmit={createChild}
                  // @ts-expect-error TS2554
                  onCancel={() => dispatch({ type: 'TOGGLE_NEW_ITEM' })}
                  initialName={getInitialName()}
                />
              )}
            </Styled.Body>

            {isLinkAction(action) && state.level !== 'linking-duplicates' ? (
              <Styled.InfoMessage data-test="link-action-info-message">{getInfoMessage()}</Styled.InfoMessage>
            ) : (
              ''
            )}

            <Styled.Footer>
              {!state.isNewButtonVisible && !state.isCancelButtonVisible && (
                <>
                  {isFormLink && (
                    <Styled.FooterLeft>
                      <CreateNewFormButton target={state.current} />
                    </Styled.FooterLeft>
                  )}

                  {isBusinessRuleTaskLinking && (
                    <Styled.FooterLeft>
                      <CreateNewDmnButton target={state.current} />
                    </Styled.FooterLeft>
                  )}

                  {isCallActivityLinking && (
                    <Styled.FooterLeft>
                      <CreateNewBpmnButton target={state.current} />
                    </Styled.FooterLeft>
                  )}
                </>
              )}

              {state.isNewButtonVisible && (
                <Styled.CreateButton
                  data-test="create-new-target"
                  // @ts-expect-error TS2769
                  size="small"
                  disabled={state.isNewItemVisible}
                  // @ts-expect-error TS2554
                  onClick={() => dispatch({ type: 'TOGGLE_NEW_ITEM' })}
                  title="Create new"
                >
                  <Plus width="24" height="24" />
                </Styled.CreateButton>
              )}

              {state.isCancelButtonVisible && (
                // @ts-expect-error TS2322
                <Button size="small" variant="text" onClick={fetchParent} data-test="cancel-move">
                  Cancel
                </Button>
              )}

              <Tooltip
                disabled={
                  !state.isMoveButtonDisabled ||
                  state.isNewItemVisible ||
                  isLinkAction(action) ||
                  action === 'form-link'
                }
                title={getTooltipTitle()}
              >
                <div>
                  {/* @ts-expect-error TS2322 */}
                  <Button
                    size="small"
                    disabled={state.isMoveButtonDisabled}
                    onClick={handleSubmit}
                    data-test="confirm-move"
                    title={action === 'form-link' ? 'Link form' : ''}
                  >
                    {getButtonText()}
                  </Button>
                </div>
              </Tooltip>
            </Styled.Footer>
          </>
        )}
      </Styled.Wrapper>
    </Styled.Container>,
    document.getElementById('root')
  );
};

TargetSelector.propTypes = {
  startingPoint: PropTypes.object.isRequired,
  selectedEntities: PropTypes.array,
  invalidTargetIds: PropTypes.array,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  onSubmit: PropTypes.func,
  action: PropTypes.oneOf(['move', 'copy', 'link', 'business-rule-task-link', 'form-link']),
  align: PropTypes.oneOf(['auto', 'bottom']),
  showFiles: PropTypes.bool,
  description: PropTypes.string,
  handleClickOutside: PropTypes.func
};

export default observer(TargetSelector);
