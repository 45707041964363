/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export const getExtractionFieldNameInvalidText = (newExtractionFieldName, extractionFields) => {
  if (!newExtractionFieldName) return;

  return !/^[a-zA-Z0-9_-]+$/.test(newExtractionFieldName)
    ? 'Name can only contain alphanumeric characters, underscores and dashes.'
    : extractionFields.some((field) => field.name === newExtractionFieldName)
      ? 'Extraction field already exists with this name.'
      : '';
};
