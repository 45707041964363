/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

export const Container = styled.div`
  // 40 is the height offset for the list header
  height: calc(100% - 40px);
`;

export const ErrorPanelItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  line-height: 18px;

  text-transform: capitalize;

  svg {
    fill: ${({ type }) =>
      type === 'success'
        ? 'var(--cds-support-success)'
        : type === 'error'
          ? 'var(--cds-support-error)'
          : 'var(--cds-support-warning)'};
  }
`;
