/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Link } from '@carbon/react';
import { useEffect, useState } from 'react';

import { CreateNewClusterSection } from 'components';
import config from 'utils/config';
import { clustersStore, organizationStore, processApplicationStore } from 'stores';

import { defineStagesModalStore } from './';
import * as Styled from './DefineStagesModal.styled';
import StageClusterSection from './StageClusterSection';

function DefineStagesModal({ onClose = () => {} }) {
  const processApplication = processApplicationStore.processApplication;

  const { devClusters, testClusters, stageClusters, prodClusters } = clustersStore;

  const initialDevClusterId = processApplication.defaultDevClusterId;
  const initialTestClusterId = processApplication.defaultTestClusterId;
  const initialStageClusterId = processApplication.defaultStageClusterId;
  const initialProdClusterId = processApplication.defaultProdClusterId;

  const [defaultDevClusterId, setDefaultDevClusterId] = useState(initialDevClusterId);
  const [defaultTestClusterId, setDefaultTestClusterId] = useState(initialTestClusterId);
  const [defaultStageClusterId, setDefaultStageClusterId] = useState(initialStageClusterId);
  const [defaultProdClusterId, setDefaultProdClusterId] = useState(initialProdClusterId);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Observing when default*ClusterId change externally
    setDefaultDevClusterId(processApplication.defaultDevClusterId);
    setDefaultTestClusterId(processApplication.defaultTestClusterId);
    setDefaultStageClusterId(processApplication.defaultStageClusterId);
    setDefaultProdClusterId(processApplication.defaultProdClusterId);
  }, [
    processApplication.defaultDevClusterId,
    processApplication.defaultTestClusterId,
    processApplication.defaultStageClusterId,
    processApplication.defaultProdClusterId
  ]);

  useEffect(() => {
    // Loading the cluster list, when opening the define stages modal
    if (defineStagesModalStore.isVisible) {
      clustersStore.loadClusters();
    }
  }, [defineStagesModalStore.isVisible, clustersStore.loadClusters]);

  const closeModal = () => {
    defineStagesModalStore.close();
    onClose();
  };

  const performRequest = async () => {
    try {
      setIsSaving(true);
      await processApplicationStore.updateStages({
        defaultDevClusterId: defaultDevClusterId,
        defaultTestClusterId: defaultTestClusterId,
        defaultStageClusterId: defaultStageClusterId,
        defaultProdClusterId: defaultProdClusterId
      });
      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  const onRequestClose = () => {
    closeModal();
    setDefaultDevClusterId(initialDevClusterId);
    setDefaultTestClusterId(initialTestClusterId);
    setDefaultStageClusterId(initialStageClusterId);
    setDefaultProdClusterId(initialProdClusterId);
  };

  return (
    <Styled.StagesModal
      open={defineStagesModalStore.isVisible}
      modalHeading="Define stages"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={onRequestClose}
      loadingStatus={isSaving ? 'active' : 'inactive'}
      loadingDescription={'Saving...'}
    >
      <Styled.StagesModalContent>
        <Styled.ContentHeading>
          <section>
            <div>Assign clusters to your stages so that your process easily navigates through the PDLC.</div>
            <Link
              href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-application-pipeline/#deployment-pipeline-stages"
              target="_blank"
            >
              Learn more.
            </Link>
          </section>
          <section>
            <div>To create new clusters, go to Console.</div>
            {/* @ts-expect-error TS2607 */}
            <CreateNewClusterSection
              creationElement={
                <Styled.OpenConsoleLink
                  href={`https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/clusters?create=true`}
                  target="_blank"
                >
                  Open Console
                </Styled.OpenConsoleLink>
              }
            />
          </section>
        </Styled.ContentHeading>
        <StageClusterSection
          stageName="development"
          tooltipDefinition="The development stage where new software features and changes are created and tested."
          tagName="dev"
          clusters={devClusters}
          onClusterSelect={setDefaultDevClusterId}
          selectedClusterId={defaultDevClusterId}
        />
        <StageClusterSection
          stageName="testing"
          tooltipDefinition="The testing stage for quality checks, ensuring software meets defined standards before release."
          tagName="test"
          clusters={testClusters}
          onClusterSelect={setDefaultTestClusterId}
          selectedClusterId={defaultTestClusterId}
        />
        <StageClusterSection
          stageName="staging"
          tooltipDefinition="The staging stage for controlled testing where changes are validated before deployment to production."
          tagName="stage"
          clusters={stageClusters}
          onClusterSelect={setDefaultStageClusterId}
          selectedClusterId={defaultStageClusterId}
        />
        <StageClusterSection
          stageName="production"
          tooltipDefinition="The production stage indicates the live system with the latest software, deployable only by admins and organization owners through the interface."
          tagName="prod"
          clusters={prodClusters}
          onClusterSelect={setDefaultProdClusterId}
          selectedClusterId={defaultProdClusterId}
        />
      </Styled.StagesModalContent>
    </Styled.StagesModal>
  );
}

export default observer(DefineStagesModal);
