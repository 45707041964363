/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

import { Input } from 'primitives';
import { CollaboratorItem as CollaboratorItemComponent } from 'components';

export const MultiLineTextarea = styled(Input)`
  font-size: 13px;
  resize: none;
`;

export const FormSuggestionsWrapper = styled.div`
  position: relative;
  flex: 1;
  line-height: 0;
`;

export const UserSuggestions = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
  border-radius: var(--spacing-tiny);
  background-color: white;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

export const CollaboratorItem = styled(CollaboratorItemComponent)`
  cursor: pointer;
  padding: var(--spacing-small);
  font-size: 14px;
  line-height: 1.5;

  &:hover {
    background-color: var(--silver-darken-94)};
  }

  ${(props) => props.isSelected && `background-color: var(--silver-darken-94)};`};
`;

export const EmptyState = styled.div`
  padding: var(--spacing-medium) 0;
  text-align: center;
  line-height: 1.1;
`;
