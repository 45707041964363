/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { ArrowRight } from '@carbon/react/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, Stack, Tag } from '@carbon/react';
import { v4 } from 'uuid';
import { observer } from 'mobx-react';

import { Cross } from 'icons';
import { tracingService, trackingService } from 'services';
import useFocusTrap from 'hooks/useFocusTrap';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import usePositioning from 'primitives/NonModalDialog/usePositioning';
import buildPath from 'utils/build-path';
import { currentDiagramStore } from 'stores';
import bpmnCopilotClient from 'experiments/ai-features/bpmn-copilot/bpmn-copilot-client';

import * as Styled from './CamundaAiChatbox.styled';
import Messages from './Messages';

const errorText =
  "I'm sorry, but I seem to have run into some problems. Could you please try again with a simpler prompt?";

const CamundaAiChatbox = ({ onClose, isOpen, location, buttonRef, modeler, saveDiagramContent, addNewMilestone }) => {
  const [text, setText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const [messages, setMessages] = useState([]);

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const chatboxBodyRef = useRef(null);

  useEffect(() => {
    if (!chatboxBodyRef.current) {
      return;
    }

    chatboxBodyRef.current.scrollTop = chatboxBodyRef.current.scrollHeight;
  }, [messages]);

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, { id: v4(), ...message }]);
  };

  const addPromptToTextArea = (e) => {
    setText(e.target.textContent);
  };

  const onSubmit = useCallback(async () => {
    if (text.trim().length === 0) {
      return;
    }

    addMessage({ type: 'human', text });
    setIsProcessing(true);
    setText('');

    let responseMessage;
    try {
      responseMessage = await bpmnCopilotClient.invoke({ modeler, addNewMilestone, textPrompt: text });
      saveDiagramContent();
    } catch (e) {
      console.error('Invoking BPMN Copilot failed');
      tracingService.traceError(e, 'Invoking BPMN Copilot failed');

      responseMessage = errorText;
    }

    addMessage({ type: 'ai', text: responseMessage });

    setIsProcessing(false);
  }, [text, addMessage]);

  const onTextareaKeydown = useCallback(
    (event) => {
      const { code, shiftKey } = event;

      if (code !== 'Enter') {
        return;
      }

      if (shiftKey) {
        event.preventDefault();
        setText((value) => `${value}\n`);
        return;
      }

      event.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  const tileRef = useRef();
  useFocusTrap({ open: isOpen, ref: tileRef, selectorPrimaryFocus: 'textarea' });

  const onChatboxKeyDown = useKeyboardTrap({ onClose, ref: tileRef });

  const position = usePositioning({
    dialogEl: tileRef?.current,
    anchorEl: buttonRef?.current,
    justify: 'right',
    alignment: 'top'
  });

  const docsAiRef = useRef();

  return createPortal(
    <Styled.ChatboxTile
      // @ts-expect-error TS2769
      $isOpen={isOpen}
      $height={'450'}
      $position={position}
      ref={tileRef}
      onKeyDown={onChatboxKeyDown}
      role="dialog"
      aria-label="Camunda AI Copilot"
    >
      <Styled.ChatboxHeader>
        <Styled.HeaderGroup orientation="horizontal" gap={3}>
          <Styled.CamundaCopilotHeader>Camunda Copilot</Styled.CamundaCopilotHeader>
          <Tag type="gray">alpha</Tag>
        </Styled.HeaderGroup>
        <Styled.HeaderSpace />
        <Styled.HeaderGroup orientation="horizontal">
          <Styled.DocsAiLink
            id="camunda-ai-button"
            tabIndex="0"
            ref={docsAiRef}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                // @ts-expect-error TS18048
                docsAiRef?.current?.click();
              }
            }}
            onClick={() => {
              trackingService.trackDocsAIOpen({ enabled: true, from: location });
              onClose();
            }}
          >
            Docs AI
          </Styled.DocsAiLink>
          <Styled.CloseButton
            size="sm"
            kind="ghost"
            iconDescription="Close"
            hasIconOnly
            onClick={onClose}
            renderIcon={() => {
              return <Cross width="16px" height="16px" />;
            }}
          />
        </Styled.HeaderGroup>
      </Styled.ChatboxHeader>
      <Styled.ChatboxBody ref={chatboxBodyRef} tabIndex={0}>
        {messages.length === 0 && (
          <>
            <Styled.BodyGroup>
              <Styled.TextDescriptionSpan>
                Chat with the&nbsp;
                <Link href={'https://docs.camunda.io'} target="_blank" rel="noreferrer">
                  BPMN Copilot
                </Link>
                &nbsp;for assistance creating a new and functional BPMN diagram. Note that the Copilot currently
                overwrites existing work, but will save a{' '}
                <Link
                  href={buildPath(`/diagrams/${currentDiagramStore.diagramId}/versions`)}
                  aria-label="Open diagram versions"
                  target="_blank"
                >
                  version
                </Link>{' '}
                of the diagram.
              </Styled.TextDescriptionSpan>
            </Styled.BodyGroup>
            <Styled.HeaderSpace />
            <Styled.BodyGroup>
              <h2>Example prompts</h2>
              <Stack gap={5} orientation="horizontal">
                <Styled.ExamplePrompt onClick={addPromptToTextArea}>
                  Generate a mortgage approval process
                </Styled.ExamplePrompt>
                <Styled.ExamplePrompt onClick={addPromptToTextArea}>
                  Create an e-commerce order fulfillment process
                </Styled.ExamplePrompt>
              </Stack>
            </Styled.BodyGroup>
          </>
        )}

        <Messages messages={messages} isProcessing={isProcessing} />
        <Styled.HeaderSpace />
      </Styled.ChatboxBody>
      <Styled.ChatboxForm>
        <Styled.StyledTextArea
          id="ai-chatbox-textarea"
          data-test="ai-chatbox-textarea"
          aria-label="ai-chatbox-textarea"
          value={text}
          placeholder={'Enter a process description, or click Docs AI'}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={onTextareaKeydown}
        />
        <Styled.ButtonWrapper>
          <Styled.ChatboxSend
            hasIconOnly
            aria-label="Send"
            iconDescription={'Send'}
            size={'md'}
            kind={'secondary'}
            renderIcon={() => {
              return <ArrowRight width="16px" height="16px" />;
            }}
            onClick={onSubmit}
          />
        </Styled.ButtonWrapper>
      </Styled.ChatboxForm>
    </Styled.ChatboxTile>,
    document.body
  );
};

export default observer(CamundaAiChatbox);
