/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled, { keyframes } from 'styled-components';

import { Loader } from 'primitives';

const slidein = keyframes`
  from { transform: translateX(-50%) translateY(calc(100% + 20px)); }
  to { transform: translateX(-50%) translateY(0%); }
`;

const slideout = keyframes`
  from { transform: translateX(-50%) translateY(0%); }
  to { transform: translateX(-50%) translateY(calc(100% + 20px)); }
`;

export const Wrapper = styled.div`
  z-index: var(--z-snackbar);
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 16px 25px;
  min-width: 300px;
  max-width: 700px;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: white;
  font-weight: 500;
  background-color: ${({ $variant }) => ($variant === 'info' ? 'var(--grey-darken-23)' : 'var(--red-darken-42)')};
  animation: ${slidein} 0.3s ease-out forwards;

  &.slideout {
    animation: ${slideout} 0.3s ease-out forwards;
  }

  ${({ $variant }) =>
    $variant === 'info' &&
    `
      a {
        color: var(--cds-blue-40);
        text-decoration: none;
      }

      a:hover {
        color: var(--cds-blue-40);
        text-decoration: underline;
      }
  `}
`;

export const LoadingAnimation = styled(Loader)`
  margin-right: 10px;
`;
