/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import schema from '@camunda/zeebe-element-templates-json-schema/resources/schema.json';
import { Helmet } from 'react-helmet-async';

import { processApplicationStore } from 'stores';
import { CodeEditor } from 'components';
import { trackingService } from 'services';
import getIdFromSlug from 'utils/getIdFromSlug';
import { getPageTitle, isContainedInProcessApplication } from 'utils/helpers';

import connectorTemplateStore from './ConnectorTemplateStore';
import Header from './Header';
import ActionBar from './ActionBar';
import PropertiesPanel from './PropertiesPanel';
import * as Styled from './ConnectorTemplate.styled';
import useEditorSuggestions from './useEditorSuggestions';
import useEditorHovers from './useEditorHovers';
import { DuplicateConnectorTemplateDialog } from './DuplicateConnectorTemplateDialog';

export function ConnectorTemplate() {
  // @ts-expect-error TS2339
  const { slug } = useParams();
  const { connectorTemplate, loading, update, forceRefresh, confirmRefresh, isReadOnly } = connectorTemplateStore;
  const [refreshing, setRefreshing] = useState(false);

  const suggestionProviders = useEditorSuggestions();
  const hoverProviders = useEditorHovers();

  useEffect(() => {
    (async () => {
      await connectorTemplateStore.init(getIdFromSlug(slug));
      trackingService.trackPageView('connector-template');
      connectorTemplateStore.trackConnectorTemplateView('direct');
    })();
    return () => connectorTemplateStore.reset();
  }, [slug]);

  useEffect(() => {
    (async () => {
      if (connectorTemplate && isContainedInProcessApplication(connectorTemplate)) {
        await processApplicationStore.init(connectorTemplate.folderId, true);
      }
    })();

    return () => processApplicationStore.reset();
  }, [connectorTemplate]);

  // A writeable CodeEditor is an uncontrolled component, meaning that changes to the value
  // prop are not reflected after the component has been mounted.
  // See https://github.com/camunda/web-modeler/pull/1315
  // In order to force a reload of the CodeEditor when the content needs to be changed (e.g.
  // when the user uploads a new JSON), we unmount and re-mount the CodeEditor.
  useEffect(() => {
    if (forceRefresh) {
      setRefreshing(true);
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (refreshing) {
      confirmRefresh();
      setRefreshing(false);
    }
  }, [refreshing]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle(connectorTemplate?.name)}</title>
      </Helmet>
      <Header />

      <ActionBar />

      {!loading && !refreshing && (
        <Styled.Wrapper id="connector-template-editor" data-editable={!connectorTemplate.imported}>
          <CodeEditor
            value={connectorTemplate?.content}
            readOnly={isReadOnly}
            readOnlyRanges={connectorTemplateStore.readOnlyRanges}
            folding
            onChange={update}
            schema={schema}
            titles={[<b>Raw JSON</b>]}
            hideControls
            suggestionProviders={suggestionProviders}
            hoverProviders={hoverProviders}
          />
          <PropertiesPanel value={connectorTemplate?.content} />
        </Styled.Wrapper>
      )}

      {connectorTemplate?.imported && <DuplicateConnectorTemplateDialog />}
    </>
  );
}

export default observer(ConnectorTemplate);
