/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-expect-error TS2307
import BpmnModdle from 'bpmn-moddle';
import DmnModdle from 'dmn-moddle';
import { CamundaCloudModeler as DmnModeler } from 'camunda-dmn-js';
import ModelerModdleExtension from 'modeler-moddle/resources/modeler.json';
import DmnModelerModdleExtension from 'modeler-moddle/resources/dmn-modeler.json';
// @ts-expect-error TS2307
import zeebeBpmnModdle from 'zeebe-bpmn-moddle/resources/zeebe';
// @ts-expect-error TS2307
import zeebeDmnModdle from 'zeebe-dmn-moddle/resources/zeebe';
import Modeler from 'camunda-bpmn-js/lib/camunda-cloud/Modeler';

import { BPMN, C7_EXECUTION_PLATFORM, DMN, EXECUTION_PLATFORM } from 'utils/constants';
import { parseXML } from 'utils/web-modeler-diagram-parser';
import parseExecutionPlatform from 'utils/web-modeler-diagram-parser/parse-execution-platform-details';

/**
 * Validates BPMN and DMN diagrams by utilizing the moddle functions from bpmn-io.
 *
 * @param {String} xml The diagram content to validate.
 * @ts-expect-error TS1064 returns {Boolean}
 */
export const isValidBpmnDiagram = async (xml) => {
  try {
    // @ts-expect-error TS2339
    const { documentElement } = parseXML(xml);

    if (documentElement?.namespaceURI?.includes(BPMN)) {
      const moddle = new BpmnModdle({
        zeebe: zeebeBpmnModdle
      });
      await moddle.fromXML(xml);

      return true;
    } else {
      throw new Error('File is malformed.');
    }
  } catch {
    return false;
  }
};

/**
 * Validates BPMN and DMN diagrams by utilizing the moddle functions from bpmn-io.
 * Also sanitizes the content before importing it into the modeler.
 * In case of error or warnings, the content will be null.
 *
 * @param content The diagram content to validate and sanitize.
 * @param type The type of the diagram.
 * @returns {Promise<{error: Error | null, warnings: string[], content: String | null}>} The result of the validation and sanitization.
 */
export const sanitizeBeforeImport = async (content, type) => {
  if (type === DMN) {
    // See issue #2682
    content = patchDmnFileDefinitions(content);
  }

  let modeler;
  let warnings;

  try {
    if (type === BPMN) {
      modeler = new Modeler({
        moddleExtensions: {
          modeler: ModelerModdleExtension
        }
      });
    } else if (type === DMN) {
      modeler = new DmnModeler({
        moddleExtensions: {
          modeler: DmnModelerModdleExtension
        }
      });
    } else {
      return {
        error: new Error(`Unknown diagram type ${type}`),
        warnings: [],
        content: null
      };
    }

    const { error, warnings: importWarnings } = await modeler.importXML(content);
    warnings = importWarnings;

    if (error) {
      return { error, warnings: warnings || [], content: null };
    }
  } catch (error) {
    return { error, warnings: [], content: null };
  }

  return { error: null, warnings: warnings || [], content };
};

export const isValidDmnFile = async (xml) => {
  try {
    xml = patchDmnFileDefinitions(xml);

    // @ts-expect-error TS2339
    const { documentElement } = parseXML(xml);

    if (documentElement.namespaceURI.includes(DMN)) {
      const moddle = new DmnModdle({
        zeebe: zeebeDmnModdle
      });
      await moddle.fromXML(xml);

      return true;
    } else {
      throw new Error('File is malformed.');
    }
  } catch {
    return false;
  }
};

// See issue #2682
export const patchDmnFileDefinitions = (xml) => {
  return xml
    .replace('"https://www.omg.org/spec/DMN/20191111/MODEL"/"', '"https://www.omg.org/spec/DMN/20191111/MODEL/"')
    .replace('"https://www.omg.org/spec/DMN/20191111/DMNDI"/"', '"https://www.omg.org/spec/DMN/20191111/DMNDI/"')
    .replace('"http://www.omg.org/spec/DMN/20180521/DC"/"', '"http://www.omg.org/spec/DMN/20180521/DC/"')
    .replace('"http://www.omg.org/spec/DMN/20180521/DI"/"', '"http://www.omg.org/spec/DMN/20180521/DI/"');
};

export const getExecutionPlatform = (xmlStr) => {
  const { executionPlatform } = parseExecutionPlatform(xmlStr) || {};
  return executionPlatform;
};

export const isCamunda8Diagram = (xmlStr) => {
  const executionPlatform = getExecutionPlatform(xmlStr);
  return Boolean(executionPlatform && executionPlatform === EXECUTION_PLATFORM);
};

export const isCamunda7Diagram = (xmlStr) => {
  const executionPlatform = getExecutionPlatform(xmlStr);
  return Boolean(!executionPlatform || executionPlatform === C7_EXECUTION_PLATFORM);
};

export const hasValidExecutionPlatformAndVersion = (xmlStr) => {
  const { executionPlatform, executionPlatformVersion } = parseExecutionPlatform(xmlStr) || {};
  return Boolean(
    (executionPlatform === EXECUTION_PLATFORM || executionPlatform === C7_EXECUTION_PLATFORM) &&
      executionPlatformVersion
  );
};
