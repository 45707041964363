/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Modal, TextInput } from '@carbon/react';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import gitSettingsStore from './GitSettingsStore';

const DeleteProviderConnectionDialog = () => {
  const { shouldShowDeleteDialog, status } = gitSettingsStore;

  const [confirmWord, setConfirmWord] = useState('');

  useEffect(() => {
    setConfirmWord('');
  }, [shouldShowDeleteDialog]);

  return (
    <Modal
      size="xs"
      open={shouldShowDeleteDialog}
      onRequestClose={() => gitSettingsStore.hideDeleteConnection()}
      onSecondarySubmit={() => gitSettingsStore.hideDeleteConnection()}
      onRequestSubmit={() => gitSettingsStore.deleteConnection()}
      preventCloseOnClickOutside
      danger
      modalHeading="Delete repository connection"
      primaryButtonText="Delete"
      primaryButtonDisabled={confirmWord !== 'DELETE'}
      secondaryButtonText="Cancel"
      // @ts-expect-error TS2322
      loadingStatus={status}
      loadingDescription={status === 'error' ? 'An error occurred. Please try again.' : 'Deleting...'}
    >
      Are you sure you want to remove your configured connection between your process application and your source code
      repository provider?
      <WordConfirmContainer>
        <TextInput
          data-modal-primary-focus
          id="delete-confirm"
          type="text"
          labelText="Type the word DELETE to confirm"
          value={confirmWord}
          onChange={(evt) => setConfirmWord(evt.target.value)}
        />
      </WordConfirmContainer>
    </Modal>
  );
};

const WordConfirmContainer = styled.div`
  margin-top: var(--cds-spacing-06, 24px);
`;

export default observer(DeleteProviderConnectionDialog);
