/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, ProgressBar } from '@carbon/react';
import { DocumentView, TrashCan } from '@carbon/icons-react';
import { useState } from 'react';

import { EntityTable } from 'components';

import * as Styled from './IdpDocuments.styled';

const IdpDocuments = ({ idpDocuments, onDocumentDelete, onDocumentExtract, renderUploadButton }) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  return (
    <EntityTable
      noTablePadding
      action={() => renderUploadButton({ onDocumentsUploadProgress: setUploadProgress })}
      columns={[
        { key: 'name', header: 'Document name', sortable: true },
        {
          key: 'entityAction',
          header: '',
          renderer: (document) => {
            return document.isUploading ? (
              <ProgressBar type="inline" label="Uploading document" size="small" value={uploadProgress} />
            ) : (
              <Styled.DocumentActionContainer>
                <Button kind="ghost" onClick={() => onDocumentExtract(document)} renderIcon={DocumentView}>
                  Extract
                </Button>
                <Button
                  hasIconOnly
                  iconDescription="Delete"
                  tooltipPosition="left"
                  kind="ghost"
                  data-test={`delete-document-${document.id}`}
                  onClick={() => onDocumentDelete?.(document.id)}
                  renderIcon={TrashCan}
                />
              </Styled.DocumentActionContainer>
            );
          }
        }
      ]}
      toolbarPlaceholder="Search document"
      rows={idpDocuments.map((document) => ({
        id: document.id,
        name: document.name,
        entityAction: document
      }))}
    />
  );
};

export default IdpDocuments;
