/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { ActionableNotification, InlineNotification } from '@carbon/react';
import { useEffect, useState } from 'react';

import { clusterService } from 'services';
import { notificationStore, organizationStore } from 'stores';
import { CLUSTER_STATUS } from 'utils/cluster/constants';

export default function ClusterPausedResumingNotification({ clusterId, status }) {
  const [isUpdating, setIsUpdating] = useIsUpdating(status);

  return isUpdating || status === CLUSTER_STATUS.RESUMING ? (
    <InlineNotification
      title="Your cluster is being resumed"
      subtitle="This will take a few minutes"
      kind="warning"
      lowContrast
      hideCloseButton
    />
  ) : (
    <ActionableNotification
      title="Paused cluster"
      subtitle="To deploy to this cluster, you need to resume it."
      kind="warning"
      lowContrast
      hideCloseButton
      actionButtonLabel={'Resume'}
      inline
      onActionButtonClick={async () => {
        try {
          // @ts-expect-error TS2349
          setIsUpdating(true);
          await clusterService.resumeCluster(organizationStore.currentOrganizationId, clusterId);
        } catch {
          // @ts-expect-error TS2349
          setIsUpdating(false);
          notificationStore.showError('Unable to resume cluster. Please wait a few minutes and try again.');
        }
      }}
    />
  );
}

const useIsUpdating = (status) => {
  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    if (status !== CLUSTER_STATUS.SUSPENDED) {
      setIsUpdating(false);
    }
  }, [status]);

  return [isUpdating, setIsUpdating];
};
