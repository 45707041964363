/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

const VARIANTS = {
  big: 20,
  normal: 10,
  none: 0
};

export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: ${({ variant }) => `${VARIANTS[variant]}px 0`};
  background-color: var(--silver-darken-87);
`;
