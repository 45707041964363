/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button, Link } from '@carbon/react';

import config from 'utils/config';
import { ClustersList, ClusterUpdateNotification, CreateNewClusterSection } from 'components';
import { organizationStore, processApplicationStore } from 'stores';

import * as Styled from './DefaultClusterSelection.styled';

export default function DefaultClusterSelection(props) {
  return (
    processApplicationStore.isCreationModalVisible && (
      <section>
        <Styled.SectionTitle>Select a development cluster </Styled.SectionTitle>
        <span>This will be the preferred cluster for deployment during development.</span>
        <p>
          <Link
            href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#create-a-process-application"
            target="_blank"
          >
            Learn more
          </Link>
        </p>
        {/* @ts-expect-error TS2741 */}
        <ClusterUpdateNotification isModalOpen>
          {!props.clusters || props.clusters.length === 0 ? (
            <Styled.NoClusterSection>
              <span>No development cluster has been created yet.</span>
              <span>You can skip the creation and take care of it later.</span>
            </Styled.NoClusterSection>
          ) : (
            <ClustersList {...props} />
          )}
        </ClusterUpdateNotification>

        {/* @ts-expect-error TS2607 */}
        <CreateNewClusterSection
          creationElement={
            <Button
              size="md"
              onClick={() => {
                window.open(
                  `https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/clusters?create=true`
                );
              }}
              kind="tertiary"
            >
              Create new dev cluster
            </Button>
          }
          clusterType="DEV"
        />
      </section>
    )
  );
}
