/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, cloneElement, Children, useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
// @ts-expect-error TS2305
import { PropTypes } from 'prop-types';

import * as Styled from './Tooltip.styled';

const ARROW_SIZE = 5;

/**
 * Can be configured to show on hover (normal tooltip functionality) OR
 * show based on a timer
 *
 * The tooltip will show based on a timer if the `showAfter` prop is set. Otherwise,
 * it will be shown on hover. If the `hideAfter` prop is set along with `showAfter`, the tooltip
 * will also close based on a timer. In timer mode, clicking anywhere outside will always close the tooltip.
 *
 * The `showOnlyOnOverflow` prop can be set if the tooltip need to be shown only when the content
 * overflows (works only in hover mode).
 *
 */
const withSharedState = (Component) => {
  const wrapped = ({
    children,
    showOnlyOnOverflow = false,
    showAfter = 0,
    hideAfter = 0,
    disabled = false,
    interactive = false,
    ...props
  }) => {
    const [anchorEl, setAnchorEl] = useState();
    const [timer, setTimer] = useState();

    const anchorRef = useRef();

    const controlledByTimer = showAfter > 0;
    const controlledByHover = showAfter === 0;

    useEffect(() => {
      let showTimer, hideTimer;
      if (controlledByTimer) {
        showTimer = setTimeout(() => setAnchorEl(anchorRef.current), showAfter);
        if (hideAfter > 0) {
          // @ts-expect-error TS2554
          hideTimer = setTimeout(() => setAnchorEl(), showAfter + hideAfter);
        }
      }

      return () => {
        showTimer && clearTimeout(showTimer);
        hideTimer && clearTimeout(hideTimer);
      };
    }, []);

    children = Children.map(children, (child, i) => {
      if (controlledByTimer) {
        // Set a ref to first child (to use it as the anchor element)
        if (i === 0) {
          return cloneElement(child, {
            ref: anchorRef
          });
        } else {
          return child;
        }
      } else if (controlledByHover) {
        // Each child can be the anchor element when hovered over
        return cloneElement(child, {
          // @ts-expect-error TS2345
          onMouseEnter: (evt) => setAnchorEl(evt.currentTarget),
          onMouseLeave: () => {
            if (interactive) {
              // @ts-expect-error TS2345
              setTimer(setTimeout(setAnchorEl, 200));
            } else {
              // @ts-expect-error TS2554
              setAnchorEl();
            }
          }
        });
      }
    });

    let showTooltip = false;
    if (!disabled) {
      showTooltip = Boolean(anchorEl);
      if (controlledByHover && showOnlyOnOverflow) {
        // @ts-expect-error TS18048
        const isOverflowing = Boolean(anchorEl) && anchorEl.scrollWidth > anchorEl.clientWidth;
        showTooltip = isOverflowing;
      }
    }

    return (
      <Fragment>
        {children}

        {showTooltip && (
          <Component
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            timer={timer}
            controlledByTimer={controlledByTimer}
            {...props}
          />
        )}
      </Fragment>
    );
  };

  wrapped.propTypes = {
    interactive: PropTypes.bool,
    showOnlyOnOverflow: PropTypes.bool,
    showAfter: PropTypes.number,
    hideAfter: PropTypes.number,
    disabled: PropTypes.bool
  };

  return wrapped;
};

const Tooltip = ({
  timer,
  setAnchorEl,
  anchorEl,
  title,
  align = 'bottom',
  justify = 'center',
  controlledByTimer = false,
  color = 'grey',
  onClose,
  keepOpen = false
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [alignment, setAlignment] = useState(align);
  const tooltip = useRef();

  useEffect(() => {
    const { top, bottom, left, width } = anchorEl.getBoundingClientRect();
    // @ts-expect-error TS18048
    const insufficientSpaceAtBottom = bottom + tooltip.current.offsetHeight + ARROW_SIZE > window.innerHeight;

    const getLeftPosition = () => {
      switch (justify) {
        case 'left':
          return left;
        case 'right':
          // @ts-expect-error TS18048
          return left + width - tooltip.current.offsetWidth;
        default:
          // @ts-expect-error TS18048
          return left - tooltip.current.offsetWidth / 2 + width / 2;
      }
    };

    const getTopPosition = () => {
      if (alignment == 'top' || insufficientSpaceAtBottom) {
        // @ts-expect-error TS18048
        return top - tooltip.current.offsetHeight - ARROW_SIZE - 5;
      }

      return bottom + ARROW_SIZE + 5;
    };

    if (insufficientSpaceAtBottom) {
      setAlignment('top');
    }

    setPosition({
      top: getTopPosition(),
      left: getLeftPosition()
    });
  }, [anchorEl, anchorEl?.getBoundingClientRect().top]);

  // Close tooltip when clicked outside (in timer mode)
  useEffect(() => {
    if (controlledByTimer) {
      const handleClick = (e) => {
        // @ts-expect-error TS18048
        if (tooltip.current.contains(e.target)) {
          return; // inside click
        }
        if (!keepOpen) {
          setAnchorEl(); // outside click
        }
      };
      document.addEventListener('mousedown', handleClick);

      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }
  }, []);

  // Call `onClose` method (if present) when closing, in timer mode
  useEffect(() => {
    if (controlledByTimer && onClose) {
      return () => {
        onClose();
      };
    }
  }, []);

  return createPortal(
    <Styled.Wrapper
      onMouseEnter={() => clearTimeout(timer)}
      onMouseLeave={() => !controlledByTimer && setAnchorEl()}
      // @ts-expect-error TS2769
      $position={position}
      $justify={justify}
      $align={alignment}
      ref={tooltip}
      color={color}
      role="tooltip"
      aria-hidden={!anchorEl}
    >
      {title}
    </Styled.Wrapper>,
    document.body
  );
};

Tooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.element,
  align: PropTypes.oneOf(['top', 'bottom']),
  justify: PropTypes.oneOf(['left', 'center', 'right']),
  interactive: PropTypes.bool,
  controlledByTimer: PropTypes.bool,
  color: PropTypes.string,
  keepOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default withSharedState(Tooltip);
