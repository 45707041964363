/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { formStore } from 'stores';
import { Dropdown } from 'components';
import { IconButton } from 'primitives';
import { Dots, Milestone, Copy, Download } from 'icons';
import { trackingService } from 'services';
import { exportForm } from 'utils/file-io';
import hasAccess, { actions } from 'utils/user-access';

import * as Styled from './ActionsMenu.styled';

const ActionsMenu = () => {
  const iconSize = 18;

  const history = useHistory();
  const { project, form, loading } = formStore;

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState(null);

  const trackMenuItemClick = (item) => {
    trackingService.trackActionsMenuItemClick(item, form.id, 'FORM');
  };

  return (
    <>
      <IconButton
        // @ts-expect-error TS2769
        $isActive={Boolean(actionsMenuAnchorEl)}
        aria-haspopup="true"
        title="Browse more actions"
        onClick={(event) => {
          setActionsMenuAnchorEl(event.currentTarget);
          trackingService.trackActionsMenuOpening(form.id, 'FORM');
        }}
        data-test="actions-context-dropdown-button"
      >
        <Dots />
      </IconButton>

      <Dropdown
        anchorEl={actionsMenuAnchorEl}
        open={Boolean(actionsMenuAnchorEl)}
        onClose={() => setActionsMenuAnchorEl(null)}
        data-test="actions-context-dropdown"
      >
        {hasAccess(project, actions.VIEW_VERSIONS) && (
          // @ts-expect-error TS2741
          <Dropdown.ListGroup>
            {/* @ts-expect-error TS2769 */}
            <Styled.ActionItem
              data-test="versions-list"
              title="Show version"
              onClick={() => {
                history.push(`/forms/${form.id}/versions`);
                trackMenuItemClick('open-versions');
              }}
            >
              <Milestone width={iconSize} height={iconSize} />
              Show versions
            </Styled.ActionItem>
          </Dropdown.ListGroup>
        )}

        {/* @ts-expect-error TS2741 */}
        <Dropdown.ListGroup>
          {/* @ts-expect-error TS2769 */}
          <Styled.ActionItem
            disabled={loading}
            data-test="copy-json"
            title="Copy JSON"
            onClick={() => {
              setActionsMenuAnchorEl(null);
              formStore.copyJSON();
              trackMenuItemClick('copy-json');
            }}
          >
            <Copy width={iconSize} height={iconSize} />
            Copy JSON
          </Styled.ActionItem>

          {/* @ts-expect-error TS2769 */}
          <Styled.ActionItem
            disabled={loading}
            data-test="export-json"
            title="Download JSON"
            onClick={() => {
              setActionsMenuAnchorEl(null);
              exportForm(form);
              trackMenuItemClick('export-json');
            }}
          >
            <Download width={iconSize} height={iconSize} />
            Download JSON
          </Styled.ActionItem>
        </Dropdown.ListGroup>
      </Dropdown>
    </>
  );
};

export default observer(ActionsMenu);
