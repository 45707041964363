/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// @ts-nocheck TS2339
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 15px 20px;
  display: flex;
  cursor: pointer;
  margin-bottom: -1px;
  ${({ includeBottomBorder }) => includeBottomBorder && 'border-bottom: 1px solid var(--silver-darken-94)'};
`;

export const Label = styled.span`
  font-size: 13px;
  margin-left: 6px;
`;

export const Hyperlink = styled.span`
  color: var(--cds-blue-60);
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: var(--cds-blue-70);
  }
`;
