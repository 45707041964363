/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import { processApplicationStore, projectStore, breadcrumbStore } from 'stores';
import { TopBar, Breadcrumb, Dropdown } from 'components';
import hasAccess, { actions } from 'utils/user-access';
import { getPageTitle } from 'utils/helpers';
import buildSlug from 'utils/buildSlug';

const ProcessApplicationHeader = () => {
  const history = useHistory();
  const { processApplication, loading } = processApplicationStore;
  const { project, folder } = projectStore;
  const pageTitle = loading ? 'Loading...' : processApplication.name || 'Process application not Found';

  const handleDelete = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    if (await processApplicationStore.delete(processApplication.id)) {
      if (folder.id) {
        history.push(`/folders/${buildSlug(folder.id, folder.name)}`);
      } else {
        history.push(`/projects/${buildSlug(project.id, project.name)}`);
      }
    }
  };

  const title = processApplication.name || 'Loading...';
  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle(pageTitle)}</title>
      </Helmet>

      <TopBar.Breadcrumbs>
        <Breadcrumb title="Home" variant="link" to="/" data-test="breadcrumb-home" />
        <Breadcrumb
          title={project.name || 'Loading...'}
          variant="link"
          to={`/projects/${buildSlug(project.id, project.name)}`}
          data-test="breadcrumb-project"
        />
        {Boolean(folder.parent) && (
          <Fragment>
            {folder.parent.parentId && <Breadcrumb title="..." variant="text" data-test="grand-parent-dots" />}
            <Breadcrumb
              title={folder.parent.name}
              variant="link"
              to={`/folders/${buildSlug(folder.parent.id, folder.parent.name)}`}
              data-test="grand-parent-folder"
            />
          </Fragment>
        )}
        {Boolean(folder.name) && (
          <Breadcrumb
            title={folder.name}
            variant="link"
            to={`/folders/${buildSlug(folder.id, folder.name)}`}
            data-test="parent-folder"
          />
        )}
        {hasAccess(project, actions.MODIFY_DIAGRAM) ? (
          <Breadcrumb
            title={title}
            variant="dropdown"
            isBold
            forPage="process-application"
            data-test="breadcrumb-process-application-menu"
            handleSubmit={async (name) => {
              breadcrumbStore.finishEditing();
              await processApplicationStore.rename(name);
            }}
          >
            {/* @ts-expect-error TS2739 */}
            <Dropdown.ListItem
              key="edit"
              onClick={() => {
                breadcrumbStore.toggleDropdownVisibility();
                breadcrumbStore.toggleEditingFor('process-application');
              }}
              data-test="rename-process-application"
            >
              Edit name
            </Dropdown.ListItem>
            {/* @ts-expect-error TS2739 */}
            <Dropdown.ListItem key="delete" onClick={handleDelete} data-test="delete-process-application">
              Delete
            </Dropdown.ListItem>
          </Breadcrumb>
        ) : (
          <Breadcrumb title={title} variant="text" isBold data-test="breadcrumb-process-application" />
        )}
      </TopBar.Breadcrumbs>
    </Fragment>
  );
};

export default observer(ProcessApplicationHeader);
