/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ActionableNotification, InlineLoading } from '@carbon/react';

import { idpProjectStore } from 'stores';
import ExtractionFields from 'App/Pages/Project/IdpApplication/IdpProject/IdpExtractionFields/extraction-fields/ExtractionFields';
import { EXTRACTION_NOTIFICATION_STEPS } from 'App/Pages/Project/IdpApplication/IdpProject/utils/constants';
import { PdfViewer, PdfViewerHeader } from 'App/Pages/Project/IdpApplication/IdpProject/IdpExtractionFields/pdf-viewer';

import * as Styled from './IdpExtractionFields.styled';

const IdpExtractionFields = ({ clusterStatus }) => {
  const { activeExtractionIdpDocument, idpExtractionFields, extractionStepNotification, idpDocuments, idpProject } =
    idpProjectStore;
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [idpDocumentBlob, setIdpDocumentBlob] = useState(null);
  const [isLoadingIdpDocument, setIsLoadingIdpDocument] = useState(false);
  const [idpDocumentScale, setIdpDocumentScale] = useState(1);

  useEffect(() => {
    if (idpExtractionFields.length === 0) {
      idpProjectStore.setExtractionStepNotification(EXTRACTION_NOTIFICATION_STEPS.ADD_AN_EXTRACTION_FIELD);
    }
    return () => {
      idpProjectStore.setExtractionStepNotification(null);
      idpProjectStore.setActiveExtractionIdpDocument(null);
    };
  }, []);

  useEffect(() => {
    if (idpDocuments.length > 0) {
      idpProjectStore.setActiveExtractionIdpDocument(activeExtractionIdpDocument ?? idpDocuments[0]);
      handleIdpDocumentChange(activeExtractionIdpDocument ?? idpDocuments[0]);
    }
  }, [idpDocuments.length]);

  const handleIdpDocumentChange = async (selectedIdpDocument) => {
    setIsLoadingIdpDocument(true);
    const blob = await idpProjectStore.getIdpDocument(selectedIdpDocument.id);
    idpProjectStore.setActiveExtractionIdpDocument(selectedIdpDocument);
    setCurrentPage(1);
    setIdpDocumentScale(1);
    setIdpDocumentBlob(blob);
    setIsLoadingIdpDocument(false);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => setNumberOfPages(numPages);

  const handleUpdateExtractionField = async (extractionField) => {
    await idpProjectStore.updateIdpExtractionField(
      extractionField.id,
      extractionField.name,
      extractionField.type,
      extractionField.llmPromptDescription
    );
  };

  const handleUpdateExtractionFieldValues = async (llmModelId, extractionFields) => {
    await idpProjectStore.updateIdpExtractionFieldValues(
      idpProject.id,
      activeExtractionIdpDocument.id,
      extractionFields,
      llmModelId
    );
  };

  return (
    <>
      {extractionStepNotification && (
        <Styled.ActionableNotificationContainer>
          <ActionableNotification
            inline
            hideCloseButton
            kind="info"
            title={extractionStepNotification.title}
            subtitle={extractionStepNotification.content}
            actionButtonLabel="Got it"
            onActionButtonClick={() => idpProjectStore.setExtractionStepNotification(null)}
          />
        </Styled.ActionableNotificationContainer>
      )}
      <Styled.Container>
        <Styled.Section $orientation="left" $isExtractionStepNotificationExists={!!extractionStepNotification}>
          <ExtractionFields
            clusterStatus={clusterStatus}
            onUpdateExtractionField={handleUpdateExtractionField}
            onUpdateExtractionFieldValues={handleUpdateExtractionFieldValues}
          />
        </Styled.Section>
        <Styled.Section $orientation="right" $isExtractionStepNotificationExists={!!extractionStepNotification}>
          <PdfViewerHeader
            documents={idpDocuments}
            selectedDocument={activeExtractionIdpDocument}
            selectedDocumentPageCount={numberOfPages}
            onDocumentChange={handleIdpDocumentChange}
            onDocumentPageChange={setCurrentPage}
            onDocumentScaleChange={setIdpDocumentScale}
          />
          {isLoadingIdpDocument ? (
            <Styled.LoaderContainer>
              <InlineLoading status="active" iconDescription="Loading" description="Loading document..." />
            </Styled.LoaderContainer>
          ) : (
            <PdfViewer
              pageNumber={currentPage}
              scale={idpDocumentScale}
              file={idpDocumentBlob}
              onDocumentLoadSuccess={handleDocumentLoadSuccess}
              onPageChange={setCurrentPage}
            />
          )}
        </Styled.Section>
      </Styled.Container>
    </>
  );
};

export default observer(IdpExtractionFields);
