/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useHistory } from 'react-router-dom';

import { Button } from 'primitives';
import * as Styled from 'components/TargetSelector/EmptyState.styled';

import { DmnPreview } from './icons';
import createDmnDiagram from './createDmnDiagram';

export default function BusinessRuleTaskLinkEmptyState({ target }) {
  const history = useHistory();

  return (
    <Styled.Container>
      <Styled.Content>
        <DmnPreview />
        <Styled.Title>
          <strong>No decision has been created in this project</strong>
        </Styled.Title>
        <div>To link a decision to your task, you first need to create one.</div>
      </Styled.Content>
      {/* @ts-expect-error TS2322 */}
      <Button onClick={() => createDmnDiagram(target, history)}>Create DMN diagram</Button>
    </Styled.Container>
  );
}
